import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import { User, Key, UserCog, Building2, Plus, AlertCircle, CheckCircle, RefreshCw, Trash2 } from 'lucide-react';

const UserManagement = () => {
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    role: 'user',
    branches: [],
    fullName: ''
  });
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  // Fetch branches on component mount and every 5 minutes
  useEffect(() => {
    fetchBranches();
    fetchUsers();
    const interval = setInterval(() => {
      fetchBranches();
      fetchUsers();
    }, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBranches(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/admin/users`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('שגיאה בטעינת משתמשים');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({ ...prev, [name]: value }));
    setError('');
    setSuccess('');
  };

  const toggleBranch = (branch) => {
    setNewUser(prev => ({
      ...prev,
      branches: prev.branches.includes(branch._id)
        ? prev.branches.filter(b => b !== branch._id)
        : [...prev.branches, branch._id]
    }));
  };

  const selectAllBranches = () => {
    setNewUser(prev => ({
      ...prev,
      branches: branches.map(branch => branch._id)
    }));
  };

  const clearAllBranches = () => {
    setNewUser(prev => ({
      ...prev,
      branches: []
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccess('');
    
    try {
      const response = await axios.post(`${API_URL}/api/admin/users`, newUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      
      setSuccess('המשתמש נוצר בהצלחה');
      // Reset form
      setNewUser({
        username: '',
        password: '',
        role: 'user',
        branches: [],
        fullName: ''
      });
      // Refresh users list
      fetchUsers();
    } catch (error) {
      console.error('Error creating user:', error);
      setError('שגיאה ביצירת משתמש: ' + (error.response?.data?.message || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (userId, userRole) => {
    if (userRole === 'admin') {
      setError('לא ניתן למחוק משתמש מנהל מערכת');
      return;
    }

    if (window.confirm('האם אתה בטוח שברצונך למחוק משתמש זה?')) {
      try {
        await axios.delete(`${API_URL}/api/admin/users/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setSuccess('המשתמש נמחק בהצלחה');
        fetchUsers(); // Refresh user list
      } catch (error) {
        setError('שגיאה במחיקת משתמש: ' + (error.response?.data?.message || error.message));
      }
    }
  };

  // Add this helper function to get branch names from IDs
  const getBranchNames = (branchIds) => {
    return branchIds.map(id => {
      const branch = branches.find(b => b._id === id);
      return branch ? branch.name : id;
    }).join(', ');
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-xl shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
          <User className="h-6 w-6 ml-2 text-blue-500" />
          יצירת משתמש חדש
        </h2>

        {error && (
          <div className="mb-6 p-4 bg-red-50 rounded-lg border border-red-200 flex items-center text-red-700">
            <AlertCircle className="h-5 w-5 ml-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-6 p-4 bg-green-50 rounded-lg border border-green-200 flex items-center text-green-700">
            <CheckCircle className="h-5 w-5 ml-2" />
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Full Name Field */}
            <div className="relative">
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-2">
                שם מלא
              </label>
              <div className="relative">
                <User className="h-5 w-5 text-gray-400 absolute right-3 top-3" />
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={newUser.fullName}
                  onChange={handleInputChange}
                  required
                  className="block w-full pr-10 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                  placeholder="הכנס שם מלא"
                />
              </div>
            </div>

            {/* Username Field */}
            <div className="relative">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-2">
                שם משתמש
              </label>
              <div className="relative">
                <User className="h-5 w-5 text-gray-400 absolute right-3 top-3" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={newUser.username}
                  onChange={handleInputChange}
                  required
                  className="block w-full pr-10 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                  placeholder="הכנס שם משתמש"
                />
              </div>
            </div>

            {/* Password Field */}
            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                סיסמה
              </label>
              <div className="relative">
                <Key className="h-5 w-5 text-gray-400 absolute right-3 top-3" />
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={newUser.password}
                  onChange={handleInputChange}
                  required
                  className="block w-full pr-10 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                  placeholder="הכנס סיסמה"
                />
              </div>
            </div>

            {/* Role Selection */}
            <div className="relative">
              <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-2">
                תפקיד
              </label>
              <div className="relative">
                <UserCog className="h-5 w-5 text-gray-400 absolute right-3 top-3" />
                <select
                  id="role"
                  name="role"
                  value={newUser.role}
                  onChange={handleInputChange}
                  required
                  className="block w-full pr-10 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 appearance-none bg-white"
                >
                  <option value="user">משתמש רגיל</option>
                  <option value="manager">מנהל</option>
                  <option value="admin">מנהל מערכת</option>
                  <option value="branch">משתמש סניף</option>
                  <option value="area_manager">מנהל אזור</option>
                </select>
              </div>
            </div>
          </div>

          {/* Branches Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Building2 className="h-5 w-5 ml-2" />
                  סניפים מורשים
                </div>
                <div className="flex gap-2">
                  <button
                    type="button"
                    onClick={selectAllBranches}
                    className="text-sm text-blue-600 hover:text-blue-800"
                  >
                    בחר הכל
                  </button>
                  <button
                    type="button"
                    onClick={clearAllBranches}
                    className="text-sm text-gray-600 hover:text-gray-800"
                  >
                    נקה הכל
                  </button>
                </div>
              </div>
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
              {branches.map((branch) => (
                <button
                  key={branch._id}
                  type="button"
                  onClick={() => toggleBranch(branch)}
                  className={`p-3 rounded-lg border-2 transition-all duration-200 flex items-center justify-center text-sm
                    ${newUser.branches.includes(branch._id)
                      ? 'border-blue-500 bg-blue-50 text-blue-700'
                      : 'border-gray-200 hover:border-blue-200 text-gray-600 hover:bg-blue-50/50'
                    }`}
                >
                  {branch.name}
                </button>
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white p-3 rounded-lg
                     hover:from-blue-700 hover:to-blue-600 focus:outline-none focus:ring-2 
                     focus:ring-blue-500 focus:ring-offset-2 transition-all duration-200
                     disabled:opacity-50 disabled:cursor-not-allowed
                     flex items-center justify-center space-x-2"
          >
            {isLoading ? (
              <RefreshCw className="h-5 w-5 animate-spin ml-2" />
            ) : (
              <Plus className="h-5 w-5 ml-2" />
            )}
            {isLoading ? 'יוצר משתמש...' : 'צור משתמש'}
          </button>
        </form>
      </div>

      {/* Add Users List */}
      <div className="bg-white p-6 rounded-xl shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
          <User className="h-6 w-6 ml-2 text-blue-500" />
          רשימת משתמשים
        </h2>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">שם מלא</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">שם משתמש</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תפקיד</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניפים</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">פעולות</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map((user) => (
                <tr key={user._id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.fullName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.username}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {user.role === 'admin' 
                      ? 'מנהל מערכת' 
                      : user.role === 'manager' 
                      ? 'מנהל' 
                      : user.role === 'branch'
                      ? 'משתמש סניף'
                      : user.role === 'area_manager'
                      ? 'מנהל אזור'
                      : 'משתמש רגיל'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {getBranchNames(user.branches)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <button
                      onClick={() => handleDeleteUser(user._id, user.role)}
                      disabled={user.role === 'admin'}
                      className={`p-2 rounded-lg transition-colors duration-200 
                        ${user.role === 'admin' 
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                          : 'bg-red-100 text-red-600 hover:bg-red-200'}`}
                      title={user.role === 'admin' ? 'לא ניתן למחוק מנהל מערכת' : 'מחק משתמש'}
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;