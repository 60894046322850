import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getInventoryFormDetails } from '../../utils/api';
import { toast } from 'react-hot-toast';
import { ArrowDownTrayIcon, ClockIcon } from '@heroicons/react/24/outline';
import * as XLSX from 'xlsx';

const InventoryFormStats = () => {
  const { formId } = useParams();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('summary');

  useEffect(() => {
    const fetchFormDetails = async () => {
      try {
        setLoading(true);
        const response = await getInventoryFormDetails(formId);
        if (!response.form) {
          throw new Error('Invalid form data received');
        }
        setForm(response.form);
      } catch (err) {
        console.error('Error fetching form details:', err);
        setError(err.message || 'Failed to load form details');
        toast.error('שגיאה בטעינת פרטי הטופס');
      } finally {
        setLoading(false);
      }
    };

    fetchFormDetails();
  }, [formId]);

  const exportToExcel = () => {
    try {
      if (!form || !form.responses || !form.questions) {
        toast.error('אין נתונים לייצוא');
        return;
      }

      // Prepare the data for export
      const exportData = form.responses.map((response) => {
        const answersCount = response.answers?.length || 0;
        const row = {
          'מספר תשובות': answersCount,
          'תאריך': new Date(response.completedAt).toLocaleString('he-IL'),
          'סטטוס': response.status
        };

        // Add each question and its answer
        form.questions.forEach(question => {
          const answer = response.answers.find(a => a.questionId === question._id);
          let answerValue = '';

          if (answer) {
            if (typeof answer.answer === 'object') {
              answerValue = answer.answer.selectedOption;
              if (answer.answer.followUpAnswer) {
                answerValue += ` - ${answer.answer.followUpAnswer}`;
              }
            } else {
              answerValue = answer.answer === 'true' ? 'כן' : 
                           answer.answer === 'false' ? 'לא' : 
                           answer.answer;
            }
          }

          row[question.questionText] = answerValue;
        });

        return row;
      });

      // Create workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(exportData, { 
        header: [
          'מספר תשובות',
          'תאריך',
          'סטטוס',
          ...form.questions.map(q => q.questionText)
        ]
      });

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, 'תגובות');

      // Save file
      XLSX.writeFile(wb, `תגובות_${form.title}_${new Date().toLocaleDateString()}.xlsx`);
      toast.success('הקובץ יוצא בהצלחה');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      toast.error('שגיאה בייצוא הקובץ');
    }
  };

  const ResponsesTab = () => (
    <div className="space-y-8">
      {form.responses.map((response, index) => {
        const completedAt = new Date(response.completedAt);
        const responseTimeHours = ((completedAt - new Date(form.createdAt)) / (1000 * 60 * 60)).toFixed(1);
        const answersCount = response.answers?.length || 0;
        
        return (
          <div key={response._id} className="border rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <div>
                <div className="flex items-center gap-2">
                  <h3 className="font-semibold text-lg">תגובות</h3>
                  <span className="bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded">
                    {answersCount} מתוך {form.questions.length} שאלות
                  </span>
                </div>
                <p className="text-sm text-gray-600">
                  הושלם ב: {completedAt.toLocaleString('he-IL')}
                </p>
              </div>
              <div className="flex items-center text-blue-600">
                <ClockIcon className="h-5 w-5 mr-1" />
                <span>{responseTimeHours} שעות</span>
              </div>
            </div>
            <div className="space-y-4">
              {response.answers.map((answer) => {
                const question = form.questions.find(q => q._id === answer.questionId);
                if (!question) return null;

                return (
                  <div key={answer.questionId} className="bg-gray-50 p-3 rounded-lg">
                    <p className="font-medium text-gray-700">{question.questionText}</p>
                    <div className="mt-2">
                      {typeof answer.answer === 'object' ? (
                        <div>
                          <p className="text-blue-600">{answer.answer.selectedOption}</p>
                          {answer.answer.followUpAnswer && (
                            <p className="text-gray-600 mt-1">
                              פירוט נוסף: {answer.answer.followUpAnswer}
                            </p>
                          )}
                        </div>
                      ) : (
                        <p className="text-blue-600">
                          {answer.answer === 'true' ? 'כן' : 
                           answer.answer === 'false' ? 'לא' : 
                           answer.answer}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );

  if (loading) return <div className="text-center p-8">טוען...</div>;
  if (error) return <div className="text-center p-8 text-red-600">{error}</div>;
  if (!form) return <div className="text-center p-8">הטופס לא נמצא</div>;

  return (
    <div className="p-6" dir="rtl">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">{form.title}</h1>
            <button
              onClick={exportToExcel}
              className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
            >
              <ArrowDownTrayIcon className="h-5 w-5" />
              ייצא לאקסל
            </button>
          </div>
          <p className="text-gray-600 mb-6">{form.description}</p>

          {/* Tabs */}
          <div className="border-b border-gray-200 mb-6">
            <nav className="flex space-x-8" aria-label="Tabs">
              <button
                onClick={() => setActiveTab('summary')}
                className={`${
                  activeTab === 'summary'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              >
                סיכום
              </button>
              <button
                onClick={() => setActiveTab('responses')}
                className={`${
                  activeTab === 'responses'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              >
                תגובות
              </button>
            </nav>
          </div>

          {/* Summary Tab */}
          {activeTab === 'summary' && (
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-blue-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-blue-700 mb-2">סך הכל תגובות</h3>
                  <p className="text-3xl font-bold text-blue-800">
                    {form.responses.reduce((total, response) => total + (response.answers?.length || 0), 0)}
                  </p>
                </div>
                <div className="bg-green-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-green-700 mb-2">אחוז השלמה</h3>
                  <p className="text-3xl font-bold text-green-800">
                    {((form.responses.reduce((total, response) => 
                      total + (response.answers?.length || 0), 0) / 
                      (form.questions.length)) * 100).toFixed(1)}%
                  </p>
                </div>
                <div className="bg-purple-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-purple-700 mb-2">מספר שאלות</h3>
                  <p className="text-3xl font-bold text-purple-800">{form.questions.length}</p>
                </div>
              </div>
            </div>
          )}

          {/* Responses Tab */}
          {activeTab === 'responses' && <ResponsesTab />}
        </div>
      </div>
    </div>
  );
};

export default InventoryFormStats; 