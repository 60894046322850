import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileText, FileX, Building } from 'lucide-react';
import api from '../utils/api';

const BranchDashboard = () => {
  const [stats, setStats] = useState({
    inventory: {
      total: 0,
      active: 0,
      completed: 0,
      expired: 0
    },
    rejections: {
      total: 0,
      open: 0,
      inProgress: 0,
      completed: 0
    }
  });
  const [branchesStats, setBranchesStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get user from localStorage
    const currentUser = JSON.parse(localStorage.getItem('user'));
    setUser(currentUser);
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        if (!user) return;

        if (user.role === 'branch') {
          // Fetch branch-specific stats
          const inventoryResponse = await api.get('/api/inventory/dashboard');
          const rejectionsResponse = await api.get('/api/rejection-forms/dashboard');
          
          setStats({
            inventory: inventoryResponse.data.stats,
            rejections: rejectionsResponse.data.stats || {
              total: 0,
              open: 0,
              inProgress: 0,
              completed: 0
            }
          });
        } else if (user.role === 'area_manager') {
          // Fetch stats for all branches under the area manager
          const response = await api.get('/api/area-manager/dashboard');
          
          if (response.data) {
            // Set aggregated stats
            setStats(response.data.aggregatedStats);
            // Set individual branch stats
            setBranchesStats(response.data.branchesStats.map(branch => ({
              ...branch,
              inventory: {
                ...branch.inventory,
                percentage: branch.inventory.total > 0 
                  ? ((branch.inventory.completed / branch.inventory.total) * 100).toFixed(1)
                  : 0
              },
              rejections: {
                ...branch.rejections,
                percentage: branch.rejections.total > 0 
                  ? ((branch.rejections.completed / branch.rejections.total) * 100).toFixed(1)
                  : 0
              }
            })));
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching stats:', error);
        setLoading(false);
      }
    };

    if (user) {
      fetchStats();
    }
  }, [user]);

  if (loading) {
    return <div className="text-center p-8">טוען...</div>;
  }

  return (
    <div className="space-y-6">
      {/* Welcome Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl p-6 shadow-lg">
        <h1 className="text-2xl font-bold mb-2">
          {user?.role === 'area_manager' ? 'ברוכים הבאים לדשבורד מנהל אזור' : 'ברוכים הבאים למערכת הניהול'}
        </h1>
        <p className="text-blue-100">
          {user?.role === 'area_manager' 
            ? 'כאן תוכלו למצוא סיכום של כל המשימות בסניפים שלכם'
            : 'כאן תוכלו למצוא סיכום של כל המשימות הפתוחות שלכם'}
        </p>
      </div>

      {/* Aggregated Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Inventory Forms Card */}
        <div 
          className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow cursor-pointer"
          onClick={() => navigate('/dashboard/inventory')}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <FileText className="h-6 w-6 ml-2 text-blue-600" />
                שאלוני מלאי
              </h2>
              <span className="text-sm text-gray-500">לחץ לצפייה</span>
            </div>
            <div className="grid grid-cols-4 gap-4">
              <div className="text-center">
                <div className="text-2xl font-bold text-gray-800">{stats.inventory.total}</div>
                <div className="text-sm text-gray-500">סה"כ</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-red-600">{stats.inventory.active}</div>
                <div className="text-sm text-gray-500">פעילים</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-green-600">{stats.inventory.completed}</div>
                <div className="text-sm text-gray-500">הושלמו</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-orange-500">{stats.inventory.expired}</div>
                <div className="text-sm text-gray-500">פג תוקף</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-3">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">
                {stats.inventory.active > 0 
                  ? `${stats.inventory.active} שאלונים פעילים ממתינים למילוי` 
                  : stats.inventory.expired > 0 
                    ? `${stats.inventory.expired} שאלונים שפג תוקפם` 
                    : 'אין שאלונים פעילים'}
              </span>
            </div>
          </div>
        </div>

        {/* Rejection Forms Card */}
        <div 
          className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow cursor-pointer"
          onClick={() => navigate('/dashboard/rejection-forms')}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <FileX className="h-6 w-6 ml-2 text-blue-600" />
                רג'קטים תפעוליים
              </h2>
              <span className="text-sm text-gray-500">לחץ לצפייה</span>
            </div>
            <div className="grid grid-cols-4 gap-4">
              <div className="text-center">
                <div className="text-2xl font-bold text-gray-800">{stats.rejections.total}</div>
                <div className="text-sm text-gray-500">סה"כ</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-red-600">{stats.rejections.open}</div>
                <div className="text-sm text-gray-500">משימות פתוחות</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-orange-500">{stats.rejections.inProgress}</div>
                <div className="text-sm text-gray-500">בטיפול</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-green-600">{stats.rejections.completed}</div>
                <div className="text-sm text-gray-500">הושלמו</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-3">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">
                {stats.rejections.open + stats.rejections.inProgress > 0 
                  ? `${stats.rejections.open + stats.rejections.inProgress} משימות מחכות לטיפול` 
                  : 'כל המשימות הושלמו 🎉'}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Branch-specific stats for area managers */}
      {user?.role === 'area_manager' && branchesStats.length > 0 && (
        <div className="mt-8">
          <h2 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
            <Building className="h-6 w-6 ml-2" />
            סטטיסטיקות לפי סניף
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {branchesStats.map((branch) => (
              <div key={branch.branchId} className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 border-b border-gray-100">
                  <h3 className="text-lg font-semibold text-gray-800">{branch.branchName}</h3>
                </div>
                <div className="p-4">
                  <div className="space-y-4">
                    {/* Inventory stats */}
                    <div>
                      <div className="flex justify-between text-sm mb-2">
                        <span className="text-gray-600">שאלוני מלאי</span>
                        <div className="flex items-center space-x-2">
                          <span className="text-gray-800">
                            {branch.inventory.completed}/{branch.inventory.total}
                          </span>
                          <span className={`text-sm font-medium ${
                            parseFloat(branch.inventory.percentage) >= 70 ? 'text-green-600' :
                            parseFloat(branch.inventory.percentage) >= 40 ? 'text-yellow-600' :
                            'text-red-600'
                          }`}>
                            ({branch.inventory.percentage}%)
                          </span>
                        </div>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className={`h-2 rounded-full ${
                            parseFloat(branch.inventory.percentage) >= 70 ? 'bg-green-600' :
                            parseFloat(branch.inventory.percentage) >= 40 ? 'bg-yellow-600' :
                            'bg-red-600'
                          }`}
                          style={{ 
                            width: `${branch.inventory.percentage}%` 
                          }}
                        />
                      </div>
                      <div className="flex justify-between text-xs mt-1 text-gray-500">
                        <span>פעילים: {branch.inventory.active}</span>
                        <span>פג תוקף: {branch.inventory.expired}</span>
                      </div>
                    </div>
                    {/* Rejections stats */}
                    <div>
                      <div className="flex justify-between text-sm mb-2">
                        <span className="text-gray-600">רג'קטים</span>
                        <div className="flex items-center space-x-2">
                          <span className="text-gray-800">
                            {branch.rejections.completed}/{branch.rejections.total}
                          </span>
                          <span className={`text-sm font-medium ${
                            parseFloat(branch.rejections.percentage) >= 70 ? 'text-green-600' :
                            parseFloat(branch.rejections.percentage) >= 40 ? 'text-yellow-600' :
                            'text-red-600'
                          }`}>
                            ({branch.rejections.percentage}%)
                          </span>
                        </div>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className={`h-2 rounded-full ${
                            parseFloat(branch.rejections.percentage) >= 70 ? 'bg-green-600' :
                            parseFloat(branch.rejections.percentage) >= 40 ? 'bg-yellow-600' :
                            'bg-red-600'
                          }`}
                          style={{ 
                            width: `${branch.rejections.percentage}%` 
                          }}
                        />
                      </div>
                      <div className="flex justify-between text-xs mt-1 text-gray-500">
                        <span>פתוחים: {branch.rejections.open}</span>
                        <span>בטיפול: {branch.rejections.inProgress}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BranchDashboard; 