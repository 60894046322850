import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { CheckCircle, AlertCircle, Loader, Plus, DollarSign, Briefcase } from 'lucide-react';
import api from '../../utils/api';
import { toast } from 'react-hot-toast';

const PublicSalaryUpgradeForm = () => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    branchId: '',
    idNumber: '',
    employeeName: '',
    employeeNumber: '',
    role: '',
    salaryType: '',
    currentSalary: '',
    requestedSalary: '',
    yearsInCompany: '',
    justification: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formSettings, setFormSettings] = useState({ isSubmissionOpen: true, closedMessage: '' });

  useEffect(() => {
    fetchBranches();
    fetchSettings();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await api.get('/api/salary-surveys/public/branches');
      setBranches(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
      setError('שגיאה בטעינת רשימת הסניפים');
    } finally {
      setLoading(false);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await api.get('/api/salary-surveys/settings');
      setFormSettings(response.data);
    } catch (error) {
      console.error('Error fetching form settings:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.branchId) {
      toast.error('נא לבחור סניף');
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post(`/api/salary-surveys/submit`, formData);
      setIsSubmitted(true);
      toast.success('הבקשה נשלחה בהצלחה!');
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || 'אירעה שגיאה בשליחת הטופס');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNewResponse = () => {
    setFormData({
      branchId: '',
      idNumber: '',
      employeeName: '',
      employeeNumber: '',
      role: '',
      salaryType: '',
      currentSalary: '',
      requestedSalary: '',
      yearsInCompany: '',
      justification: ''
    });
    setIsSubmitted(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center text-red-600">
          <AlertCircle className="w-12 h-12 mx-auto mb-4" />
          <p className="text-lg">{error}</p>
        </div>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-2xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg p-8 text-center">
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-4">תודה רבה!</h2>
            <p className="text-gray-600 mb-8">
              בקשתך התקבלה בהצלחה ותטופל בהקדם האפשרי
            </p>
            
            <button
              onClick={handleNewResponse}
              className="flex items-center px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors mx-auto"
            >
              <Plus className="w-4 h-4 ml-2" />
              שלח בקשה נוספת
            </button>
          </div>
          
          <div className="mt-4 text-center text-sm text-gray-500">
            פותח ע"י ישי אלמולי © 2024
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-50 py-6 px-3 sm:py-12 sm:px-6 lg:px-8">
      {!formSettings.isSubmissionOpen ? (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto"
        >
          <div className="bg-white rounded-2xl shadow-xl p-8 text-center">
            <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-4">הטופס סגור להגשות</h2>
            <p className="text-gray-600">
              {formSettings.closedMessage}
            </p>
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto"
        >
          <div className="bg-white rounded-2xl shadow-xl p-4 sm:p-8 border border-gray-100">
            <div className="mb-6 sm:mb-8 text-center">
              <div className="inline-block p-2 sm:p-3 bg-blue-100 rounded-2xl mb-3 sm:mb-4">
                <DollarSign className="w-6 h-6 sm:w-8 sm:h-8 text-blue-600" />
              </div>
              <h1 className="text-2xl sm:text-3xl font-bold mb-2 bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
                בקשה להעלאת שכר
              </h1>
              <p className="text-sm sm:text-base text-gray-600">מלא את הפרטים הבאים לבקשת העלאת שכר</p>
            </div>

            <div className="mb-8 p-4 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl border border-blue-100">
              <p className="text-blue-800 text-sm text-center flex items-center justify-center gap-2">
                <span className="bg-blue-100 p-1 rounded-full">🔒</span>
                טופס זה הינו חסוי ומאובטח
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6 sm:space-y-8">
              <div className="grid grid-cols-1 gap-4 sm:gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2 flex items-center gap-2">
                    <Briefcase className="w-4 h-4 text-blue-500" />
                    בחר סניף
                  </label>
                  <select
                    name="branchId"
                    required
                    className="w-full rounded-lg sm:rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.branchId}
                    onChange={handleChange}
                  >
                    <option value="">בחר סניף</option>
                    {branches.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2">
                    תעודת זהות
                  </label>
                  <input
                    type="text"
                    name="idNumber"
                    required
                    maxLength="9"
                    pattern="\d{9}"
                    title="יש להזין 9 ספרות"
                    className="w-full rounded-lg sm:rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base sm:text-sm"
                    value={formData.idNumber}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2">
                    שם העובד
                  </label>
                  <input
                    type="text"
                    name="employeeName"
                    required
                    className="w-full rounded-lg sm:rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base sm:text-sm"
                    value={formData.employeeName}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    מספר עובד
                  </label>
                  <input
                    type="text"
                    name="employeeNumber"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.employeeNumber}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    תפקיד
                  </label>
                  <input
                    type="text"
                    name="role"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.role}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    סוג שכר
                  </label>
                  <select
                    name="salaryType"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.salaryType}
                    onChange={handleChange}
                  >
                    <option value="">בחר סוג שכר</option>
                    <option value="hourly">שעתי</option>
                    <option value="global">גלובלי</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {formData.salaryType === 'hourly' ? 'שכר לשעה' : 'משכורת חודשית'} נוכחי/ת
                  </label>
                  <input
                    type="number"
                    name="currentSalary"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.currentSalary}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {formData.salaryType === 'hourly' ? 'שכר לשעה' : 'משכורת חודשית'} מבוקש/ת
                  </label>
                  <input
                    type="number"
                    name="requestedSalary"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.requestedSalary}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    ותק בחברה (בשנים)
                  </label>
                  <input
                    type="number"
                    name="yearsInCompany"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.yearsInCompany}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2">
                    נימוק לבקשת העלאה
                  </label>
                  <textarea
                    name="justification"
                    required
                    rows={4}
                    className="w-full rounded-lg sm:rounded-xl border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base sm:text-sm"
                    value={formData.justification}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex justify-center pt-4 sm:pt-6">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full sm:w-auto bg-gradient-to-r from-blue-600 to-blue-700 text-white px-6 sm:px-10 py-3 rounded-lg sm:rounded-xl hover:from-blue-700 hover:to-blue-800 transition-all transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 flex items-center justify-center gap-2 shadow-lg text-base sm:text-sm"
                >
                  {isSubmitting ? (
                    <>
                      <Loader className="animate-spin h-5 w-5 text-white" />
                      שולח...
                    </>
                  ) : (
                    <>
                      <span>שלח בקשה</span>
                      <motion.div
                        animate={{ x: [0, 5, 0] }}
                        transition={{ duration: 1, repeat: Infinity }}
                      >
                        →
                      </motion.div>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
          
          <div className="mt-4 sm:mt-6 text-center text-xs sm:text-sm text-gray-500 bg-white/50 backdrop-blur-sm py-2 sm:py-3 rounded-xl">
            פותח ע"י ישי אלמולי © {new Date().getFullYear()}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default PublicSalaryUpgradeForm; 