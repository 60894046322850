import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { FileUp, FileCheck, Trash2, ArrowUpToLine } from 'lucide-react';
import { API_URL } from '../../config';

export default function TemplateUpload() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [timeLimit, setTimeLimit] = useState('24'); // Default 24 hours
  const [branches, setBranches] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(true);
  const [selectedBranches, setSelectedBranches] = useState({}); // Map of templateId -> branchId
  const [assignedBranches, setAssignedBranches] = useState({});

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/branches`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setBranches(response.data);
        // Initialize selected branches for each template with first branch
        if (response.data.length > 0) {
          const initialBranches = {};
          templates.forEach(template => {
            initialBranches[template._id] = response.data[0]._id;
          });
          setSelectedBranches(initialBranches);
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
        toast.error('שגיאה בטעינת סניפים');
      } finally {
        setLoadingBranches(false);
      }
    };

    fetchBranches();
  }, [templates]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/templates`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setTemplates(response.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
        toast.error('שגיאה בטעינת תבניות קיימות');
      }
    };

    fetchTemplates();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error('נא לבחור קובץ');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('timeLimit', timeLimit);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/api/templates/import`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setTemplates(prevTemplates => [...prevTemplates, ...response.data.templates]);
      toast.success(response.data.message);
      setFile(null);
      e.target.reset();
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.response?.data?.message || 'שגיאה בהעלאת הקובץ');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateForms = async (templateId) => {
    const selectedBranch = selectedBranches[templateId];
    if (!selectedBranch) {
      toast.error('נא לבחור סניף');
      return;
    }

    if (assignedBranches[templateId]?.includes(selectedBranch)) {
      toast.error('כבר נוצר טופס עבור סניף זה');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/api/templates/${templateId}/create-forms`, {
        timeLimit: parseInt(timeLimit),
        branchId: selectedBranch
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setAssignedBranches(prev => ({
        ...prev,
        [templateId]: [...(prev[templateId] || []), selectedBranch]
      }));

      const template = templates.find(t => t._id === templateId);
      const allBranchesAssigned = branches.every(branch => 
        assignedBranches[templateId]?.includes(branch._id) || branch._id === selectedBranch
      );

      if (allBranchesAssigned) {
        setTemplates(prev => prev.filter(t => t._id !== templateId));
        toast.success('הטפסים נוצרו בהצלחה והתבנית נמחקה');
      } else {
        toast.success('הטפסים נוצרו בהצלחה');
      }

    } catch (error) {
      console.error('Create forms error:', error);
      toast.error(error.response?.data?.message || 'שגיאה ביצירת הטפסים');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAllTemplates = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/templates`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setTemplates([]);
      toast.success('כל התבניות נמחקו בהצלחה');
    } catch (error) {
      console.error('Error removing templates:', error);
      toast.error('שגיאה במחיקת התבניות');
    }
  };

  const handleEyeDoSync = async (templateId, branchId) => {
    try {
      const response = await axios.post(
        `/api/eyedo/sync/${templateId}`,
        { branchId },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      toast.success('סונכרן בהצלחה עם EyeDo');
      return response.data;
    } catch (error) {
      console.error('EyeDo sync error:', error);
      const errorMessage = error.response?.data?.message || error.response?.data?.error || 'שגיאה בסנכרון עם EyeDo';
      toast.error(errorMessage);
    }
  };

  return (
    <div className="p-6">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">העלאת קובץ אקסל</h2>
          {templates.length > 0 && (
            <button
              type="button"
              onClick={handleRemoveAllTemplates}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Trash2 className="h-5 w-5 ml-2" />
              מחק את כל התבניות ({templates.length})
            </button>
          )}
        </div>

        <form onSubmit={handleUpload} className="space-y-4">
          <div className="flex items-center space-x-4">
            <input
              type="file"
              accept=".xlsx,.xls"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
            />
          </div>

          <div className="flex items-end gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                זמן להשלמה (שעות)
              </label>
              <input
                type="number"
                min="1"
                max="72"
                value={timeLimit}
                onChange={(e) => setTimeLimit(e.target.value)}
                className="block w-32 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div className="flex gap-2">
              <button
                type="submit"
                disabled={loading || !file}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {loading ? (
                  'מעלה...'
                ) : (
                  <>
                    <FileUp className="h-5 w-5 ml-2" />
                    העלאה
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {templates.length > 0 && (
        <div>
          <h3 className="text-xl font-semibold mb-4">תבניות שנוצרו</h3>
          <div className="space-y-4">
            {templates.map((template) => (
              <div
                key={template._id}
                className="border rounded-lg p-4 bg-white shadow-sm"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h4 className="text-lg font-medium">{template.title}</h4>
                    <p className="text-gray-600">{template.description}</p>
                    <p className="text-sm text-gray-500">
                      מספר שאלות: {template.questions.length}
                    </p>
                    <p className="text-sm text-gray-500">
                      סניפים שהוקצו: {assignedBranches[template._id]?.length || 0} מתוך {branches.length}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <select
                      value={selectedBranches[template._id] || ''}
                      onChange={(e) => setSelectedBranches(prev => ({
                        ...prev,
                        [template._id]: e.target.value
                      }))}
                      className="block w-48 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      disabled={loadingBranches}
                    >
                      {loadingBranches ? (
                        <option>טוען סניפים...</option>
                      ) : (
                        branches
                          .filter(branch => !assignedBranches[template._id]?.includes(branch._id))
                          .map((branch) => (
                            <option key={branch._id} value={branch._id}>
                              {branch.name}
                            </option>
                          ))
                      )}
                    </select>

                    <button
                      onClick={() => handleCreateForms(template._id)}
                      disabled={loading || !selectedBranches[template._id] || 
                        assignedBranches[template._id]?.includes(selectedBranches[template._id])}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                    >
                      <FileCheck className="h-5 w-5 ml-2" />
                      צור טפסים
                    </button>
                    <button
                      onClick={() => handleEyeDoSync(template._id, selectedBranches[template._id])}
                      disabled={!selectedBranches[template._id]}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                    >
                      <ArrowUpToLine className="h-5 w-5 ml-2" />
                      שלח ל-EyeDo
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
} 