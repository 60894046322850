import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import confetti from 'canvas-confetti';

const CelebrationSection = () => {
  useEffect(() => {
    const duration = 3 * 1000;
    const animationEnd = Date.now() + duration;

    const randomInRange = (min, max) => {
      return Math.random() * (max - min) + min;
    };

    const confettiInterval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(confettiInterval);
        return;
      }

      confetti({
        particleCount: 3,
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        origin: { y: 0.6 },
        colors: ['#60A5FA', '#34D399', '#F59E0B', '#EC4899']
      });
    }, 50);

    return () => clearInterval(confettiInterval);
  }, []);

  const messages = [
    {
      title: 'מה למדנו השנה?',
      content: 'שיש לנו צוות מנצח! 💪'
    },
    {
      title: 'איך התמודדנו?',
      content: 'יחד עברנו כל אתגר! 🌟'
    },
    {
      title: 'לאן פנינו?',
      content: 'קדימה לשנה של יעדים חדשים והצלחות! 🎯'
    }
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 }
  };

  return (
    <motion.div
      className="celebration-section"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <motion.h2
        className="celebration-title"
        variants={item}
      >
        סיכום והסתכלות קדימה 🎉
      </motion.h2>

      <motion.div className="celebration-messages" variants={container}>
        {messages.map((message, index) => (
          <motion.div
            key={index}
            className="message-card"
            variants={item}
          >
            <h3>{message.title}</h3>
            <p>{message.content}</p>
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className="final-message"
        variants={item}
      >
        <h3>יחד נמשיך להוביל להצלחה! 🌟</h3>
        <p>לשנה של יעדים חדשים, מצוינות תפעולית והישגים משותפים! 📈</p>
      </motion.div>

      <motion.div
        className="guide-promotion"
        variants={item}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <Link to="/cashier-guide" className="guide-link">
          <div className="promotion-content">
            <h3>🚀 רוצה להשתפר ביכולות גיוס לקוחות לאפליקציה?</h3>
            <p>למד איך להתמודד עם התנגדויות ולשפר את אחוזי ההצלחה!</p>
            <button className="cta-button">
              למדריך המלא <span>←</span>
            </button>
          </div>
        </Link>
      </motion.div>

      <motion.div
        className="credit-text"
        variants={item}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2 }}
      >
        פותח ע״י ישי אלמולי@
      </motion.div>
    </motion.div>
  );
};

export default CelebrationSection; 