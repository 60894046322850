import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft, Award, RotateCcw, Timer as TimerIcon } from 'lucide-react';
import ReactConfetti from 'react-confetti';
import { useClarity, trackClarityEvent } from '../../utils/useClarity';

const scenarios = [
  {
    id: 1,
    stage: "זיהוי הזדמנות",
    situation: "לקוח מגיע לקופה . יש לו סל קניות בשווי 250₪",
    customerResponses: {
      "יש לך את האפליקציה שלנו?": {
        response: "לא, ולא ממש מעניין אותי...",
        feedback: "❌ זו שאלה סגורה שקל לענות עליה 'לא'. נסה להתמקד בערך ובחיסכון הספציפי.",
        isGood: false,
        score: 0,
        category: "פתיחה"
      },
      "שמתי לב שיש לך פה מוצרים ב-250 שקלים - אפשר לחסוך לך 12.5 שקלים ישר עכשיו": {
        response: "באמת? איך זה עובד?",
        feedback: "✅ מצוין! התחלת עם ערך ספציפי והלקוח מתעניין.",
        isGood: true,
        score: 10,
        nextScenario: 2,
        category: "פתיחה"
      },
      "רוצה להוריד את האפליקציה שלנו?": {
        response: "לא תודה, אני ממהר...",
        feedback: "❌ התחלת עם שאלה סגורה במקום להציג ערך.",
        isGood: false,
        score: 0,
        category: "פתיחה"
      },
      "היי, אני רואה שאתה קונה הרבה היום": {
        response: "כן, ומה?",
        feedback: "❌ פתיחה כללית מדי ללא הצגת ערך מיידי",
        isGood: false,
        score: 2,
        category: "פתיחה"
      }
    }
  },
  {
    id: 2,
    stage: "הצגת ערך",
    situation: "הלקוח מתעניין בחיסכון האפשרי",
    customerResponses: {
      "בוא אראה לך משהו מעניין... [מציג את המחיר הרגיל ואת המחיר באפליקציה]": {
        response: "וואו, זה באמת הבדל משמעותי",
        feedback: "✅ מצוין! הדגמת את הערך באופן מוחשי",
        isGood: true,
        score: 10,
        nextScenario: 3,
        category: "הדגמה"
      },
      "תוריד את האפליקציה ותראה בעצמך": {
        response: "אולי אחר כך...",
        feedback: "❌ פספסת הזדמנות להדגים את הערך באופן מיידי",
        isGood: false,
        score: 0,
        category: "הדגמה"
      },
      "תראה, יש לנו מבצעים מטורפים באפליקציה": {
        response: "כן? כמו מה?",
        feedback: "⚠ טוב שציינת שיש מבצעים, אבל עדיף להראות דוגמה ספציפית",
        isGood: false,
        score: 5,
        category: "הדגמה"
      }
    }
  },
  {
    id: 3,
    stage: "התמודדות עם התנגדויות",
    situation: "הלקוח: 'אין לי מקום בטלפון...'",
    customerResponses: {
      "בוא נמחק יחד אפליקציה שלא בשימוש": {
        response: "טוב, אפשר לנסות...",
        feedback: "✅ מצוין! הצעת פתרון מעשי ומיידי",
        isGood: true,
        score: 10,
        nextScenario: 4,
        category: "התנגדויות"
      },
      "האפליקציה לא תופסת הרבה מקום": {
        response: "עדיין, אני מעדיף לא...",
        feedback: "❌ התעלמת מהבעיה האמיתית של הלקוח",
        isGood: false,
        score: 0,
        category: "התנגדויות"
      },
      "אפשר למחק אותה אחרי שתנצל את ההטבה": {
        response: "לא, אני מעדיף לא להתעסק עם זה",
        feedback: "⚠️ הצעת פתרון, אבל הוא לא מיידי ולא מלווה",
        isGood: false,
        score: 3,
        category: "התנגדויות"
      }
    }
  },
  {
    id: 4,
    stage: "סגירה",
    situation: "הלקוח מוכן להתקין את האפליקציה",
    customerResponses: {
      "בוא נוריד את האפליקציה ביחד - אני אדאג שתקבל את כל ההטבות מיד": {
        response: "בסדר, בוא נעשה את זה",
        feedback: "✅ מצוין! הצעת ליווי אישי והבטחת תועלת מיידית",
        isGood: true,
        score: 10,
        isFinal: true,
        category: "סגירה"
      },
      "הנה הקישור להורדה, תוריד כשנוח לך": {
        response: "טוב, אני אעשה את זה אחר כך...",
        feedback: "❌ פספסת את ההזדמנות לסגור את המכירה במקום",
        isGood: false,
        score: 0,
        category: "סגירה"
      },
      "אני אעזור לך להוריד עכשיו": {
        response: "טוב, אבל מהר...",
        feedback: "⚠️ טוב שהצעת עזרה מיידית, אבל כדאי גם להדגיש את התועלת",
        isGood: false,
        score: 7,
        category: "סגירה"
      }
    }
  }
];

const TimerDisplay = ({ startTime }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Math.floor((Date.now() - startTime) / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return (
    <div className="text-sm text-gray-600 flex items-center gap-1">
      <TimerIcon size={14} />
      {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </div>
  );
};

const ProgressBar = ({ currentScenario, totalScenarios }) => (
  <div className="w-full bg-gray-200 h-1 rounded-full overflow-hidden">
    <div 
      className="h-full bg-blue-500 transition-all duration-500"
      style={{ width: `${(currentScenario / totalScenarios) * 100}%` }}
    />
  </div>
);

const ChatMessage = ({ message, isUser, isSystem, animate = true }) => (
  <motion.div
    initial={animate ? { opacity: 0, y: 20 } : false}
    animate={animate ? { opacity: 1, y: 0 } : false}
    exit={{ opacity: 0, y: -20 }}
    className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}
  >
    <div className={`max-w-[85%] sm:max-w-[70%] p-3 rounded-xl ${
      isSystem ? 'bg-yellow-50 border border-yellow-200' :
      isUser ? 'bg-blue-500 text-white' : 'bg-gray-100'
    } shadow-sm`}>
      {message}
    </div>
  </motion.div>
);

const ScoreCard = ({ categoryScores, totalScore, maxScore }) => (
  <div className="bg-white p-4 rounded-xl shadow-md border border-gray-100">
    <h3 className="text-lg sm:text-xl font-bold mb-4 text-gray-800">ניתוח ביצועים</h3>
    <div className="space-y-3">
      {Object.entries(categoryScores).map(([category, score]) => (
        <div key={category} className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-2">
          <span className="font-medium text-gray-700 min-w-[80px]">{category}</span>
          <div className="flex-1 flex items-center gap-2">
            <div className="flex-1 h-2 bg-gray-200 rounded-full">
              <div 
                className={`h-2 rounded-full ${
                  score.percentage >= 80 ? 'bg-green-500' :
                  score.percentage >= 50 ? 'bg-yellow-500' :
                  'bg-red-500'
                }`}
                style={{ width: `${score.percentage}%` }}
              />
            </div>
            <span className="text-sm text-gray-600 min-w-[80px] text-left">
              {score.score}/{score.maxScore}
            </span>
          </div>
        </div>
      ))}
      <div className="border-t pt-3 mt-3">
        <div className="flex justify-between items-center font-bold text-gray-800">
          <span>ציון כולל</span>
          <span>
            {totalScore}/{maxScore} ({Math.round((totalScore/maxScore) * 100)}%)
          </span>
        </div>
      </div>
    </div>
  </div>
);

const SalesPractice = () => {
  useClarity(); // Initialize Clarity

  const [currentScenario, setCurrentScenario] = useState(0);
  const [messages, setMessages] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [score, setScore] = useState(0);
  const [gameState, setGameState] = useState('playing');
  const [categoryScores, setCategoryScores] = useState({});
  const [responses, setResponses] = useState({});
  const [startTime] = useState(Date.now());
  const [isResponseDisabled, setIsResponseDisabled] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Add ref for chat area
  const chatAreaRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, feedback]); // Scroll when messages or feedback change

  useEffect(() => {
    if (currentScenario < scenarios.length) {
      setMessages([
        { 
          text: `שלב ${currentScenario + 1}: ${scenarios[currentScenario].stage}`,
          isSystem: true 
        },
        { 
          text: scenarios[currentScenario].situation, 
          isUser: false 
        }
      ]);
    }
  }, [currentScenario]);

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateScores = () => {
    const scores = {};
    let total = 0;

    // Get unique categories from scenarios
    const categories = new Set();
    scenarios.forEach(scenario => {
      Object.values(scenario.customerResponses).forEach(response => {
        categories.add(response.category);
      });
    });

    // Initialize scores for each category
    categories.forEach(category => {
      scores[category] = { score: 0, maxScore: 10, percentage: 0 };
    });

    // Take the highest score for each category
    Object.entries(responses).forEach(([category, response]) => {
      if (response && response.score > scores[category].score) {
        scores[category].score = response.score;
      }
    });

    // Calculate total and percentages
    Object.values(scores).forEach(categoryScore => {
      total += categoryScore.score;
      categoryScore.percentage = (categoryScore.score / categoryScore.maxScore) * 100;
    });

    setCategoryScores(scores);
    return { 
      total, 
      maxTotal: categories.size * 10 
    };
  };

  const calculateAndSetFinalScores = (responses) => {
    const scores = {};
    const categories = new Set();
    let total = 0;
    
    // Get unique categories
    Object.values(responses).forEach(response => {
      categories.add(response.category);
    });

    // Initialize scores
    categories.forEach(category => {
      scores[category] = {
        score: 0,
        maxScore: 10,
        percentage: 0
      };
    });

    // Take the highest score for each category
    Object.values(responses).forEach(response => {
      const category = response.category;
      if (response.score > scores[category].score) {
        scores[category].score = response.score;
        scores[category].percentage = (response.score / 10) * 100;
      }
    });

    // Calculate total after all highest scores are determined
    Object.values(scores).forEach(categoryScore => {
      total += categoryScore.score;
    });

    setCategoryScores(scores);
    return { total, maxTotal: categories.size * 10 };
  };

  const handleResponse = async (response, responseData) => {
    setIsResponseDisabled(true);
    
    // Track response in Clarity
    trackClarityEvent('practice_response', {
      stage: scenarios[currentScenario].stage,
      response: response,
      isCorrect: responseData.isGood,
      score: responseData.score
    });
    
    const newResponses = {
      ...responses,
      [responseData.category]: responseData
    };
    setResponses(newResponses);

    const currentScores = calculateAndSetFinalScores(newResponses);
    setScore(currentScores.total);

    setMessages(prev => [...prev, { text: response, isUser: true }]);
    
    await new Promise(resolve => setTimeout(resolve, 500));
    
    setMessages(prev => [...prev, { text: responseData.response, isUser: false }]);
    setFeedback(responseData.feedback);
    
    if (responseData.isGood) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);

      if (responseData.isFinal) {
        setTimeout(() => {
          setGameState('success');
        }, 1500);
      } else {
        setIsResponseDisabled(false);
      }
    } else {
      setTimeout(() => {
        setIsResponseDisabled(false);
      }, 1500);
    }
  };

  const handleNextQuestion = () => {
    setCurrentScenario(prev => prev + 1);
    setFeedback(null);
  };

  const resetPractice = () => {
    // Track practice reset
    trackClarityEvent('practice_reset', {
      finalScore: score,
      timeSpent: Math.floor((Date.now() - startTime) / 1000)
    });
    
    setCurrentScenario(0);
    setMessages([]);
    setFeedback(null);
    setScore(0);
    setResponses({});
    setCategoryScores({});
    setGameState('playing');
  };

  // Track practice completion
  useEffect(() => {
    if (gameState === 'success') {
      trackClarityEvent('practice_complete', {
        finalScore: score,
        categoryScores: Object.fromEntries(
          Object.entries(categoryScores).map(([category, data]) => [
            category,
            data.percentage
          ])
        ),
        timeSpent: Math.floor((Date.now() - startTime) / 1000)
      });
    }
  }, [gameState]);

  return (
    <div className="min-h-screen bg-gray-50 relative overflow-hidden">
      {showConfetti && (
        <div className="fixed inset-0 pointer-events-none z-50">
          <ReactConfetti
            width={windowSize.width}
            height={windowSize.height}
            recycle={false}
            numberOfPieces={200}
            gravity={0.3}
          />
        </div>
      )}
      <div className="max-w-2xl mx-auto p-3 sm:p-4 h-screen flex flex-col">
        <div className="bg-white rounded-xl shadow-lg flex-1 flex flex-col overflow-hidden">
          <div className="border-b bg-white sticky top-0 z-10">
            <div className="flex justify-between items-center p-4">
              <Link 
                to="/cashier-guide" 
                className="text-blue-500 hover:text-blue-600 flex items-center gap-1 text-sm sm:text-base"
              >
                <ArrowLeft size={16} />
                <span className="hidden sm:inline">חזרה למדריך</span>
              </Link>
              <h1 className="text-lg sm:text-xl font-bold text-gray-800">אימון מכירות</h1>
              <div className="flex items-center gap-3">
                <TimerDisplay startTime={startTime} />
                <div className="text-sm sm:text-base font-semibold bg-blue-50 text-blue-600 px-3 py-1 rounded-full">
                  {score} נקודות
                </div>
              </div>
            </div>
            <ProgressBar 
              currentScenario={currentScenario} 
              totalScenarios={scenarios.length} 
            />
          </div>
          
          {/* Chat Area */}
          <div 
            ref={chatAreaRef}
            className="flex-1 overflow-y-auto p-4 bg-gray-50"
          >
            <div className="space-y-4">
              {messages.map((msg, idx) => (
                <ChatMessage 
                  key={idx} 
                  message={msg.text} 
                  isUser={msg.isUser}
                  isSystem={msg.isSystem}
                  animate={!isResponseDisabled}
                />
              ))}
              
              {feedback && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white border border-gray-200 p-4 rounded-xl shadow-sm mt-4"
                >
                  {feedback}
                  {feedback.includes("✅") && currentScenario < scenarios.length - 1 && (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 1 }}
                      onClick={handleNextQuestion}
                      className="mt-4 w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                    >
                      המשך לשאלה הבאה
                    </motion.button>
                  )}
                </motion.div>
              )}
              <div ref={messagesEndRef} /> {/* Scroll anchor */}
            </div>
          </div>

          {/* Response Buttons or Success State */}
          <div className="border-t bg-white p-3 sm:p-4 sticky bottom-0">
            {gameState === 'playing' && currentScenario < scenarios.length && (
              <motion.div 
                className="grid grid-cols-1 gap-2"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                {Object.entries(scenarios[currentScenario].customerResponses).map(([response, data]) => (
                  <motion.button
                    key={response}
                    onClick={() => handleResponse(response, data)}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.99 }}
                    className="bg-white border-2 border-blue-500 text-blue-600 p-3 rounded-xl hover:bg-blue-50 
                             transition-colors text-right text-sm sm:text-base shadow-sm"
                  >
                    {response}
                  </motion.button>
                ))}
              </motion.div>
            )}

            {gameState === 'success' && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="space-y-4 overflow-y-auto max-h-[60vh]"
              >
                <div className="text-center bg-green-50 p-4 rounded-xl border border-green-200">
                  <span className="text-2xl mb-2">🎉</span>
                  <h2 className="text-lg sm:text-xl font-bold text-green-700">
                    כל הכבוד! סיימת את האימון בהצלחה!
                  </h2>
                </div>
                
                <ScoreCard 
                  categoryScores={categoryScores}
                  totalScore={score}
                  maxScore={Object.keys(categoryScores).length * 10}
                />
                
                <motion.button
                  onClick={resetPractice}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full bg-blue-500 text-white px-6 py-3 rounded-xl hover:bg-blue-600 
                           transition-colors font-medium shadow-sm mb-4"
                >
                  התחל מחדש
                </motion.button>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesPractice;