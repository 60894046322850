import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Lock, Save, RefreshCw, AlertCircle, CheckCircle } from 'lucide-react';

const UserProfile = () => {
  const [profile, setProfile] = useState({
    fullName: '',
    email: '',
    username: '',
    branches: [],
    branchNames: []
  });
  const [password, setPassword] = useState({ current: '', new: '', confirm: '' });
  const [message, setMessage] = useState({ type: '', content: '' });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/users/profile`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setProfile({
        ...response.data,
        branches: response.data.branches || [],
        branchNames: response.data.branchNames || []
      });
    } catch (error) {
      console.error('שגיאה בטעינת פרופיל המשתמש:', error);
      setMessage({ type: 'error', content: 'שגיאה בטעינת פרטי המשתמש' });
    }
  };

  const handleProfileChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { fullName, email } = profile;
      const token = localStorage.getItem('token');
      
      await axios.put(
        `${API_URL}/api/users/profile`,
        { fullName, email },
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // Fetch the updated profile to ensure we have all data
      await fetchUserProfile();
      
      // Updated success message in Hebrew
      setMessage({ type: 'success', content: 'הפרופיל עודכן בהצלחה!' });
    } catch (error) {
      console.error('Error in profile update:', error.response ? error.response.data : error);
      setMessage({ 
        type: 'error', 
        content: `שגיאה בעדכון הפרופיל: ${error.response?.data?.message || error.message}. ${error.response?.data?.error || ''}`
      });
    } finally {
      setIsLoading(false);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    if (password.new !== password.confirm) {
      setMessage({ type: 'error', content: 'הסיסמאות החדשות אינן תואמות' });
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(
        `${API_URL}/api/users/change-password`,
        { currentPassword: password.current, newPassword: password.new },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setMessage({ type: 'success', content: 'הסיסמה שונתה בהצלחה' });
      setPassword({ current: '', new: '', confirm: '' });
    } catch (error) {
      console.error('שגיאה בשינוי הסיסמה:', error.response ? error.response.data : error);
      setMessage({ 
        type: 'error', 
        content: `שגיאה בשינוי הסיסמה: ${error.response?.data?.message || error.message}`
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8" dir="rtl">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">פרופיל אישי</h1>
        
        <div className="bg-white rounded-xl shadow-xl p-8">
          {message.content && (
            <div 
              className={`mb-6 p-4 rounded-lg ${
                message.type === 'error' 
                  ? 'bg-red-50 text-red-700 border border-red-200' 
                  : 'bg-green-50 text-green-700 border border-green-200'
              }`} 
              role="alert"
            >
              <div className="flex items-center">
                {message.type === 'error' 
                  ? <AlertCircle className="h-5 w-5 ml-2" /> 
                  : <CheckCircle className="h-5 w-5 ml-2" />
                }
                <p>{message.content}</p>
              </div>
            </div>
          )}
          
          <form onSubmit={updateProfile} className="space-y-6">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">
                  שם מלא
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={profile.fullName}
                  onChange={handleProfileChange}
                  className="block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  אימייל
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profile.email}
                  onChange={handleProfileChange}
                  className="block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                />
              </div>

              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                  שם משתמש
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={profile.username}
                  readOnly
                  className="block w-full p-3 border border-gray-300 rounded-lg bg-gray-50 cursor-not-allowed"
                />
              </div>

              {/* Branches with null check */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  סניפים מקושרים
                </label>
                <div className="min-h-[60px] block w-full p-3 border border-gray-300 rounded-lg bg-gray-50">
                  {Array.isArray(profile.branchNames) && profile.branchNames.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {profile.branchNames.map((branchName, index) => (
                        <span 
                          key={index} 
                          className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                        >
                          {branchName}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <span className="text-gray-500">לא מקושרים סניפים</span>
                  )}
                </div>
              </div>
            </div>

            <button 
              type="submit" 
              className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? (
                <RefreshCw className="animate-spin h-5 w-5 ml-3" />
              ) : (
                <Save className="h-5 w-5 ml-2" />
              )}
              עדכן פרופיל
            </button>
          </form>

          <div className="border-t border-gray-200 mt-8 pt-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-6">שינוי סיסמה</h3>
            <form onSubmit={changePassword} className="space-y-4">
              {/* Password Fields */}
              <div>
                <label htmlFor="current" className="block text-sm font-medium text-gray-700">סיסמה נוכחית</label>
                <input
                  type="password"
                  id="current"
                  name="current"
                  value={password.current}
                  onChange={handlePasswordChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label htmlFor="new" className="block text-sm font-medium text-gray-700">סיסמה חדשה</label>
                <input
                  type="password"
                  id="new"
                  name="new"
                  value={password.new}
                  onChange={handlePasswordChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label htmlFor="confirm" className="block text-sm font-medium text-gray-700">אימות סיסמה חדשה</label>
                <input
                  type="password"
                  id="confirm"
                  name="confirm"
                  value={password.confirm}
                  onChange={handlePasswordChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <button 
                type="submit" 
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={isLoading}
              >
                {isLoading ? (
                  <RefreshCw className="animate-spin h-5 w-5 ml-3" />
                ) : (
                  <Lock className="h-5 w-5 ml-2" />
                )}
                שנה סיסמה
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;