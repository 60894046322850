import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createInventoryForm, getBranches } from '../../utils/api';
import { PlusIcon, TrashIcon, ArrowPathIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';

const questionTypes = [
  { value: 'text', label: 'טקסט' },
  { value: 'number', label: 'מספר' },
  { value: 'boolean', label: 'כן/לא' },
  { value: 'multipleChoice', label: 'בחירה מרובה' },
];

const CreateInventoryForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector(state => state.auth.user);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [branches, setBranches] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(true);
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    branch: '',
    timeLimit: '24',
    questions: [
      {
        questionText: '',
        questionType: 'text',
        required: true,
        options: [],
        order: 1,
        branchingLogic: {},
      },
    ],
  });

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        setLoadingBranches(true);
        const data = await getBranches();
        setBranches(data);
        
        if (data.length > 0) {
          const defaultBranch = data[0]._id;
          setSelectedBranch(defaultBranch);
          setFormData(prev => ({ ...prev, branch: defaultBranch }));
        }
      } catch (err) {
        console.error('Error fetching branches:', err);
        setError('Failed to load branches. Please try again.');
      } finally {
        setLoadingBranches(false);
      }
    };

    fetchBranches();
  }, []);

  if (!user) {
    return (
      <div className="p-4">
        <p className="text-red-500">Error: User not authenticated</p>
      </div>
    );
  }

  if (!['admin', 'manager'].includes(user.role)) {
    return (
      <div className="p-4">
        <p className="text-red-500">Error: Insufficient permissions. Only managers and administrators can create inventory forms.</p>
      </div>
    );
  }

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...formData.questions];
    newQuestions[index][field] = value;
    
    // Initialize options array if switching to multiple choice
    if (field === 'questionType' && value === 'multipleChoice') {
      newQuestions[index].options = [''];
      newQuestions[index].branchingLogic = [{ nextQuestionText: '' }];
    }
    
    setFormData({ ...formData, questions: newQuestions });
  };

  const addQuestion = () => {
    setFormData({
      ...formData,
      questions: [
        ...formData.questions,
        {
          questionText: '',
          questionType: 'text',
          required: true,
          options: [],
          order: formData.questions.length + 1,
          branchingLogic: {},
        },
      ],
    });
  };

  const removeQuestion = (index) => {
    const updatedQuestions = formData.questions.filter((_, i) => i !== index);
    updatedQuestions.forEach((q, i) => {
      q.order = i + 1;
    });
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...formData.questions];
    newQuestions[questionIndex].options.push('');
    newQuestions[questionIndex].branchingLogic.push({ nextQuestionText: '' });
    setFormData({ ...formData, questions: newQuestions });
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...formData.questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setFormData({ ...formData, questions: newQuestions });
  };

  const handleBranchingLogicChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...formData.questions];
    if (!newQuestions[questionIndex].branchingLogic) {
      newQuestions[questionIndex].branchingLogic = [];
    }
    if (!newQuestions[questionIndex].branchingLogic[optionIndex]) {
      newQuestions[questionIndex].branchingLogic[optionIndex] = {};
    }
    newQuestions[questionIndex].branchingLogic[optionIndex].nextQuestionText = value;
    setFormData({ ...formData, questions: newQuestions });
  };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...formData.questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    newQuestions[questionIndex].branchingLogic.splice(optionIndex, 1);
    setFormData({ ...formData, questions: newQuestions });
  };

  const handleBranchChange = (event) => {
    const branchId = event.target.value;
    setSelectedBranch(branchId);
    setFormData(prev => ({ ...prev, branch: branchId }));
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      throw new Error('נדרש להזין כותרת לשאלון');
    }
    if (!formData.branch) {
      throw new Error('נדרש לבחור סניף');
    }
    if (!formData.questions.length) {
      throw new Error('נדרש להוסיף לפחות שאלה אחת');
    }
    for (const question of formData.questions) {
      if (!question.questionText.trim()) {
        throw new Error('נדרש להזין טקסט לכל השאלות');
      }
      if (question.questionType === 'multipleChoice') {
        if (!question.options.length || question.options.some(opt => !opt.trim())) {
          throw new Error('נדרש להזין לפחות אפשרות אחת לשאלות מסוג בחירה מרובה');
        }
        // Validate branching logic
        for (const [optionIndex, logic] of Object.entries(question.branchingLogic)) {
          if (logic.nextQuestionText && !logic.nextQuestionText.trim()) {
            throw new Error('נדרש להזין טקסט לשאלות המשך');
          }
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      validateForm();
      await createInventoryForm(formData);
      navigate('/dashboard/inventory');
    } catch (err) {
      console.error('Error creating form:', err);
      setError(err.message || 'Error creating form');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <div className="max-w-4xl mx-auto">
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-t-xl p-6 shadow-lg">
          <h1 className="text-2xl font-bold text-white mb-2">יצירת שאלון מלאי חדש</h1>
          <p className="text-blue-100">מלא את הפרטים הנדרשים ליצירת שאלון חדש</p>
        </div>

        <form onSubmit={handleSubmit} className="bg-white rounded-b-xl shadow-lg">
          <div className="p-6 space-y-6">
            {/* Form Header Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  כותרת השאלון <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  סניף <span className="text-red-500">*</span>
                </label>
                {loadingBranches ? (
                  <div className="animate-pulse bg-gray-200 h-10 rounded-lg" />
                ) : (
                  <select
                    value={selectedBranch}
                    onChange={handleBranchChange}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  >
                    <option value="">בחר סניף</option>
                    {branches.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  תיאור
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  rows={3}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  זמן להשלמה (שעות) <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  min="1"
                  max="72"
                  value={formData.timeLimit}
                  onChange={(e) => setFormData({ ...formData, timeLimit: e.target.value })}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">הגדר זמן בשעות להשלמת הטופס (מקסימום 72 שעות)</p>
              </div>
            </div>

            {/* Questions Section */}
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-gray-800">שאלות</h2>
                <button
                  type="button"
                  onClick={addQuestion}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <PlusIcon className="h-5 w-5 ml-2" />
                  הוסף שאלה
                </button>
              </div>

              <div className="space-y-4">
                {formData.questions.map((question, questionIndex) => (
                  <div key={questionIndex} className="bg-gray-50 rounded-xl p-6 border border-gray-200">
                    <div className="flex justify-between items-start mb-4">
                      <h3 className="text-lg font-medium text-gray-800">
                        שאלה {question.order}
                      </h3>
                      <button
                        type="button"
                        onClick={() => removeQuestion(questionIndex)}
                        disabled={formData.questions.length === 1}
                        className="text-red-500 hover:text-red-700 disabled:opacity-50"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>

                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          טקסט השאלה <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          value={question.questionText}
                          onChange={(e) => handleQuestionChange(questionIndex, 'questionText', e.target.value)}
                          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          required
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            סוג שאלה
                          </label>
                          <select
                            value={question.questionType}
                            onChange={(e) => handleQuestionChange(questionIndex, 'questionType', e.target.value)}
                            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          >
                            {questionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="flex items-center">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              checked={question.required}
                              onChange={(e) => handleQuestionChange(questionIndex, 'required', e.target.checked)}
                              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 h-4 w-4"
                            />
                            <span className="mr-2 text-sm text-gray-700">חובה</span>
                          </label>
                        </div>
                      </div>

                      {/* Multiple Choice Options */}
                      {question.questionType === 'multipleChoice' && (
                        <div className="mt-4 space-y-4">
                          <div className="space-y-2">
                            {question.options.map((option, optionIndex) => (
                              <div key={optionIndex} className="flex flex-col gap-2">
                                <div className="flex items-center gap-2">
                                  <input
                                    type="text"
                                    value={option}
                                    onChange={(e) => handleOptionChange(questionIndex, optionIndex, e.target.value)}
                                    placeholder={`אפשרות ${optionIndex + 1}`}
                                    className="flex-1 p-2 border rounded-lg"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeOption(questionIndex, optionIndex)}
                                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                                  >
                                    <XMarkIcon className="h-5 w-5" />
                                  </button>
                                </div>
                                
                                {/* Branching Logic Input */}
                                <div className="mr-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                                  <label className="block text-sm font-medium text-gray-700 mb-2">
                                    שאלת המשך (אופציונלי)
                                  </label>
                                  <input
                                    type="text"
                                    value={question.branchingLogic?.[optionIndex]?.nextQuestionText || ''}
                                    onChange={(e) => handleBranchingLogicChange(questionIndex, optionIndex, e.target.value)}
                                    placeholder="הזן שאלת המשך שתוצג כאשר נבחרת אפשרות זו..."
                                    className="w-full p-2 border rounded-lg"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          <button
                            type="button"
                            onClick={() => addOption(questionIndex)}
                            className="mt-2 px-4 py-2 text-sm bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100"
                          >
                            הוסף אפשרות +
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {error && (
              <div className="p-4 bg-red-50 border-r-4 border-red-500 text-red-700 rounded-lg">
                {error}
              </div>
            )}

            <div className="flex gap-4 pt-4 border-t">
              <button
                type="submit"
                disabled={loading}
                className={`px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {loading ? (
                  <span className="inline-flex items-center">
                    <ArrowPathIcon className="h-5 w-5 ml-2 animate-spin" />
                    יוצר שאלון...
                  </span>
                ) : (
                  'צור שאלון'
                )}
              </button>
              <button
                type="button"
                onClick={() => navigate('/dashboard/inventory')}
                disabled={loading}
                className="px-6 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                ביטול
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateInventoryForm; 