import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';

export const useBranchManagement = () => {
  const [newBranch, setNewBranch] = useState('');
  const [newBranchId, setNewBranchId] = useState('');
  const [branches, setBranches] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBranches(response.data);
    } catch (error) {
      setError('Failed to load branches. Please try again later.');
      console.error('Error fetching branches:', error);
    }
  };

  const handleNewBranchChange = (e) => {
    setNewBranch(e.target.value);
    setError('');
  };

  const handleNewBranchIdChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) > 0 && Number(value) <= 9999)) {
      setNewBranchId(value);
      setError('');
    }
  };

  const addNewBranch = async () => {
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_URL}/api/branches`,
        {
          name: newBranch.trim(),
          branch_id: parseInt(newBranchId)
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );

      setBranches([...branches, response.data]);
      setNewBranch('');
      setNewBranchId('');
      setSuccess('הסניף נוסף בהצלחה');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to add branch. Please try again.');
      console.error('Error adding branch:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBranch = async (branchId) => {
    if (!window.confirm('האם אתה בטוח שברצונך למחוק את הסניף?')) {
      return;
    }

    try {
      await axios.delete(`${API_URL}/api/branches/${branchId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      setBranches(branches.filter(branch => branch._id !== branchId));
      setSuccess('הסניף נמחק בהצלחה');
    } catch (error) {
      setError('Failed to delete branch. Please try again.');
      console.error('Error deleting branch:', error);
    }
  };

  const updateBranch = async (branchId, updatedData) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/branches/${branchId}`,
        updatedData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );

      setBranches(branches.map(branch => 
        branch._id === branchId ? response.data : branch
      ));
      
      setSuccess('הסניף עודכן בהצלחה');
      
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update branch');
      console.error('Error updating branch:', error);
    }
  };

  return {
    newBranch,
    newBranchId,
    branches,
    handleNewBranchChange,
    handleNewBranchIdChange,
    addNewBranch,
    deleteBranch,
    searchTerm,
    setSearchTerm,
    error,
    success,
    isLoading,
    updateBranch
  };
}; 