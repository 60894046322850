import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InventoryFormList from './InventoryFormList';
import CreateInventoryForm from './CreateInventoryForm';
import InventoryFormStats from './InventoryFormStats';
import PublicInventoryForm from './PublicInventoryForm';
import TemplateUpload from './TemplateUpload';

const InventoryRoutes = () => {
  return (
    <Routes>
      {/* List all forms */}
      <Route index element={<InventoryFormList />} />
      
      {/* Create new form */}
      <Route path="forms/new" element={<CreateInventoryForm />} />
      
      {/* View form statistics */}
      <Route path="forms/:formId/stats" element={<InventoryFormStats />} />

      {/* Public form access */}
      <Route path="form/:token" element={<PublicInventoryForm />} />

      {/* Excel template upload */}
      <Route path="upload-template" element={<TemplateUpload />} />
    </Routes>
  );
};

export default InventoryRoutes; 