import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Upload, X, Camera } from 'lucide-react';
import { toast } from 'react-hot-toast';
import api from '../../utils/api';

const STATUS_COLORS = {
  pending: 'bg-red-100 text-red-800',
  in_progress: 'bg-orange-100 text-orange-800',
  completed: 'bg-green-100 text-green-800'
};

const STATUS_LABELS = {
  pending: 'ממתין לטיפול',
  in_progress: 'בטיפול',
  completed: 'בוצע'
};

const RejectionForm = () => {
  const { token } = useParams();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemStates, setItemStates] = useState({});
  const [uploadingImage, setUploadingImage] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});

  const fetchForm = useCallback(async () => {
    try {
      const response = await api.get(`/api/rejection-forms/access/${token}`);
      console.log('Fetched form data:', response.data);
      
      if (new Date(response.data.expiresAt) < new Date()) {
        toast.error('הטופס פג תוקף');
        setError('הטופס פג תוקף');
        setLoading(false);
        return;
      }
      
      setForm(response.data);
      setLoading(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'שגיאה בטעינת הטופס';
      toast.error(errorMessage);
      setError(errorMessage);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchForm();
  }, [token, fetchForm]);

  useEffect(() => {
    if (form) {
      const initialStates = {};
      const initialStatus = {};
      form.items.forEach(item => {
        initialStates[item._id] = {
          newComment: '',
          selectedImages: null,
          imagePreviews: null
        };
        initialStatus[item._id] = item.status;
      });
      setItemStates(initialStates);
      setSelectedStatus(initialStatus);
    }
  }, [form]);

  const handleImageSelect = async (itemId, event) => {
    const files = Array.from(event.target.files);
    if (files.length > 5) {
      toast.error('ניתן להעלות עד 5 תמונות בבת אחת');
      return;
    }

    try {
      // Get existing images if any
      const existingImages = itemStates[itemId]?.selectedImages || [];
      const existingPreviews = itemStates[itemId]?.imagePreviews || [];

      // Process new files
      const processedFiles = await Promise.all(files.map(async file => {
        const compressedFile = await compressImage(file);
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              file: compressedFile,
              preview: reader.result
            });
          };
          reader.readAsDataURL(compressedFile);
        });
      }));

      // Combine existing and new images
      const totalImages = [...existingImages, ...processedFiles.map(p => p.file)];
      const totalPreviews = [...existingPreviews, ...processedFiles.map(p => p.preview)];

      // Check if total exceeds 5
      if (totalImages.length > 5) {
        toast.error('סך הכל ניתן להעלות עד 5 תמונות');
        return;
      }

      setItemStates(prev => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          selectedImages: totalImages,
          imagePreviews: totalPreviews
        }
      }));

      // Reset the file input
      event.target.value = '';
    } catch (error) {
      console.error('Error processing images:', error);
      toast.error('שגיאה בטעינת התמונות');
    }
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Max dimensions
          const MAX_WIDTH = 1920;
          const MAX_HEIGHT = 1080;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            }));
          }, 'image/jpeg', 0.7); // 0.7 quality gives good balance between size and quality
        };
        img.onerror = reject;
        img.src = event.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleUpdateItem = async (itemId) => {
    try {
      const itemState = itemStates[itemId];
      const item = form.items.find(i => i._id === itemId);
      
      // Prevent updates to completed items
      if (item.status === 'completed') {
        toast.error('לא ניתן לערוך פריט שהושלם');
        return;
      }

      // Check if photo is required but missing
      if (item.requiresPhoto && (!itemState.selectedImages?.length && !item.imageUrls?.length)) {
        toast.error('חובה לצרף תמונה לפריט זה');
        return;
      }

      let imageUrls = [];

      if (itemState.selectedImages?.length) {
        setUploadingImage(true);
        toast.loading('מעלה תמונות...', { id: 'uploadImages' });
        
        try {
          // Upload all images in parallel
          const uploadPromises = itemState.selectedImages.map(async (image) => {
            const formData = new FormData();
            formData.append('image', image);
            
            const uploadResponse = await api.post(
              `/api/rejection-forms/upload-image/${token}`, 
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' }
              }
            );
            
            if (!uploadResponse.data.imageUrl) {
              throw new Error('No image URL in response');
            }
            
            return uploadResponse.data.imageUrl;
          });

          imageUrls = await Promise.all(uploadPromises);
          toast.success('התמונות הועלו בהצלחה', { id: 'uploadImages' });
        } catch (uploadError) {
          console.error('Error uploading images:', uploadError);
          toast.error('שגיאה בהעלאת התמונות', { id: 'uploadImages' });
          return;
        } finally {
          setUploadingImage(false);
        }
      }

      const updateData = {
        status: selectedStatus[itemId],
        ...(itemState.newComment && { comment: itemState.newComment }),
        ...(imageUrls.length > 0 && { imageUrls })
      };

      toast.loading('שולח עדכון...', { id: 'updateItem' });
      await api.patch(`/api/rejection-forms/access/${token}/items/${itemId}`, updateData);

      setItemStates(prev => ({
        ...prev,
        [itemId]: {
          newComment: '',
          selectedImages: null,
          imagePreviews: null
        }
      }));

      await fetchForm();
      toast.success('העדכון נשלח בהצלחה', { id: 'updateItem' });
    } catch (error) {
      console.error('Error updating item:', error);
      toast.error('שגיאה בעדכון הפריט', { id: 'updateItem' });
    }
  };

  if (loading) return <div className="text-center p-8">טוען...</div>;
  if (error) return <div className="text-center p-8 text-red-600">{error}</div>;
  if (!form) return <div className="text-center p-8">הטופס לא נמצא</div>;

  return (
    <div className="min-h-screen bg-gray-50" dir="rtl">
      <div className="max-w-4xl mx-auto p-2 sm:p-6">
        {/* Mobile-optimized Header */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl shadow-lg overflow-hidden">
          {/* Main Header Content */}
          <div className="p-4">
            <div className="flex flex-col sm:flex-row gap-4 items-center justify-between mb-4">
              <div>
                <h1 className="text-lg sm:text-2xl font-bold text-center sm:text-right">{form.branchName}</h1>
                <p className="text-sm text-blue-100 text-center sm:text-right">מפקח: {form.inspector}</p>
              </div>
              
              {/* Dominant Days Counter */}
              {(() => {
                const diffDays = Math.ceil((new Date(form.dueDate) - new Date()) / (1000 * 60 * 60 * 24));
                const isOverdue = diffDays < 0;
                const isUrgent = diffDays <= 2;
                
                return (
                  <div className={`
                    rounded-xl p-3 text-center min-w-[200px]
                    ${isOverdue 
                      ? 'bg-red-500/90 text-white' 
                      : isUrgent 
                        ? 'bg-orange-500/90 text-white'
                        : 'bg-emerald-100 text-emerald-800'
                    } 
                    shadow-lg ${!isOverdue && !isUrgent ? 'border border-emerald-200' : 'border-2 border-white/20'}
                  `}>
                    <div className="text-2xl font-bold mb-1">
                      {isOverdue ? `${Math.abs(diffDays)}` : diffDays}
                    </div>
                    <div className={`text-sm ${isOverdue || isUrgent ? 'text-white/90' : 'text-emerald-700'}`}>
                      {isOverdue ? 'ימים בחריגה ⚠️' :
                       diffDays === 0 ? 'להשלים היום! ⚠️' :
                       'ימים להשלמה'}
                    </div>
                  </div>
                );
              })()}
            </div>
          </div>
          
          {/* Dates Section - Mobile-friendly grid */}
          <div className="bg-blue-900 bg-opacity-50 p-4">
            <div className="grid grid-cols-2 gap-3">
              <div className="bg-blue-500 bg-opacity-25 rounded-lg p-2">
                <span className="text-xs text-blue-200">תאריך דוח</span>
                <p className="font-semibold text-sm">
                  {new Date(form.reportDate).toLocaleDateString('he-IL')}
                </p>
              </div>
              <div className={`rounded-lg p-2 ${
                new Date(form.dueDate) < new Date() 
                  ? 'bg-red-500 bg-opacity-25' 
                  : 'bg-blue-500 bg-opacity-25'
              }`}>
                <span className="text-xs text-blue-200">תאריך יעד</span>
                <p className="font-semibold text-sm">
                  {new Date(form.dueDate).toLocaleDateString('he-IL')}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* User Guide */}
        <div className="bg-blue-50 border border-blue-200 rounded-xl p-4 mt-4 mb-2">
          <h2 className="font-medium text-blue-800 mb-2 text-lg">איך ממלאים את הטופס?</h2>
          <div className="space-y-2 text-sm text-blue-700">
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">1</span>
              <span>בחר את הסטטוס המתאים לכל משימה (ממתין לטיפול / בטיפול / בוצע)</span>
            </p>
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">2</span>
              <span>הוסף הערה המתארת את הפעולות שבוצעו</span>
            </p>
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">3</span>
              <span>צרף תמונה להמחשה (אם נדרש)</span>
            </p>
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">4</span>
              <span>לחץ על 'שלח עדכון' לשמירת השינויים</span>
            </p>
          </div>
          <div className="mt-3 text-sm text-blue-600">
            <p>💡 טיפ: לחץ על 'היסטוריית עדכונים' כדי לראות את כל העדכוני הקודמים</p>
          </div>
        </div>

        {/* Simplified Tasks List for Mobile */}
        <div className="bg-white rounded-xl shadow-lg mt-4">
          <div className="p-3 sm:p-6 space-y-6">
            {form.items.map((item) => (
              <div key={item._id} className="border border-gray-200 rounded-xl overflow-hidden">
                {/* Task Header */}
                <div className="p-4 bg-gray-50">
                  <div className="flex items-start justify-between gap-2">
                    <h3 className="text-base font-medium text-gray-800 flex-1">{item.description}</h3>
                    <span className={`shrink-0 px-3 py-1 rounded-full text-sm ${STATUS_COLORS[item.status]}`}>
                      {STATUS_LABELS[item.status]}
                    </span>
                  </div>
                </div>

                {/* Task Content */}
                <div className="p-4">
                  {/* Original Image with Toggle */}
                  {item.imageUrl && (
                    <div className="mb-4">
                      <button 
                        onClick={() => window.open(item.imageUrl, '_blank')}
                        className="w-full relative"
                      >
                        <img 
                          src={item.imageUrl} 
                          alt="תמונת בעיה" 
                          className="rounded-lg shadow-sm w-full h-48 object-cover"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-20 transition-all duration-200 flex items-center justify-center">
                          <span className="text-white opacity-0 hover:opacity-100">הצג תמונה מקורית</span>
                        </div>
                      </button>
                    </div>
                  )}

                  {/* Required Photo Indicator */}
                  {item.requiresPhoto && (
                    <div className="mb-3 flex items-center gap-2">
                      <span className="text-red-500 text-sm font-medium">* נדרש תיעוד בתמונה</span>
                    </div>
                  )}

                  {/* Status Update Section */}
                  <div className="mb-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">עדכן סטטוס:</h4>
                    <div className="grid grid-cols-3 gap-2">
                      {Object.entries(STATUS_LABELS).map(([status, label]) => (
                        <button
                          key={status}
                          onClick={() => setSelectedStatus(prev => ({ ...prev, [item._id]: status }))}
                          disabled={item.status === 'completed'}
                          className={`
                            py-2 px-1 rounded-lg text-sm transition-all
                            ${item.status === 'completed' ? 'opacity-50 cursor-not-allowed' : ''}
                            ${selectedStatus[item._id] === status 
                              ? `${STATUS_COLORS[status]} border-2 border-current font-medium` 
                              : 'bg-gray-50 hover:bg-gray-100'
                            }
                          `}
                        >
                          {label}
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Quick Update Form */}
                  <div className="space-y-3">
                    <div>
                      <label className="text-sm font-medium text-gray-700 block mb-1">הוסף הערה:</label>
                      <textarea
                        value={itemStates[item._id]?.newComment || ''}
                        onChange={(e) => setItemStates(prev => ({
                          ...prev,
                          [item._id]: {
                            ...prev[item._id],
                            newComment: e.target.value
                          }
                        }))}
                        disabled={item.status === 'completed'}
                        placeholder={item.status === 'completed' ? 'לא ניתן להוסיף הערות לפריט שהושלם' : 'כתוב כאן את ההערה שלך...'}
                        className={`w-full p-2 border rounded-lg text-sm ${item.status === 'completed' ? 'bg-gray-100' : ''}`}
                        rows="2"
                      />
                    </div>

                    {/* Image Upload Section */}
                    <div>
                      <label className="text-sm font-medium text-gray-700 block mb-1">
                        צרף תמונות:
                        <span className="text-xs text-gray-500 mr-2">
                          {itemStates[item._id]?.selectedImages?.length 
                            ? `(${itemStates[item._id].selectedImages.length}/5 תמונות)`
                            : '(ניתן להעלות עד 5 תמונות)'}
                        </span>
                      </label>
                      <div className="flex flex-wrap items-start gap-2">
                        {/* Gallery Upload Button */}
                        <label className={`flex-none ${item.status === 'completed' ? 'opacity-50 cursor-not-allowed' : ''}`}>
                          <div className="bg-gray-50 hover:bg-gray-100 rounded-lg p-3 text-center cursor-pointer">
                            <Upload className="h-5 w-5 mx-auto mb-1" />
                            <span className="text-sm">מגלריה</span>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            multiple
                            onChange={(e) => handleImageSelect(item._id, e)}
                            disabled={item.status === 'completed' || itemStates[item._id]?.selectedImages?.length >= 5}
                          />
                        </label>

                        {/* Camera Upload Button */}
                        <label className={`flex-none ${item.status === 'completed' ? 'opacity-50 cursor-not-allowed' : ''}`}>
                          <div className="bg-gray-50 hover:bg-gray-100 rounded-lg p-3 text-center cursor-pointer">
                            <Camera className="h-5 w-5 mx-auto mb-1" />
                            <span className="text-sm">מצלמה</span>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            capture="environment"
                            onChange={(e) => handleImageSelect(item._id, e)}
                            disabled={item.status === 'completed' || itemStates[item._id]?.selectedImages?.length >= 5}
                          />
                        </label>

                        {/* Image Previews */}
                        {itemStates[item._id]?.imagePreviews?.map((preview, index) => (
                          <div key={index} className="relative w-20 h-20">
                            <img
                              src={preview}
                              alt={`תצוגה מקדימה ${index + 1}`}
                              className="w-full h-full object-cover rounded-lg"
                            />
                            <button
                              onClick={() => {
                                const newImages = [...(itemStates[item._id].selectedImages || [])];
                                const newPreviews = [...(itemStates[item._id].imagePreviews || [])];
                                newImages.splice(index, 1);
                                newPreviews.splice(index, 1);
                                setItemStates(prev => ({
                                  ...prev,
                                  [item._id]: {
                                    ...prev[item._id],
                                    selectedImages: newImages.length ? newImages : null,
                                    imagePreviews: newPreviews.length ? newPreviews : null
                                  }
                                }));
                              }}
                              className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-1"
                            >
                              <X className="h-3 w-3" />
                            </button>
                          </div>
                        ))}
                      </div>
                      {itemStates[item._id]?.selectedImages?.length >= 5 && (
                        <p className="text-xs text-red-500 mt-1">
                          הגעת למקסימום התמונות המותר (5)
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Submit Button */}
                  <button
                    onClick={() => handleUpdateItem(item._id)}
                    disabled={uploadingImage || item.status === 'completed'}
                    className={`w-full mt-4 ${
                      uploadingImage || item.status === 'completed'
                        ? 'bg-gray-400 cursor-not-allowed' 
                        : 'bg-blue-600 active:bg-blue-700'
                    } text-white py-3 rounded-lg text-sm font-medium`}
                  >
                    {uploadingImage ? 'מעלה תמונה...' : 
                     item.status === 'completed' ? 'הפריט הושלם' : 'שלח עדכון'}
                  </button>
                </div>

                {/* Comments History Section */}
                {item.comments && item.comments.length > 0 && (
                  <div className="border-t">
                    <details className="p-4">
                      <summary className="text-sm font-medium text-gray-700 cursor-pointer">
                        היסטוריית עדכונים ({item.comments.length})
                      </summary>
                      <div className="mt-3 space-y-3">
                        {item.comments.map((comment, index) => (
                          <div key={index} className="bg-gray-50 rounded-lg p-3 text-sm">
                            {comment.text && <p className="mb-2">{comment.text}</p>}
                            {comment.imageUrls && comment.imageUrls.length > 0 && (
                              <div className="flex flex-wrap gap-2 mb-2">
                                {comment.imageUrls.map((imageUrl, imgIndex) => (
                                  <img 
                                    key={imgIndex}
                                    src={imageUrl} 
                                    alt={`תמונת עדכון ${imgIndex + 1}`} 
                                    className="rounded-lg w-32 h-32 object-cover cursor-pointer"
                                    onClick={() => window.open(imageUrl, '_blank')}
                                  />
                                ))}
                              </div>
                            )}
                            <p className="text-xs text-gray-500">
                              {new Date(comment.createdAt).toLocaleString('he-IL')}
                            </p>
                          </div>
                        ))}
                      </div>
                    </details>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectionForm; 