import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { toast } from 'react-hot-toast';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authSlice';

const ImagePreview = ({ imageUrls, alt, size = "medium" }) => {
  const sizeClasses = {
    small: "w-24 h-24",
    medium: "w-32 h-32",
    large: "w-40 h-40",
    full: "w-full h-48"
  };

  const images = typeof imageUrls === 'string' ? [imageUrls] : imageUrls;

  if (!images?.length) return null;

  const handleImageClick = (e, url) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  return (
    <div className="flex gap-2 flex-wrap" onClick={e => e.stopPropagation()}>
      {images.map((url, index) => (
        <div 
          key={index} 
          className="relative group cursor-pointer"
          onClick={(e) => handleImageClick(e, url)}
        >
          <img 
            src={url} 
            alt={`${alt} ${index + 1}`}
            className={`rounded-lg object-cover ${sizeClasses[size]}`}
            onError={(e) => {
              console.error('Failed to load image:', url);
              e.target.style.display = 'none';
            }}
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-200 rounded-lg">
            <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              לחץ להגדלה
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const BranchRejectionDashboard = () => {
  const [forms, setForms] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedForm, setExpandedForm] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    try {
      console.log('Current user branches:', user.branches);
      const response = await api.get('/api/rejection-forms/dashboard');
      console.log('Fetched branch forms:', response.data);
      
      // Filter forms based on user's branches
      if ((user.role === 'branch' || user.role === 'area_manager') && user.branches?.length > 0) {
        const userBranches = user.branches.map(branch => typeof branch === 'object' ? branch.toString() : branch);
        const filteredForms = response.data.forms.filter(form => {
          return userBranches.some(userBranch => {
            const formBranch = typeof form.branch === 'object' ? form.branch.toString() : form.branch;
            return formBranch === userBranch || form.branchName === userBranch;
          });
        });
        
        console.log('Forms after filtering:', filteredForms);
        setForms(filteredForms);
        
        // Recalculate stats based on filtered forms
        const newStats = {
          total: filteredForms.reduce((acc, form) => acc + form.items.length, 0),
          open: filteredForms.reduce((acc, form) => acc + form.items.filter(item => item.status === 'pending').length, 0),
          inProgress: filteredForms.reduce((acc, form) => acc + form.items.filter(item => item.status === 'in_progress').length, 0),
          completed: filteredForms.reduce((acc, form) => acc + form.items.filter(item => item.status === 'completed').length, 0)
        };
        
        setStats(newStats);
      } else {
        // For other roles (admin, manager), show all forms
        setForms(response.data.forms);
        const allForms = response.data.forms;
        const newStats = {
          total: allForms.reduce((acc, form) => acc + form.items.length, 0),
          open: allForms.reduce((acc, form) => acc + form.items.filter(item => item.status === 'pending').length, 0),
          inProgress: allForms.reduce((acc, form) => acc + form.items.filter(item => item.status === 'in_progress').length, 0),
          completed: allForms.reduce((acc, form) => acc + form.items.filter(item => item.status === 'completed').length, 0)
        };
        setStats(newStats);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching forms:', error);
      toast.error('שגיאה בטעינת הטפסים');
      setLoading(false);
    }
  };

  const toggleFormExpansion = (formId) => {
    setExpandedForm(expandedForm === formId ? null : formId);
  };

  const calculateProgress = (items) => {
    if (!items?.length) return 0;
    const completed = items.filter(item => item.status === 'completed').length;
    return Math.round((completed / items.length) * 100);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Stats Section */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
          <p className="text-sm text-gray-600 mb-1">סה"כ משימות</p>
          <p className="text-2xl font-bold text-blue-600">{stats.total || 0}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
          <p className="text-sm text-gray-600 mb-1">משימות פתוחות</p>
          <p className="text-2xl font-bold text-yellow-500">{stats.open || 0}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
          <p className="text-sm text-gray-600 mb-1">בטיפול</p>
          <p className="text-2xl font-bold text-blue-500">{stats.inProgress || 0}</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
          <p className="text-sm text-gray-600 mb-1">הושלמו</p>
          <p className="text-2xl font-bold text-green-500">{stats.completed || 0}</p>
        </div>
      </div>

      {/* Forms List */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  תאריך דוח
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  תאריך יעד
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  מפקח
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  התקדמות
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  פעולות
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {forms.map((form) => (
                <React.Fragment key={form._id}>
                  <tr 
                    className={`hover:bg-gray-50 cursor-pointer ${expandedForm === form._id ? 'bg-blue-50' : ''}`}
                    onClick={() => toggleFormExpansion(form._id)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {new Date(form.reportDate).toLocaleDateString('he-IL')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {new Date(form.dueDate).toLocaleDateString('he-IL')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {form.inspector}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                          <div 
                            className="h-full bg-blue-500 rounded-full"
                            style={{ width: `${calculateProgress(form.items)}%` }}
                          />
                        </div>
                        <span className="mr-2 text-sm text-gray-600">
                          {calculateProgress(form.items)}%
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(`/rejection-form/${form.accessToken}`, '_blank');
                        }}
                        className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 text-sm"
                      >
                        הגש טופס
                      </button>
                    </td>
                  </tr>
                  {expandedForm === form._id && (
                    <tr>
                      <td colSpan="5" className="px-6 py-4 bg-gray-50">
                        <div className="space-y-4">
                          {form.items.map((item, index) => (
                            <div key={index} className="border-b border-gray-200 pb-4 last:border-0 last:pb-0">
                              <div className="flex justify-between items-start mb-2">
                                <div className="flex-1">
                                  <h4 className="font-medium text-gray-900">פריט {index + 1}</h4>
                                  <p className="text-gray-700">{item.description}</p>
                                </div>
                                <span className={`px-2 py-1 rounded text-sm ${
                                  item.status === 'completed' ? 'bg-green-100 text-green-800' :
                                  item.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                                  'bg-yellow-100 text-yellow-800'
                                }`}>
                                  {item.status === 'completed' ? 'הושלם' :
                                   item.status === 'in_progress' ? 'בטיפול' :
                                   'ממתין'}
                                </span>
                              </div>
                              {item.imageUrl && (
                                <ImagePreview imageUrls={item.imageUrl} alt={`פריט ${index + 1}`} />
                              )}
                              {item.comments?.length > 0 && (
                                <div className="mt-2 space-y-2">
                                  <h5 className="font-medium text-gray-900">תגובות:</h5>
                                  {item.comments.map((comment, commentIndex) => (
                                    <div key={commentIndex} className="bg-white p-3 rounded-lg shadow-sm">
                                      <p className="text-gray-700">{comment.text}</p>
                                      {comment.imageUrl && (
                                        <ImagePreview imageUrls={comment.imageUrl} alt={`תגובה ${commentIndex + 1}`} size="small" />
                                      )}
                                      <p className="text-gray-500 text-sm mt-1">
                                        {new Date(comment.createdAt).toLocaleString('he-IL')}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BranchRejectionDashboard; 