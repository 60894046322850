import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import GiftCardForm from './GiftCardForm';
import GiftCardList from './GiftCardList';

const GiftCardsPage = () => {
  const user = useSelector(state => state.auth.user);
  const isAreaManager = user.role === 'area_manager';
  const listRef = useRef();

  const handleGiftCardSubmit = () => {
    if (listRef.current) {
      listRef.current.fetchGiftCards();
    }
  };

  return (
    <div className="space-y-6">
      {isAreaManager && <GiftCardForm onSubmit={handleGiftCardSubmit} />}
      <GiftCardList ref={listRef} />
    </div>
  );
};

export default GiftCardsPage;
