import React from 'react';
import { motion } from 'framer-motion';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const IntroSection = () => {
  const branchData = {
    'להב קרית ים': 1069,
    'כרמיאל': 1224,
    'היפר דודו קרית ים': 959,
    'היפר דודו פתח תקווה (סלור)': 2761,
    'מחסני מזון סיטי אשדוד': 3645,
    'סופר דוש קניון': 5264,
    'מחסני להב נתניה קניון': 1739,
    'מחסני מזון נצרת עלית': 1875,
    'מחסני להב וולפסון': 863,
    'סופר דוש חולון': 1393,
    'מחסני מזון ק.שמואל': 923
  };

  const totalRecruitments = Object.values(branchData).reduce((a, b) => a + b, 0);
  const maxBranch = Object.entries(branchData).reduce((a, b) => a[1] > b[1] ? a : b);

  const chartData = {
    labels: Object.keys(branchData),
    datasets: [
      {
        label: 'גיוסי אפליקציה שנתיים',
        data: Object.values(branchData),
        backgroundColor: 'rgba(96, 165, 250, 0.8)',
        borderColor: 'rgba(96, 165, 250, 1)',
        borderWidth: 1,
        borderRadius: 8,
        hoverBackgroundColor: 'rgba(96, 165, 250, 1)',
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: 12,
        titleFont: {
          size: 14,
          family: 'Assistant'
        },
        bodyFont: {
          size: 14,
          family: 'Assistant'
        },
        rtl: true,
        titleAlign: 'right',
        bodyAlign: 'right',
        callbacks: {
          label: function(context) {
            return `${context.parsed.y.toLocaleString()} גיוסי אפליקציה`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: 'Assistant',
            size: 14
          },
          color: '#E5E7EB',
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          font: {
            family: 'Assistant',
            size: 14
          },
          color: '#E5E7EB',
          callback: function(value) {
            if (value >= 1000) {
              return (value / 1000).toFixed(1) + 'K';
            }
            return value.toLocaleString();
          }
        }
      }
    }
  };

  return (
    <motion.div
      className="intro-section"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h2
        className="intro-title"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        סיכום גיוסים אפליקציית יילו 2024 🌟
      </motion.h2>

      <motion.div
        className="intro-content"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <p className="intro-text">
          חברים יקרים, הגיע הזמן לסכם שנה מדהימה! 
          <br />
          שנת 2024 הייתה שנה מלאה באתגרים, הישגים והצלחות משותפות.
          <br />
          כל אחד ואחת מכם תרם לתוצאות המרשימות שהשגנו יחד.
        </p>
      </motion.div>

      <motion.div
        className="intro-highlights"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        <div className="highlight-item">
          <span className="highlight-label">סניף מוביל</span>
          <span className="highlight-value">{maxBranch[0]}</span>
          <span className="highlight-subvalue">{maxBranch[1].toLocaleString()} גיוסי אפליקציה</span>
        </div>
        <div className="highlight-item">
          <span className="highlight-label">סה״כ גיוסי אפליקציה שנתי ברשת</span>
          <span className="highlight-value">39,423</span>
          <span className="highlight-subvalue">גיוסי אפליקציה בסה״כ</span>
        </div>
      </motion.div>

      <motion.div
        className="graph-container"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6 }}
      >
        <Bar data={chartData} options={options} height={400} />
      </motion.div>

      <motion.p
        className="intro-message"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.8 }}
      >
        היו עליות, היו ירידות, אבל בסוף - שרדנו ואפילו פרחנו! 🌸
        <br />
        בואו נסתכל על ההישגים המרשימים שלנו השנה
      </motion.p>
    </motion.div>
  );
};

export default IntroSection; 