import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { AlertTriangle, HelpCircle, Share2, FileDown, Settings, X } from 'lucide-react';

// Default constants for repair calculations
const DEFAULT_REPAIR_CONSTANTS = {
  DOLLAR_PRICE_PER_KG: 3000,
  EXCHANGE_RATE: 3.5,
  WASTE_PERCENTAGE: 0.30,
  PROFIT_MARGIN: 100
};

// Fixed costs
const FIXED_COSTS = {
  SKIN_TOP_COST: 1500,
  NET_COST: 200,
  COLOR_COST: 300,
  ADDITIONAL_COST: 300
};

// Add tooltip component
const Tooltip = ({ text, children }) => {
  return (
    <div className="group relative inline-block">
      {children}
      <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 absolute z-50 bottom-full mb-2 p-2 w-48 bg-gray-900 text-white text-sm rounded-lg shadow-lg">
        {text}
        <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-gray-900 rotate-45"></div>
      </div>
    </div>
  );
};

// Add field explanations
const fieldExplanations = {
  requiredGrams: "כמות השיער בגרמים הנדרשת לתיקון",
  hairType: "סוג השיער לתיקון",
  structure: "סוג המבנה של הפאה",
  needsColor: "האם נדרש צביעה",
  needsNet: "האם נדרשת החלפת רשת",
  profitMargin: "אחוז הרווח הרצוי",
};

// Update the label component to include tooltip
const LabelWithTooltip = ({ htmlFor, tooltip, children }) => (
  <div className="flex items-center gap-1">
    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={htmlFor}>{children}</label>
    {tooltip && (
      <Tooltip text={tooltip}>
        <HelpCircle className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-help" />
      </Tooltip>
    )}
  </div>
);

// Update the input styles to include text-right
const inputClassName = "w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right";
const selectClassName = "w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right";
const textareaClassName = "w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right";

// Update the input field with unit style
const InputWithUnit = ({ name, value, onChange, unit, min, max, placeholder }) => (
  <div className="relative flex rounded-lg border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-transparent">
    <input
      type="number"
      name={name}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      placeholder={placeholder}
      className="w-full px-4 py-3 rounded-lg border-0 focus:ring-0"
    />
    <div className="flex items-center px-4 bg-gray-50 border-r border-gray-300 rounded-l-lg">
      <span className="text-gray-500 whitespace-nowrap">{unit}</span>
    </div>
  </div>
);

const WigRepairCalculator = () => {
  // Load data from localStorage
  const loadFromLocalStorage = () => {
    try {
      const savedFormData = localStorage.getItem('wigRepairCalculatorForm');
      const savedCostConstants = localStorage.getItem('wigRepairCalculatorCosts');
      const savedCalculatedData = localStorage.getItem('wigRepairCalculatorResults');
      
      return {
        formData: savedFormData ? JSON.parse(savedFormData) : null,
        costConstants: savedCostConstants ? JSON.parse(savedCostConstants) : null,
        calculatedData: savedCalculatedData ? JSON.parse(savedCalculatedData) : null
      };
    } catch (error) {
      console.error('Error loading from localStorage:', error);
      return {};
    }
  };

  const savedData = loadFromLocalStorage();

  const [formData, setFormData] = useState(savedData.formData || {
    requiredGrams: 100,
    needsSkinTop: false,
    skinTopCustomPrice: FIXED_COSTS.SKIN_TOP_COST,
    needsColor: false,
    colorCustomPrice: FIXED_COSTS.COLOR_COST,
    needsNet: false,
    netCustomPrice: FIXED_COSTS.NET_COST,
    needsAdditional: false,
    additionalCustomPrice: 0,
    clientName: '',
    clientPhone: '',
    customizations: '',
    paymentTerms: '50% מקדמה, 50% במסירה',
  });

  const [costConstants, setCostConstants] = useState(savedData.costConstants || DEFAULT_REPAIR_CONSTANTS);
  const [calculatedData, setCalculatedData] = useState(savedData.calculatedData || null);
  const [showCostSettings, setShowCostSettings] = useState(false);

  // Save to localStorage on changes
  useEffect(() => {
    localStorage.setItem('wigRepairCalculatorForm', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    localStorage.setItem('wigRepairCalculatorCosts', JSON.stringify(costConstants));
  }, [costConstants]);

  useEffect(() => {
    if (calculatedData) {
      localStorage.setItem('wigRepairCalculatorResults', JSON.stringify(calculatedData));
    }
  }, [calculatedData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCostChange = (e) => {
    const { name, value } = e.target;
    setCostConstants(prev => ({
      ...prev,
      [name]: Number(value)
    }));
  };

  // Remove the calculatePrice function and add useEffect for dynamic calculation
  useEffect(() => {
    // Skip calculation if required fields are missing
    if (!formData.requiredGrams) {
      return;
    }

    // Calculate raw material cost with waste
    const totalGrams = Number(formData.requiredGrams) * (1 + costConstants.WASTE_PERCENTAGE);
    const rawMaterialCostUSD = (totalGrams / 1000) * costConstants.DOLLAR_PRICE_PER_KG;
    const rawMaterialCostNIS = rawMaterialCostUSD * costConstants.EXCHANGE_RATE;

    // Calculate additional costs
    let additionalCosts = 0;
    
    if (formData.needsSkinTop) {
      additionalCosts += Number(formData.skinTopCustomPrice);
    }

    if (formData.needsNet) {
      additionalCosts += Number(formData.netCustomPrice);
    }

    if (formData.needsColor) {
      additionalCosts += Number(formData.colorCustomPrice);
    }

    if (formData.needsAdditional) {
      additionalCosts += Number(formData.additionalCustomPrice);
    }

    // Calculate total cost and final price
    const totalCost = rawMaterialCostNIS + additionalCosts;
    const profitMarginMultiplier = costConstants.PROFIT_MARGIN / 100;
    const finalPrice = totalCost * (1 + profitMarginMultiplier);

    setCalculatedData({
      rawMaterialGrams: Number(formData.requiredGrams),
      wasteGrams: Number(formData.requiredGrams) * costConstants.WASTE_PERCENTAGE,
      totalGrams,
      rawMaterialCostUSD: rawMaterialCostUSD.toFixed(2),
      rawMaterialCostNIS: rawMaterialCostNIS.toFixed(2),
      additionalCosts,
      totalCost: totalCost.toFixed(2),
      finalPrice: finalPrice.toFixed(2),
      profitAmount: (finalPrice - totalCost).toFixed(2),
      timestamp: new Date().toLocaleString('he-IL'),
      breakdown: {
        rawMaterial: rawMaterialCostNIS.toFixed(2),
        skinTopCost: formData.needsSkinTop ? FIXED_COSTS.SKIN_TOP_COST : 0,
        netCost: formData.needsNet ? FIXED_COSTS.NET_COST : 0,
        colorCost: formData.needsColor ? FIXED_COSTS.COLOR_COST : 0,
        additional: FIXED_COSTS.ADDITIONAL_COST
      }
    });
  }, [
    formData.requiredGrams,
    formData.needsSkinTop,
    formData.skinTopCustomPrice,
    formData.needsNet,
    formData.netCustomPrice,
    formData.needsColor,
    formData.colorCustomPrice,
    formData.needsAdditional,
    formData.additionalCustomPrice,
    costConstants.WASTE_PERCENTAGE,
    costConstants.DOLLAR_PRICE_PER_KG,
    costConstants.EXCHANGE_RATE,
    costConstants.PROFIT_MARGIN
  ]);

  const generatePDF = async () => {
    try {
      const quoteElement = document.getElementById('repair-quote-content');
      
      if (!quoteElement) {
        console.error('Quote element not found');
        return;
      }

      // Set white background and fixed width for consistent rendering
      const originalBackground = quoteElement.style.background;
      const originalWidth = quoteElement.style.width;
      quoteElement.style.background = 'white';
      quoteElement.style.width = '800px'; // Fixed width for consistent rendering

      const canvas = await html2canvas(quoteElement, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: '#ffffff',
        windowWidth: 800, // Match the fixed width
      });

      // Restore original styles
      quoteElement.style.background = originalBackground;
      quoteElement.style.width = originalWidth;
    
      const imgData = canvas.toDataURL('image/png');
      
      // A4 dimensions in mm (210 x 297)
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      // Calculate dimensions while maintaining aspect ratio
      const imgRatio = canvas.height / canvas.width;
      let finalWidth = pdfWidth - 20; // 10mm margins on each side
      let finalHeight = finalWidth * imgRatio;

      // If height exceeds page height, scale down proportionally
      if (finalHeight > pdfHeight - 20) {
        finalHeight = pdfHeight - 20;
        finalWidth = finalHeight / imgRatio;
      }
      
      // Center image horizontally
      const xPosition = (pdfWidth - finalWidth) / 2;
      
      pdf.addImage(imgData, 'PNG', xPosition, 10, finalWidth, finalHeight);
      pdf.save(`הצעת-מחיר-תיקון-פאה-${formData.clientName || 'לקוח'}-${new Date().toLocaleDateString('he-IL').replace(/\//g, '-')}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const shareViaWhatsApp = () => {
    let phoneNumber = formData.clientPhone.replace(/\D/g, '');
    if (phoneNumber.startsWith('0')) {
      phoneNumber = '972' + phoneNumber.substring(1);
    }

    const message = 
      `👋 *הצעת מחיר תיקון פאה - מיכל דיין* 👱‍♀️\n\n` +
      `👤 *פרטי לקוח:*\n` +
      `שם: ${formData.clientName}\n` +
      `📅 תאריך: ${new Date().toLocaleDateString('he-IL')}\n\n` +
      `💇‍♀️ *פרטי התיקון:*\n` +
      `• כמות שיער: ${formData.requiredGrams} גרם\n` +
      `• סקין/טופ: ${formData.needsSkinTop ? 'כן' : 'לא'}\n` +
      `• צביעה: ${formData.needsColor ? 'כן' : 'לא'}\n` +
      `• החלפת רשת: ${formData.needsNet ? 'כן' : 'לא'}\n\n` +
      `💰 *מחיר סופי: ₪${calculatedData?.finalPrice}*\n\n` +
      (formData.customizations ? `📝 *הערות:*\n${formData.customizations}\n\n` : '') +
      `📞 *ליצירת קשר:*\n` +
      `054-2890590\n\n` +
      `✨ בברכה,\n` +
      `מיכל דיין - עיצוב והתאמת פאות`;
    
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="min-h-screen bg-gray-50 p-2 sm:p-4 md:p-6 overflow-x-hidden w-full" dir="rtl">
      <div className="max-w-[1600px] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        {/* Left Column - Input Form */}
        <div className="space-y-6 w-full">
          <Card className="bg-white shadow-sm w-full">
            <CardHeader className="border-b bg-white">
              <div className="flex flex-row-reverse justify-between items-center flex-wrap">
                <button
                  onClick={() => setShowCostSettings(!showCostSettings)}
                  className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded-lg transition-colors text-gray-600"
                >
                  <Settings className="w-5 h-5" />
                  <span className="text-sm">הגדרות מחיר</span>
                </button>
                <div className="text-right">
                  <CardTitle className="text-2xl font-bold text-gray-900">מחשבון תיקוני פאה</CardTitle>
                  <p className="text-gray-500 mt-1">מיכל דיין - עיצוב והתאמת פאות</p>
                </div>
              </div>
              
              <div className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-100">
                <div className="flex items-start gap-3">
                  <AlertTriangle className="w-5 h-5 text-blue-600 mt-0.5" />
                  <div>
                    <h4 className="font-medium text-blue-900 mb-1">הוראות שימוש</h4>
                    <ul className="text-sm text-blue-800 space-y-1">
                      <li>• הזינו כמות שיער ובחרו תיקונים</li>
                      <li>• מלאו פרטי לקוח</li>
                      <li>• המחיר מתעדכן אוטומטית</li>
                    </ul>
                  </div>
                </div>
              </div>
            </CardHeader>
            
            <CardContent className="p-6">
              {/* Settings Modal */}
              {showCostSettings && (
                <div 
                  className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 transition-opacity duration-300"
                  onClick={(e) => {
                    if (e.target === e.currentTarget) {
                      setShowCostSettings(false);
                    }
                  }}
                >
                  <div className="fixed inset-x-0 bottom-0 sm:relative sm:inset-auto">
                    <div className="bg-white w-full sm:rounded-xl sm:max-w-2xl sm:w-full sm:mx-auto sm:mt-10 max-h-[85vh] overflow-y-auto relative rounded-t-2xl shadow-2xl">
                      {/* Settings Header */}
                      <div className="sticky top-0 bg-white border-b z-10">
                        <div className="h-2 flex justify-center items-center sm:hidden">
                          <div className="w-12 h-1.5 bg-gray-300 rounded-full mt-3 mb-1"></div>
                        </div>
                        <div className="px-6 py-4 flex justify-between items-center">
                          <div className="text-right">
                            <h3 className="text-xl font-bold text-gray-900">הגדרות מחיר</h3>
                            <p className="text-sm text-gray-500 mt-1">עדכון מחירי חומרי גלם ועלויות</p>
                          </div>
                          <button
                            onClick={() => setShowCostSettings(false)}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                          >
                            <X className="w-5 h-5 text-gray-500" />
                          </button>
                        </div>
                      </div>
                      
                      {/* Settings Content */}
                      <div className="px-6 py-6 space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                          {/* Cost Settings Fields */}
                          {[
                            'DOLLAR_PRICE_PER_KG',
                            'EXCHANGE_RATE',
                            'WASTE_PERCENTAGE',
                            'PROFIT_MARGIN'
                          ].map((key) => (
                            <div key={key} className="space-y-2">
                              <label className="block text-sm font-medium text-gray-700 text-right">
                                {key === 'DOLLAR_PRICE_PER_KG' ? 'מחיר דולר לק"ג' :
                                 key === 'EXCHANGE_RATE' ? 'שער דולר' :
                                 key === 'WASTE_PERCENTAGE' ? 'אחוז פחת' :
                                 'אחוז רווח'}
                              </label>
                              <InputWithUnit
                                name={key}
                                value={costConstants[key]}
                                onChange={handleCostChange}
                                unit={key === 'DOLLAR_PRICE_PER_KG' ? '$' :
                                      key === 'EXCHANGE_RATE' ? '₪' :
                                      '%'}
                                step={key === 'EXCHANGE_RATE' || key === 'WASTE_PERCENTAGE' ? "0.01" : "1"}
                                min="0"
                                max={key === 'PROFIT_MARGIN' ? "500" : undefined}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      
                      {/* Add Footer with Save Button */}
                      <div className="sticky bottom-0 bg-white border-t px-6 py-4 sm:px-6 sm:py-6">
                        <button
                          onClick={() => setShowCostSettings(false)}
                          className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 
                            transition-all duration-200 text-base font-medium shadow-lg 
                            hover:shadow-blue-500/25 active:transform active:scale-[0.98]"
                        >
                          שמור הגדרות
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Main Form */}
              <div className="space-y-8">
                {/* Basic Information */}
                <section className="space-y-6">
                  <div className="flex items-center gap-4 bg-gradient-to-r from-blue-50 to-transparent p-4 rounded-lg border border-blue-100">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-500 text-white font-semibold">1</div>
                    <div className="text-right">
                      <h3 className="text-lg font-semibold text-gray-900">פרטי התיקון</h3>
                      <p className="text-sm text-gray-500">הזן את פרטי התיקון הנדרש</p>
                    </div>
                  </div>

                  {/* Form Fields */}
                  <div className="grid grid-cols-1 gap-6">
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-gray-700 text-right">
                        כמות שיער נדרשת
                      </label>
                      <InputWithUnit
                        name="requiredGrams"
                        value={formData.requiredGrams}
                        onChange={handleChange}
                        unit="גרם"
                        min="1"
                        placeholder="הכנס כמות בגרמים"
                      />
                    </div>

                    <div className="space-y-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="needsSkinTop"
                          checked={formData.needsSkinTop}
                          onChange={handleChange}
                          className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                        />
                        <span className="text-gray-700">נדרש סקין/טופ</span>
                      </label>
                      {formData.needsSkinTop && (
                        <div className="relative">
                          <input
                            type="number"
                            name="skinTopCustomPrice"
                            value={formData.skinTopCustomPrice}
                            onChange={handleChange}
                            className="w-full px-4 py-3 pr-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right"
                            dir="rtl"
                          />
                          <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">₪</span>
                        </div>
                      )}
                    </div>

                    <div className="space-y-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="needsColor"
                          checked={formData.needsColor}
                          onChange={handleChange}
                          className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                        />
                        <span className="text-gray-700">נדרשת צביעה</span>
                      </label>
                      {formData.needsColor && (
                        <div className="relative">
                          <input
                            type="number"
                            name="colorCustomPrice"
                            value={formData.colorCustomPrice}
                            onChange={handleChange}
                            className="w-full px-4 py-3 pr-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right"
                            dir="rtl"
                          />
                          <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">₪</span>
                        </div>
                      )}
                    </div>

                    <div className="space-y-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="needsNet"
                          checked={formData.needsNet}
                          onChange={handleChange}
                          className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                        />
                        <span className="text-gray-700">נדרשת החלפת רשת</span>
                      </label>
                      {formData.needsNet && (
                        <div className="relative">
                          <input
                            type="number"
                            name="netCustomPrice"
                            value={formData.netCustomPrice}
                            onChange={handleChange}
                            className="w-full px-4 py-3 pr-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right"
                            dir="rtl"
                          />
                          <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">₪</span>
                        </div>
                      )}
                    </div>

                    <div className="space-y-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="needsAdditional"
                          checked={formData.needsAdditional}
                          onChange={handleChange}
                          className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                        />
                        <span className="text-gray-700">עלויות נוספות</span>
                      </label>
                      {formData.needsAdditional && (
                        <div className="relative">
                          <input
                            type="number"
                            name="additionalCustomPrice"
                            value={formData.additionalCustomPrice}
                            onChange={handleChange}
                            className="w-full px-4 py-3 pr-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right"
                            dir="rtl"
                          />
                          <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">₪</span>
                        </div>
                      )}
                    </div>
                  </div>
                </section>

                {/* Customer Details */}
                <section className="space-y-6">
                  <div className="flex items-center gap-4 bg-gradient-to-r from-green-50 to-transparent p-4 rounded-lg border border-green-100">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-green-500 text-white font-semibold">2</div>
                    <div className="text-right">
                      <h3 className="text-lg font-semibold text-gray-900">פרטי לקוח</h3>
                      <p className="text-sm text-gray-500">הזן את פרטי הלקוח והערות נוספות</p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-gray-700 text-right">
                        שם הלקוח/ה
                      </label>
                      <input
                        type="text"
                        name="clientName"
                        value={formData.clientName}
                        onChange={handleChange}
                        className={inputClassName}
                        placeholder="הכנס/י שם"
                      />
                    </div>
                    
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-gray-700 text-right">
                        טלפון
                      </label>
                      <input
                        type="tel"
                        name="clientPhone"
                        value={formData.clientPhone}
                        onChange={handleChange}
                        placeholder="050-0000000"
                        className={inputClassName}
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <label className="block text-sm font-medium text-gray-700 text-right">
                      הערות נוספות
                    </label>
                    <textarea
                      name="customizations"
                      value={formData.customizations}
                      onChange={handleChange}
                      rows="4"
                      className={textareaClassName}
                      placeholder="פרטים נוספים..."
                    />
                  </div>
                </section>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Column - Live Preview */}
        <div className="space-y-6 lg:sticky lg:top-6 self-start">
          {calculatedData && (
            <Card className="bg-white shadow-sm">
              <CardHeader className="border-b bg-white">
                <CardTitle className="text-2xl font-bold text-gray-900">הצעת מחיר</CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div id="repair-quote-content" className="space-y-8 bg-white p-8 rounded-xl text-right" dir="rtl">
                  {/* Header with Logo and Title */}
                  <div className="text-center border-b pb-8">
                    <div className="mb-4">
                      <h1 className="text-4xl font-bold text-gray-900 mb-2">מיכל דיין</h1>
                      <p className="text-xl text-blue-600">עיצוב והתאמת פאות</p>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-2xl font-semibold text-gray-800">הצעת מחיר - תיקון פאה</h2>
                      <div className="flex items-center justify-center gap-2 mt-2 text-gray-500">
                        <span>מספר: #{new Date().getTime().toString().slice(-6)}</span>
                        <span className="text-gray-300">|</span>
                        <span>תאריך: {new Date().toLocaleDateString('he-IL')}</span>
                      </div>
                    </div>
                  </div>

                  {/* Main Content */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Customer Details */}
                    <div className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-xl border border-blue-100">
                      <div className="flex items-center gap-2 mb-4">
                        <div className="w-1 h-6 bg-blue-500 rounded-full"></div>
                        <h3 className="text-xl font-bold text-gray-800">פרטי לקוח</h3>
                      </div>
                      <div className="space-y-3">
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-700 min-w-[80px]">שם:</span>
                          <span className="text-gray-600">{formData.clientName || '-'}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-700 min-w-[80px]">טלפון:</span>
                          <span className="text-gray-600">{formData.clientPhone || '-'}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-700 min-w-[80px]">תשלום:</span>
                          <span className="text-gray-600">{formData.paymentTerms}</span>
                        </div>
                      </div>
                    </div>

                    {/* Repair Details */}
                    <div className="bg-gradient-to-br from-purple-50 to-white p-6 rounded-xl border border-purple-100">
                      <div className="flex items-center gap-2 mb-4">
                        <div className="w-1 h-6 bg-purple-500 rounded-full"></div>
                        <h3 className="text-xl font-bold text-gray-800">פרטי התיקון</h3>
                      </div>
                      <div className="space-y-3">
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-700 min-w-[80px]">כמות שיער:</span>
                          <span className="text-gray-600">{formData.requiredGrams} גרם</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-700 min-w-[80px]">סקין/טופ:</span>
                          <span className="text-gray-600">{formData.needsSkinTop ? 'כן' : 'לא'}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-700 min-w-[80px]">צביעה:</span>
                          <span className="text-gray-600">{formData.needsColor ? 'כן' : 'לא'}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-700 min-w-[80px]">החלפת רשת:</span>
                          <span className="text-gray-600">{formData.needsNet ? 'כן' : 'לא'}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Price Summary */}
                  <div className="mt-8 w-full">
                    <div className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800 p-8 rounded-xl text-white shadow-lg">
                      <div className="flex flex-wrap justify-between items-center">
                        <div className="mb-2 sm:mb-0">
                          <h3 className="text-2xl font-bold">מחיר סופי</h3>
                          <p className="text-blue-100 mt-1">כולל חומרים ועבודה</p>
                        </div>
                        <div className="text-right">
                          <div className="text-3xl sm:text-5xl font-bold">₪{calculatedData.finalPrice}</div>
                          <p className="text-sm text-blue-100 mt-1">מחיר סופי</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Notes Section */}
                  {formData.customizations && (
                    <div className="mt-8">
                      <div className="bg-gradient-to-br from-yellow-50 to-white p-6 rounded-xl border border-yellow-100">
                        <div className="flex items-center gap-2 mb-4">
                          <div className="w-1 h-6 bg-yellow-500 rounded-full"></div>
                          <h3 className="text-xl font-bold text-gray-800 text-right flex-grow">הערות</h3>
                        </div>
                        <div className="text-gray-600 whitespace-pre-wrap text-right">
                          {formData.customizations}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Footer */}
                  <div className="border-t pt-8 mt-8">
                    <div className="text-center space-y-2">
                      <p className="font-semibold text-gray-800">מיכל דיין - עיצוב והתאמת פאות</p>
                      <div className="flex justify-center items-center gap-4 text-gray-600">
                        <span>054-2890590</span>
                        <span className="text-gray-300">|</span>
                        <span>קרית-ים</span>
                      </div>
                      <div className="text-sm text-gray-500 mt-4 text-right">
                        <p className="text-center">* תוקף ההצעה: 14 ימים מתאריך ההצעה</p>
                        <p className="text-center">* ניתן לשלם במזומן או בהעברה בנקאית</p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>

              {/* Action Buttons */}
              <div className="p-6 border-t">
                <div className="flex gap-4">
                  <button
                    onClick={shareViaWhatsApp}
                    className="flex-1 flex items-center justify-center gap-2 bg-green-500 text-white py-4 px-6 rounded-lg hover:bg-green-600 transition-colors text-lg font-medium shadow-lg"
                  >
                    <Share2 className="w-6 h-6" />
                    <span>שתף בוואצאפ</span>
                  </button>
                  <button
                    onClick={generatePDF}
                    className="flex-1 flex items-center justify-center gap-2 bg-blue-500 text-white py-4 px-6 rounded-lg hover:bg-blue-600 transition-colors text-lg font-medium shadow-lg"
                  >
                    <FileDown className="w-6 h-6" />
                    <span>הורד PDF</span>
                  </button>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default WigRepairCalculator;