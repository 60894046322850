import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { API_URL } from '../../config';
import CountdownTimer from './CountdownTimer';

const PublicInventoryForm = () => {
  const { token } = useParams();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState({});
  const [branchingAnswers, setBranchingAnswers] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const fetchForm = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/api/inventory/access/${token}`);
      if (!response.ok) {
        throw new Error('הטופס לא נמצא');
      }
      const data = await response.json();
      
      // Check if form is expired
      const expirationDate = new Date(data.expiresAt);
      if (expirationDate < new Date()) {
        setIsExpired(true);
        setError('הטופס פג תוקף');
        return;
      }

      // Check if form has already been submitted
      if (data.responses && data.responses.length > 0) {
        setSubmitted(true);
        setError('הטופס כבר הוגש');
        return;
      }

      setForm(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [token]);

  React.useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  // Add expiration check on timer end
  const handleTimerEnd = () => {
    setIsExpired(true);
    setError('הטופס פג תוקף');
  };

  const handleAnswerChange = (questionId, value, question) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: value
    }));

    // Reset branching answer when main answer changes
    if (question.questionType === 'multipleChoice') {
      setBranchingAnswers(prev => ({
        ...prev,
        [questionId]: ''
      }));
    }
  };

  const handleBranchingAnswerChange = (questionId, value) => {
    setBranchingAnswers(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  const handleSubmit = async () => {
    if (submitting || isExpired) return;

    // Check expiration again before submitting
    if (new Date(form.expiresAt) < new Date()) {
      setIsExpired(true);
      setError('הטופס פג תוקף');
      return;
    }

    // Validate required questions
    const requiredQuestions = form.questions.filter(q => q.required);
    const unansweredQuestions = requiredQuestions.filter(q => !answers[q._id]);

    if (unansweredQuestions.length > 0) {
      toast.error(`נא לענות על כל השאלות המסומנות ב-*`);
      return;
    }

    setSubmitting(true);

    try {
      // Prepare answers array with branching answers
      const answersArray = Object.entries(answers).map(([questionId, answer]) => {
        const branchingAnswer = branchingAnswers[questionId];
        return {
          questionId,
          answer: branchingAnswer ? `${answer} - ${branchingAnswer}` : answer
        };
      });

      const response = await fetch(`${API_URL}/api/inventory/access/${token}/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers: answersArray
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'שגיאה בשליחת הטופס');
      }

      setSubmitted(true);
      toast.success('הטופס נשלח בהצלחה!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
    </div>
  );

  if (error || isExpired) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-center p-8">
        <div className="text-3xl mb-4">⚠️</div>
        <h2 className="text-2xl font-bold mb-2 text-red-600">
          {isExpired ? 'הטופס פג תוקף' : error}
        </h2>
        <p className="text-gray-600">
          {isExpired ? 'לא ניתן להגיש את הטופס לאחר שפג תוקפו' : 'אנא נסה שוב מאוחר יותר'}
        </p>
      </div>
    </div>
  );

  if (!form) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-center p-8">הטופס לא נמצא</div>
    </div>
  );

  if (submitted) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-center p-8">
        <div className="text-3xl mb-4">✅</div>
        <h2 className="text-2xl font-bold mb-2">תודה!</h2>
        <p className="text-gray-600">
          {error === 'הטופס כבר הוגש' ? 'הטופס כבר הוגש ולא ניתן להגיש אותו שוב' : 'הטופס נשלח בהצלחה'}
        </p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50" dir="rtl">
      <div className="max-w-4xl mx-auto p-2 sm:p-6">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-4">
            <div className="flex flex-col sm:flex-row gap-4 items-center justify-between mb-4">
              <div>
                <h1 className="text-lg sm:text-2xl font-bold text-center sm:text-right">{form.title}</h1>
                <p className="text-sm text-blue-100 text-center sm:text-right">סניף: {form.branchName}</p>
              </div>
              
              {/* Countdown Timer */}
              <CountdownTimer expiresAt={form.expiresAt} onTimerEnd={handleTimerEnd} />
            </div>
          </div>
        </div>

        {/* User Guide */}
        <div className="bg-blue-50 border border-blue-200 rounded-xl p-4 mt-4 mb-2">
          <h2 className="font-medium text-blue-800 mb-2 text-lg">איך ממלאים את הטופס?</h2>
          <div className="space-y-2 text-sm text-blue-700">
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">1</span>
              <span>ענה על כל השאלות הנדרשות (מסומנות ב-*)</span>
            </p>
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">2</span>
              <span>לחץ על 'שלח טופס' בסיום</span>
            </p>
          </div>
        </div>

        {/* Questions */}
        <div className="bg-white rounded-xl shadow-lg mt-4">
          <div className="p-3 sm:p-6 space-y-6">
            {form.questions.map((question, index) => (
              <div key={question._id} className="border border-gray-200 rounded-xl overflow-hidden">
                <div className="p-4 bg-gray-50">
                  <div className="flex items-start gap-2">
                    <span className="bg-blue-100 text-blue-800 rounded-full w-6 h-6 flex items-center justify-center text-sm shrink-0">
                      {index + 1}
                    </span>
                    <div className="flex-1">
                      <h3 className="text-base font-medium text-gray-800 whitespace-pre-line leading-5">
                        {question.questionText}
                        {question.required && <span className="text-red-500 mr-1">*</span>}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="p-4">
                  {/* Answer Input */}
                  <div className="mb-4">
                    {question.questionType === 'text' && (
                      <textarea
                        value={answers[question._id] || ''}
                        onChange={(e) => handleAnswerChange(question._id, e.target.value, question)}
                        className="w-full p-2 border rounded-lg"
                        rows="2"
                        placeholder="הקלד את תשובתך כאן..."
                      />
                    )}
                    {question.questionType === 'number' && (
                      <input
                        type="number"
                        value={answers[question._id] || ''}
                        onChange={(e) => handleAnswerChange(question._id, e.target.value, question)}
                        className="w-full p-2 border rounded-lg"
                        placeholder="הזן מספר..."
                      />
                    )}
                    {question.questionType === 'boolean' && (
                      <div className="flex gap-4">
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            checked={answers[question._id] === true}
                            onChange={() => handleAnswerChange(question._id, true, question)}
                            className="w-4 h-4"
                          />
                          <span>כן</span>
                        </label>
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            checked={answers[question._id] === false}
                            onChange={() => handleAnswerChange(question._id, false, question)}
                            className="w-4 h-4"
                          />
                          <span>לא</span>
                        </label>
                      </div>
                    )}
                    {question.questionType === 'multipleChoice' && (
                      <div className="space-y-4">
                        <div className="space-y-2">
                          {question.options.map((option, optionIndex) => (
                            <div key={optionIndex}>
                              <label className="flex items-center gap-2">
                                <input
                                  type="radio"
                                  checked={answers[question._id] === option}
                                  onChange={() => handleAnswerChange(question._id, option, question)}
                                  className="w-4 h-4"
                                />
                                <span>{option}</span>
                              </label>
                              {/* Show branching question if this option is selected and has branching logic */}
                              {answers[question._id] === option && 
                               question.branchingLogic?.[optionIndex]?.nextQuestionText && (
                                <div className="mt-3 mr-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                                  <label className="block text-sm font-medium text-gray-700 mb-2">
                                    {question.branchingLogic[optionIndex].nextQuestionText}
                                  </label>
                                  <textarea
                                    value={branchingAnswers[question._id] || ''}
                                    onChange={(e) => handleBranchingAnswerChange(question._id, e.target.value)}
                                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    rows="2"
                                    placeholder="הקלד את תשובתך כאן..."
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={submitting || submitted}
            className={`w-full ${
              submitting || submitted
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-blue-600 hover:bg-blue-700'
            } text-white py-4 rounded-xl text-lg font-medium transition-colors`}
          >
            {submitting ? 'שולח...' : submitted ? 'הטופס נשלח' : 'שלח טופס'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublicInventoryForm; 