import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { AlertTriangle, HelpCircle, Share2, FileDown, Settings, X } from 'lucide-react';

// Hair quantity lookup table - טבלת כמויות שיער
/*
  חוקי הטבלה:
  1. טופ תמיד מתחיל עם 10 גרם פחות מסקין
  2. סרט תמיד מתחיל עם 40 גרם יותר מסקין
  3. כל 5 ס"מ = תוספת של 30 גרם (לכל סוגי המבנה)
  4. התאמות צפיפות:
     - דליל = 30 גרם פחות מהבסיס
     - קלאסי = ללא שינוי מהבסיס
     - מלא = 30 גרם יותר מהבסיס
*/
const HAIR_QUANTITY_TABLE = {
  structures: {
    top: {
      25: 180,    // אורך 25 ס"מ - טופ קלאסי (10 גרם פחות מסקין)
      30: 210,    // אורך 30 ס"מ - טופ קלאסי (+30 גרם)
      35: 240,    // אורך 35 ס"מ - טופ קלאסי (+30 גרם)
      40: 270,    // אורך 40 ס"מ - טופ קלאסי (+30 גרם)
      45: 300,    // אורך 45 ס"מ - טופ קלאסי (+30 גרם)
      50: 330,    // אורך 50 ס"מ - טופ קלאסי (+30 גרם)
    },
    skin: {
      25: 190,    // אורך 25 ס"מ - סקין קלאסי (בסיס)
      30: 220,    // אורך 30 ס"מ - סקין קלאסי (+30 גרם)
      35: 250,    // אורך 35 ס"מ - סקין קלאסי (+30 גרם)
      40: 280,    // אורך 40 ס"מ - סקין קלאסי (+30 גרם)
      45: 310,    // אורך 45 ס"מ - סקין קלאסי (+30 גרם)
      50: 340,    // אורך 50 ס"מ - סקין קלאסי (+30 גרם)
    },
    strip: {
      25: 230,    // אורך 25 ס"מ - סרט קלאסי (40 גרם יותר מסקין)
      30: 260,    // אורך 30 ס"מ - סרט קלאסי (+30 גרם)
      35: 290,    // אורך 35 ס"מ - סרט קלאסי (+30 גרם)
      40: 320,    // אורך 40 ס"מ - סרט קלאסי (+30 גרם)
      45: 350,    // אורך 45 ס"מ - סרט קלאסי (+30 גרם)
      50: 380,    // אורך 50 ס"מ - סרט קלאסי (+30 גרם)
    }
  },
  // התאמות צפיפות - קבועות לכל אורך ולכל מבנה
  densityAdjustments: {
    sparse: -30,  // דליל: 30 גרם פחות מהבסיס
    classic: 0,   // קלאסי: ללא שינוי מהבסיס
    full: 30      // מלא: 30 גרם יותר מהבסיס
  }
};

// קבועים לחישוב - יועברו למצב (state)
const DEFAULT_CONSTANTS = {
  DOLLAR_PRICE_PER_KG: 3000,
  EXCHANGE_RATE: 3.5,
  WASTE_PERCENTAGE: 0.30,
  SKIN_TOP_COST: 1500,  // תוספת עלות רק לסקין/טופ
  NET_COST: 200,
  ADDITIONAL_COST: 300,
  PROFIT_MARGIN: 100  // אחוז רווח ברירת מחדל - 100%
};

// Add tooltip component
const Tooltip = ({ text, children }) => {
  return (
    <div className="group relative inline-block">
      {children}
      <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 absolute z-50 bottom-full mb-2 p-2 w-48 bg-gray-900 text-white text-sm rounded-lg shadow-lg">
        {text}
        <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-gray-900 rotate-45"></div>
      </div>
    </div>
  );
};

// Add field explanations
const fieldExplanations = {
  hairType: "סוג השיער אינו משפיע על המחיר הסופי. זהו מידע תיאורי בלבד עבור הלקוח",
  hairTexture: "מרקם השיער אינו משפיע על המחיר הסופי. זהו מידע תיאורי בלבד עבור הלקוח",
  hairColor: "צבע השיער אינו משפיע על המחיר הסופי. זהו מידע תיאורי בלבד עבור הלקוח",
  napeLength: "אורך העורף משפיע ישירות על כמות השיער הנדרשת ולכן על המחיר הסופי",
  earLength: "אורך האוזניים משמש לחישוב השלבים השונים באה",
  structure: "מבנה הפאה משפיע על המחיר הסופי. טופ וסקין דורשים תוספת תשלום",
  density: "צפיפות השיער משפיעה על כמות השיער הנדרשת ולכן על המחיר הסופי",
  profitMargin: "חוז הרווח שיתווסף למחיר העלות הכוללת",
  workTime: "זמן העבודה המשוער לייצור הפאה. משתנה בהתאם למורכבות העבודה",
};

// Update the label component to include tooltip
const LabelWithTooltip = ({ htmlFor, tooltip, children }) => (
  <div className="flex items-center gap-1">
    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={htmlFor}>{children}</label>
    {tooltip && (
      <Tooltip text={tooltip}>
        <HelpCircle className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-help" />
      </Tooltip>
    )}
  </div>
);

// Update the input field with unit style
const InputWithUnit = ({ name, value, onChange, unit, min, max, placeholder }) => (
  <div className="relative flex rounded-lg border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-transparent">
    <input
      type="number"
      name={name}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      placeholder={placeholder}
      className="w-full px-4 py-3 rounded-lg border-0 focus:ring-0"
    />
    <div className="flex items-center px-4 bg-gray-50 border-r border-gray-300 rounded-l-lg">
      <span className="text-gray-500 whitespace-nowrap">{unit}</span>
    </div>
  </div>
);

// Update the select field style
const SelectField = ({ name, value, onChange, options }) => (
  <div className="relative">
    <select
      name={name}
      value={value}
      onChange={onChange}
      className="w-full px-4 py-3 pr-10 appearance-none bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg className="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  </div>
);

const WigCalculator = () => {
  const loadFromLocalStorage = () => {
    try {
      const savedFormData = localStorage.getItem('wigCalculatorForm');
      const savedCostConstants = localStorage.getItem('wigCalculatorCosts');
      const savedCalculatedData = localStorage.getItem('wigCalculatorResults');
      
      return {
        formData: savedFormData ? JSON.parse(savedFormData) : null,
        costConstants: savedCostConstants ? JSON.parse(savedCostConstants) : null,
        calculatedData: savedCalculatedData ? JSON.parse(savedCalculatedData) : null
      };
    } catch (error) {
      console.error('Error loading from localStorage:', error);
      return {};
    }
  };

  const savedData = loadFromLocalStorage();

  const [formData, setFormData] = useState(savedData.formData || {
    napeLength: 25,
    earLength: 63,
    clientName: '',
    clientPhone: '',
    customizations: '',
    workTime: '4-6',
    hairType: 'ברזילאי',
    hairTexture: 'חלק',
    hairColor: 'שחור',
    paymentTerms: '50% מקדמה, 50% במסירה',
    structure: 'skin',
    density: 'classic'
  });

  const [costConstants, setCostConstants] = useState(savedData.costConstants || DEFAULT_CONSTANTS);
  const [calculatedData, setCalculatedData] = useState(savedData.calculatedData || null);
  const [showCostSettings, setShowCostSettings] = useState(false);

  // שמירה ל-Local Storage בכל שינוי
  useEffect(() => {
    localStorage.setItem('wigCalculatorForm', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    localStorage.setItem('wigCalculatorCosts', JSON.stringify(costConstants));
  }, [costConstants]);

  useEffect(() => {
    if (calculatedData) {
      localStorage.setItem('wigCalculatorResults', JSON.stringify(calculatedData));
    }
  }, [calculatedData]);

  // פונקציה לחישוב אורכים בכל שלב
  const calculateLengths = () => {
    const { napeLength, earLength, hairTexture } = formData;
    const isStrait = hairTexture === 'חלק';
    const textureAdjustment = isStrait ? 1 : 0; // Add 1cm for straight hair in stages 4-5
    
    return {
      nape: {
        stage1: `${Number(napeLength)}`,
        stage2: `${Number(napeLength) + 3}-${Number(napeLength) + 9}`,
        stage3: `${Number(napeLength) + 10}-${Number(napeLength) + 14}`,
        stage4: `${Number(napeLength) + 15 + textureAdjustment}-${Number(napeLength) + 19 + textureAdjustment}`,
        stage5: `${Number(napeLength) + 20 + textureAdjustment}`
      },
      ear: {
        stage3: `${Number(earLength)}-${Number(earLength) + 4}`,
        stage4: `${Number(earLength) + 5}-${Number(earLength) + 9}`
      }
    };
  };

  const calculateHairQuantity = (length, structure, density) => {
    console.log('Calculating hair quantity:', { length, structure, density });
    
    // עיגול האורך ל-5 הקרוב
    const roundedLength = Math.round(length / 5) * 5;
    console.log('Rounded length:', roundedLength);
    
    // מציאת הכמות הבסיסית לפי המבנה והאורך
    let baseQuantity;
    const structureTable = HAIR_QUANTITY_TABLE.structures[structure];
    console.log('Structure table:', structureTable);
    
    if (!structureTable) {
      console.error('מבנה לא חוקי:', structure);
      return 0;
    }

    // מציאת האורך הקרוב ביותר אם אין התאמה מדויקת
    if (structureTable[roundedLength]) {
      baseQuantity = structureTable[roundedLength];
    } else {
      // מציאת האורך הקרוב ביותר
      const lengths = Object.keys(structureTable).map(Number);
      const closestLength = lengths.reduce((prev, curr) => {
        return Math.abs(curr - roundedLength) < Math.abs(prev - roundedLength) ? curr : prev;
      });
      baseQuantity = structureTable[closestLength];
      
      // התאמת הכמות לפי ההפרש באורך
      const lengthDiff = Math.floor((roundedLength - closestLength) / 5);
      baseQuantity += lengthDiff * 30; // תוספת של 30 גרם לכל 5 ס"מ
    }

    console.log('Base quantity before density:', baseQuantity);

    // הוספת התאמת צפיפות
    const densityAdjustment = HAIR_QUANTITY_TABLE.densityAdjustments[density] || 0;
    const finalQuantity = baseQuantity + densityAdjustment;
    
    console.log('Final quantity after density adjustment:', finalQuantity);
    return finalQuantity;
  };

  // Add useEffect for dynamic price calculation
  useEffect(() => {
    // Skip calculation if required fields are missing
    if (!formData.napeLength || !formData.structure || !formData.density) {
      return;
    }

    // Calculate lengths
    const lengths = calculateLengths();

    // Calculate hair quantity based on length, structure, and density
    const baseGrams = calculateHairQuantity(
      Number(formData.napeLength),
      formData.structure,
      formData.density
    );

    // Calculate raw material cost
    const totalGrams = baseGrams * (1 + costConstants.WASTE_PERCENTAGE);
    const rawMaterialCostUSD = (totalGrams / 1000) * costConstants.DOLLAR_PRICE_PER_KG;
    const rawMaterialCostNIS = rawMaterialCostUSD * costConstants.EXCHANGE_RATE;

    // Calculate additional costs based on structure
    let structureCost = 0;
    if (formData.structure === 'skin' || formData.structure === 'top') {
      structureCost = costConstants.SKIN_TOP_COST;
    }

    // Calculate total cost
    const additionalCosts = structureCost + costConstants.NET_COST + costConstants.ADDITIONAL_COST;
    const totalCost = rawMaterialCostNIS + additionalCosts;
    
    // Calculate final price with profit margin
    const profitMarginMultiplier = costConstants.PROFIT_MARGIN / 100;
    const finalPrice = totalCost * (1 + profitMarginMultiplier);

    setCalculatedData({
      lengths,
      rawMaterialGrams: baseGrams,
      wasteGrams: baseGrams * costConstants.WASTE_PERCENTAGE,
      totalGrams,
      rawMaterialCostUSD: rawMaterialCostUSD.toFixed(2),
      rawMaterialCostNIS: rawMaterialCostNIS.toFixed(2),
      additionalCosts,
      totalCost: totalCost.toFixed(2),
      finalPrice: finalPrice.toFixed(2),
      profitAmount: (finalPrice - totalCost).toFixed(2),
      timestamp: new Date().toLocaleString('he-IL'),
      breakdown: {
        rawMaterial: rawMaterialCostNIS.toFixed(2),
        structureCost,
        net: costConstants.NET_COST,
        additional: costConstants.ADDITIONAL_COST
      }
    });
  }, [
    formData.napeLength,
    formData.structure,
    formData.density,
    costConstants.WASTE_PERCENTAGE,
    costConstants.DOLLAR_PRICE_PER_KG,
    costConstants.EXCHANGE_RATE,
    costConstants.SKIN_TOP_COST,
    costConstants.NET_COST,
    costConstants.ADDITIONAL_COST,
    costConstants.PROFIT_MARGIN
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Form field changed:', { name, value });
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // If the change is in lengths, update the table immediately
    if (name === 'napeLength' || name === 'earLength') {
      const lengths = {
        nape: {
          stage1: `${Number(name === 'napeLength' ? value : formData.napeLength)}`,
          stage2: `${Number(name === 'napeLength' ? value : formData.napeLength) + 3}-${Number(name === 'napeLength' ? value : formData.napeLength) + 9}`,
          stage3: `${Number(name === 'napeLength' ? value : formData.napeLength) + 10}-${Number(name === 'napeLength' ? value : formData.napeLength) + 14}`,
          stage4: `${Number(name === 'napeLength' ? value : formData.napeLength) + 15}-${Number(name === 'napeLength' ? value : formData.napeLength) + 19}`,
          stage5: `${Number(name === 'napeLength' ? value : formData.napeLength) + 20}`
        },
        ear: {
          stage3: `${Number(name === 'earLength' ? value : formData.earLength)}-${Number(name === 'earLength' ? value : formData.earLength) + 4}`,
          stage4: `${Number(name === 'earLength' ? value : formData.earLength) + 5}-${Number(name === 'earLength' ? value : formData.earLength) + 9}`
        }
      };

      setCalculatedData(prev => {
        const newData = prev ? {
          ...prev,
          lengths
        } : {
          lengths,
          timestamp: new Date().toLocaleString('he-IL')
        };
        localStorage.setItem('wigCalculatorResults', JSON.stringify(newData));
        return newData;
      });
    }
  };

  const handleCostChange = (e) => {
    const { name, value } = e.target;
    setCostConstants(prev => ({
      ...prev,
      [name]: Number(value)
    }));
  };

  const generatePDF = async () => {
    try {
      const quoteElement = document.getElementById('quote-content');
      
      if (!quoteElement) {
        console.error('Quote element not found');
        return;
      }

      // Set white background and fixed width for consistent rendering
      const originalBackground = quoteElement.style.background;
      const originalWidth = quoteElement.style.width;
      quoteElement.style.background = 'white';
      quoteElement.style.width = '800px'; // Fixed width for consistent rendering

      const canvas = await html2canvas(quoteElement, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: '#ffffff',
        windowWidth: 800, // Match the fixed width
      });

      // Restore original styles
      quoteElement.style.background = originalBackground;
      quoteElement.style.width = originalWidth;
    
      const imgData = canvas.toDataURL('image/png');
      
      // A4 dimensions in mm (210 x 297)
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      // Calculate dimensions while maintaining aspect ratio
      const imgRatio = canvas.height / canvas.width;
      let finalWidth = pdfWidth - 20; // 10mm margins on each side
      let finalHeight = finalWidth * imgRatio;

      // If height exceeds page height, scale down proportionally
      if (finalHeight > pdfHeight - 20) {
        finalHeight = pdfHeight - 20;
        finalWidth = finalHeight / imgRatio;
      }
      
      // Center image horizontally
      const xPosition = (pdfWidth - finalWidth) / 2;
      
      pdf.addImage(imgData, 'PNG', xPosition, 10, finalWidth, finalHeight);
      pdf.save(`הצעת-מחיר-פאה-${formData.clientName || 'לקוח'}-${new Date().toLocaleDateString('he-IL').replace(/\//g, '-')}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const shareViaWhatsApp = () => {
    // Format phone number to international format
    let phoneNumber = formData.clientPhone.replace(/\D/g, ''); // Remove non-digits
    if (phoneNumber.startsWith('0')) {
      phoneNumber = '972' + phoneNumber.substring(1); // Replace leading 0 with 972
    }

    // Create a detailed message with emojis - updated with women emojis
    const message = 
      `👋 *הצעת מחיר פאה - מיכל דיין* 👱‍♀️\n\n` +
      `👩 *פרטי לקוח:*\n` +
      `שם: ${formData.clientName}\n` +
      `📅 תאריך: ${new Date().toLocaleDateString('he-IL')}\n\n` +
      `💇‍♀️ *פרטי הפאה:*\n` +
      `• סוג שיער: ${formData.hairType}\n` +
      `• מרקם: ${formData.hairTexture}\n` +
      `• צבע: ${formData.hairColor}\n` +
      `• אורך: ${formData.napeLength} ס"מ\n` +
      `⏱️ זמן עבודה משוער: ${formData.workTime} שבועות\n\n` +
      `💰 *מחיר סופי: ₪${calculatedData?.finalPrice}*\n` +
      `💳 *תנאי תשלום:* ${formData.paymentTerms}\n\n` +
      (formData.customizations ? `📝 *הערות:*\n${formData.customizations}\n\n` : '') +
      `📞 *ליצירת קשר:*\n` +
      `054-2890590\n\n` +
      `✨ בברכה,\n` +
      `מיכל דיין - עיצוב והתאמת פאות`;
    
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="min-h-screen bg-gray-50 p-2 sm:p-4 md:p-6 overflow-x-hidden w-full">
      <div className="max-w-[1600px] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        {/* Left Column - Input Form */}
        <div className="space-y-6 w-full">
          <Card className="bg-white shadow-sm w-full">
            <CardHeader className="border-b bg-white">
              <div className="flex justify-between items-center flex-wrap">
                <div>
                  <CardTitle className="text-2xl font-bold text-gray-900">מחשבון פאות מקצועי</CardTitle>
                  <p className="text-gray-500 mt-1">מיכל דיין - עיצוב והתאמת פאות</p>
                </div>
                <button
                  onClick={() => setShowCostSettings(!showCostSettings)}
                  className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded-lg transition-colors text-gray-600"
                >
                  <Settings className="w-5 h-5" />
                  <span className="text-sm">הגדרות מחיר</span>
                </button>
              </div>
              
              {/* New Explanation Section */}
              <div className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-100">
                <div className="flex items-start gap-3">
                  <AlertTriangle className="w-5 h-5 text-blue-600 mt-0.5" />
                  <div>
                    <h4 className="font-medium text-blue-900 mb-1">הוראות שימוש</h4>
                    <ul className="text-sm text-blue-800 space-y-1">
                      <li>• הזינו מידות ומאפייני שיער</li>
                      <li>• מלאו פרטי לקוח</li>
                      <li>• המחיר מתעדכן אוטומטית</li>
                    </ul>
                  </div>
                </div>
              </div>
            </CardHeader>
            
            <CardContent className="p-6">
              {/* Settings Modal */}
              {showCostSettings && (
                <div 
                  className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 transition-opacity duration-300"
                  onClick={(e) => {
                    if (e.target === e.currentTarget) {
                      setShowCostSettings(false);
                    }
                  }}
                >
                  <div className="fixed inset-x-0 bottom-0 sm:relative sm:inset-auto">
                    <div 
                      className={`
                        bg-white w-full sm:rounded-xl sm:max-w-2xl sm:w-full sm:mx-auto sm:mt-10 
                        max-h-[85vh] overflow-y-auto relative rounded-t-2xl sm:rounded-xl 
                        shadow-2xl transform transition-transform duration-300 ease-out
                        ${showCostSettings ? 'translate-y-0' : 'translate-y-full'}
                      `}
                    >
                      {/* Header with drag indicator */}
                      <div className="sticky top-0 bg-white border-b z-10">
                        <div className="h-2 flex justify-center items-center sm:hidden">
                          <div className="w-12 h-1.5 bg-gray-300 rounded-full mt-3 mb-1"></div>
                        </div>
                        <div className="px-6 py-4 flex justify-between items-center">
                          <div>
                            <h3 className="text-xl font-bold text-gray-900">הגדרות מחיר</h3>
                            <p className="text-sm text-gray-500 mt-1">עדכון מחירי חומרי גלם ועלויות</p>
                          </div>
                          <button
                            onClick={() => setShowCostSettings(false)}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                          >
                            <X className="w-5 h-5 text-gray-500" />
                          </button>
                        </div>
                      </div>
                      
                      {/* Content */}
                      <div className="px-6 py-6 space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                              מחיר דולר לק"ג
                            </label>
                            <div className="relative">
                              <input
                                type="number"
                                name="DOLLAR_PRICE_PER_KG"
                                value={costConstants.DOLLAR_PRICE_PER_KG}
                                onChange={handleCostChange}
                                className="w-full h-12 pr-10 pl-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base transition-shadow duration-200"
                              />
                              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">$</span>
                            </div>
                          </div>

                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                              שער דולר
                            </label>
                            <div className="relative">
                              <input
                                type="number"
                                name="EXCHANGE_RATE"
                                value={costConstants.EXCHANGE_RATE}
                                onChange={handleCostChange}
                                step="0.01"
                                className="w-full h-12 pr-10 pl-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base transition-shadow duration-200"
                              />
                              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">₪</span>
                            </div>
                          </div>

                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                              אחוז פחת
                            </label>
                            <div className="relative">
                              <input
                                type="number"
                                name="WASTE_PERCENTAGE"
                                value={costConstants.WASTE_PERCENTAGE}
                                onChange={handleCostChange}
                                step="0.01"
                                className="w-full h-12 pr-10 pl-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base transition-shadow duration-200"
                              />
                              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">%</span>
                            </div>
                          </div>

                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                              עלות סקין/טופ
                            </label>
                            <div className="relative">
                              <input
                                type="number"
                                name="SKIN_TOP_COST"
                                value={costConstants.SKIN_TOP_COST}
                                onChange={handleCostChange}
                                className="w-full h-12 pr-10 pl-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base transition-shadow duration-200"
                              />
                              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">₪</span>
                            </div>
                          </div>

                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                              עלות רשת
                            </label>
                            <div className="relative">
                              <input
                                type="number"
                                name="NET_COST"
                                value={costConstants.NET_COST}
                                onChange={handleCostChange}
                                className="w-full h-12 pr-10 pl-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base transition-shadow duration-200"
                              />
                              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">₪</span>
                            </div>
                          </div>

                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                              עלויות נוספות
                            </label>
                            <div className="relative">
                              <input
                                type="number"
                                name="ADDITIONAL_COST"
                                value={costConstants.ADDITIONAL_COST}
                                onChange={handleCostChange}
                                className="w-full h-12 pr-10 pl-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base transition-shadow duration-200"
                              />
                              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">₪</span>
                            </div>
                          </div>

                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                              אחוז רווח
                            </label>
                            <div className="relative">
                              <input
                                type="number"
                                name="PROFIT_MARGIN"
                                value={costConstants.PROFIT_MARGIN}
                                onChange={handleCostChange}
                                min="0"
                                max="500"
                                className="w-full h-12 pr-10 pl-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base transition-shadow duration-200"
                              />
                              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none">%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      {/* Footer */}
                      <div className="sticky bottom-0 bg-white border-t px-6 py-4 sm:px-6 sm:py-6">
                        <button
                          onClick={() => setShowCostSettings(false)}
                          className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 
                            transition-all duration-200 text-base font-medium shadow-lg 
                            hover:shadow-blue-500/25 active:transform active:scale-[0.98]"
                        >
                          שמור הגדרות
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Main Form */}
              <div className="space-y-8">
                {/* Basic Measurements */}
                <section className="space-y-6">
                  <div className="flex items-center gap-4 bg-gradient-to-r from-blue-50 to-transparent p-4 rounded-lg border border-blue-100">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-500 text-white font-semibold">1</div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">מידות בסיסיות</h3>
                      <p className="text-sm text-gray-500">הזן את המידות הבסיסיות של הפאה</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <LabelWithTooltip tooltip={fieldExplanations.napeLength}>
                        אורך עורף
                      </LabelWithTooltip>
                      <InputWithUnit
                        name="napeLength"
                        value={formData.napeLength}
                        onChange={handleChange}
                        unit="ס״מ"
                        min="25"
                        max="75"
                      />
                    </div>

                    <div className="space-y-4">
                      <LabelWithTooltip tooltip={fieldExplanations.earLength}>
                        אורך אוזניים
                      </LabelWithTooltip>
                      <InputWithUnit
                        name="earLength"
                        value={formData.earLength}
                        onChange={handleChange}
                        unit="ס״מ"
                      />
                    </div>
                  </div>
                </section>

                {/* Hair Characteristics */}
                <section className="space-y-6">
                  <div className="flex items-center gap-4 bg-gradient-to-r from-purple-50 to-transparent p-4 rounded-lg border border-purple-100">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple-500 text-white font-semibold">2</div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">מאפייני שיער</h3>
                      <p className="text-sm text-gray-500">בחר את מאפייני השיער הרצויים</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="space-y-4">
                      <LabelWithTooltip tooltip={fieldExplanations.hairType}>
                        סוג שיער
                      </LabelWithTooltip>
                      <SelectField
                        name="hairType"
                        value={formData.hairType}
                        onChange={handleChange}
                        options={[
                          { value: 'ברזילאי', label: 'ברזילאי' },
                          { value: 'אירופאי', label: 'אירופאי' },
                          { value: 'הודי', label: 'הודי' }
                        ]}
                      />
                    </div>

                    <div className="space-y-4">
                      <LabelWithTooltip tooltip={fieldExplanations.hairTexture}>
                        מרקם שיער
                      </LabelWithTooltip>
                      <SelectField
                        name="hairTexture"
                        value={formData.hairTexture}
                        onChange={handleChange}
                        options={[
                          { value: 'חלק', label: 'חלק' },
                          { value: 'גלי', label: 'גלי' },
                          { value: 'מתולתל', label: 'מתולתל' }
                        ]}
                      />
                    </div>

                    <div className="space-y-4">
                      <LabelWithTooltip tooltip={fieldExplanations.hairColor}>
                        צבע שיער
                      </LabelWithTooltip>
                      <SelectField
                        name="hairColor"
                        value={formData.hairColor}
                        onChange={handleChange}
                        options={[
                          { value: 'שחור', label: 'שחור' },
                          { value: 'חום כהה', label: 'חום כהה' },
                          { value: 'חום בהיר', label: 'חום בהיר' },
                          { value: 'בלונד', label: 'בלונד' }
                        ]}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <LabelWithTooltip tooltip={fieldExplanations.structure}>
                        מבנה הפאה
                      </LabelWithTooltip>
                      <div className="grid grid-cols-3 gap-4">
                        {['top', 'skin', 'strip'].map((type) => (
                          <button
                            key={type}
                            onClick={() => handleChange({ target: { name: 'structure', value: type } })}
                            className={`p-4 rounded-lg border-2 transition-all ${
                              formData.structure === type
                                ? 'border-blue-500 bg-blue-50'
                                : 'border-gray-200 hover:border-blue-200'
                            }`}
                          >
                            <div className="text-center">
                              <div className="font-medium">
                                {type === 'top' ? 'טופ' : 
                                 type === 'skin' ? 'סקין' : 'סרט'}
                              </div>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className="space-y-4">
                      <LabelWithTooltip tooltip={fieldExplanations.density}>
                        צפיפות
                      </LabelWithTooltip>
                      <div className="grid grid-cols-3 gap-4">
                        {['sparse', 'classic', 'full'].map((type) => (
                          <button
                            key={type}
                            onClick={() => handleChange({ target: { name: 'density', value: type } })}
                            className={`p-4 rounded-lg border-2 transition-all ${
                              formData.density === type
                                ? 'border-blue-500 bg-blue-50'
                                : 'border-gray-200 hover:border-blue-200'
                            }`}
                          >
                            <div className="text-center">
                              <div className="font-medium">
                                {type === 'sparse' ? 'דליל' : type === 'classic' ? 'קלאסי' : 'מלא'}
                              </div>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Gram display under both sections */}
                    <div className="col-span-full">
                      {formData.structure && (
                        <div className="text-center bg-blue-50 py-3 px-4 rounded-lg border border-blue-200">
                          <div className="text-sm text-gray-600 space-y-2">
                            <div className="font-medium text-gray-700 mb-1">כמות שיער בגרם</div>
                            <div className="grid grid-cols-2 gap-4">
                              <div className="bg-white p-2 rounded border border-blue-100">
                                <div className="text-xs text-gray-500 mb-1">כמות בסיסית</div>
                                <span className="font-bold text-gray-800">
                                  {Math.round(calculateHairQuantity(
                                    Number(formData.napeLength),
                                    formData.structure,
                                    formData.density || 'classic'
                                  ))}
                                </span>
                              </div>
                              <div className="bg-white p-2 rounded border border-blue-100">
                                <div className="text-xs text-gray-500 mb-1">פחת ({Math.round(costConstants.WASTE_PERCENTAGE * 100)}%)</div>
                                <span className="font-bold text-gray-800">
                                  {Math.round(calculateHairQuantity(
                                    Number(formData.napeLength),
                                    formData.structure,
                                    formData.density || 'classic'
                                  ) * costConstants.WASTE_PERCENTAGE)}
                                </span>
                              </div>
                            </div>
                            <div className="mt-2 pt-2 border-t border-blue-200">
                              <div className="text-xs text-gray-500">סה״כ כמות שיער</div>
                              <span className="font-bold text-lg text-blue-600">
                                {Math.round(calculateHairQuantity(
                                  Number(formData.napeLength),
                                  formData.structure,
                                  formData.density || 'classic'
                                ) * (1 + costConstants.WASTE_PERCENTAGE))}
                              </span>
                              <span className="text-sm text-gray-500 mr-1">גרם</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>

                {/* Customer Details */}
                <section className="space-y-6">
                  <div className="flex items-center gap-4 bg-gradient-to-r from-green-50 to-transparent p-4 rounded-lg border border-green-100">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-green-500 text-white font-semibold">3</div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">פרטי לקוח</h3>
                      <p className="text-sm text-gray-500">הזן את פרטי הלקוח והערות נוספות</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-gray-700">
                        שם הלקוח/ה
                      </label>
                      <input
                        type="text"
                        name="clientName"
                        value={formData.clientName}
                        onChange={handleChange}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="הכנס/י שם"
                      />
                    </div>
                    
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-gray-700">
                        טלפון
                      </label>
                      <input
                        type="tel"
                        name="clientPhone"
                        value={formData.clientPhone}
                        onChange={handleChange}
                        placeholder="050-0000000"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <label className="block text-sm font-medium text-gray-700">
                      תנאי תשלום
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                      {[
                        '50% מקדמה, 50% במסירה',
                        '30% מקדמה, 70% מסירה',
                        'תשלום מלא במסירה',
                        '3 תשלומים שווים'
                      ].map((term) => (
                        <button
                          key={term}
                          onClick={() => handleChange({ target: { name: 'paymentTerms', value: term } })}
                          className={`p-4 rounded-lg border-2 transition-all ${
                            formData.paymentTerms === term
                              ? 'border-blue-500 bg-blue-50'
                              : 'border-gray-200 hover:border-blue-200'
                          }`}
                        >
                          <div className="text-center">
                            <div className="font-medium text-sm">{term}</div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <label className="block text-sm font-medium text-gray-700">
                      הערות נוספות
                    </label>
                    <textarea
                      name="customizations"
                      value={formData.customizations}
                      onChange={handleChange}
                      rows="4"
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="פרטים נוספים..."
                    />
                  </div>
                </section>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Column - Live Preview */}
        <div className="space-y-6 lg:sticky lg:top-6 self-start">
          <Card className="bg-white shadow-sm">
            <CardHeader className="border-b bg-white">
              <CardTitle className="text-2xl font-bold text-gray-900">הצעת מחיר</CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              {calculatedData ? (
                <>
                  <div id="quote-content" className="space-y-8 bg-white p-8 rounded-xl">
                    {/* Header with Logo and Title */}
                    <div className="text-center border-b pb-8">
                      <div className="mb-4">
                        <h1 className="text-4xl font-bold text-gray-900 mb-2">מיכל דיין</h1>
                        <p className="text-xl text-blue-600">עיצוב והתאמת פאות</p>
                      </div>
                      <div className="mt-6">
                        <h2 className="text-2xl font-semibold text-gray-800">הצעת מחיר - פאה חדשה</h2>
                        <div className="flex items-center justify-center gap-2 mt-2 text-gray-500">
                          <span>מספר: #{new Date().getTime().toString().slice(-6)}</span>
                          <span className="text-gray-300">|</span>
                          <span>תאריך: {new Date().toLocaleDateString('he-IL')}</span>
                        </div>
                      </div>
                    </div>

                    {/* Main Content */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                      {/* Customer Details */}
                      <div className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-xl border border-blue-100">
                        <div className="flex items-center gap-2 mb-4">
                          <div className="w-1 h-6 bg-blue-500 rounded-full"></div>
                          <h3 className="text-xl font-bold text-gray-800">פרטי לקוח</h3>
                        </div>
                        <div className="space-y-3">
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">שם:</span>
                            <span className="text-gray-600">{formData.clientName || '-'}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">טלפון:</span>
                            <span className="text-gray-600">{formData.clientPhone || '-'}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">תשלום:</span>
                            <span className="text-gray-600">{formData.paymentTerms}</span>
                          </div>
                        </div>
                      </div>

                      {/* Wig Details */}
                      <div className="bg-gradient-to-br from-purple-50 to-white p-6 rounded-xl border border-purple-100">
                        <div className="flex items-center gap-2 mb-4">
                          <div className="w-1 h-6 bg-purple-500 rounded-full"></div>
                          <h3 className="text-xl font-bold text-gray-800">פרטי הפאה</h3>
                        </div>
                        <div className="space-y-3">
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">סוג שיער:</span>
                            <span className="text-gray-600">{formData.hairType}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">מרקם:</span>
                            <span className="text-gray-600">{formData.hairTexture}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">צבע:</span>
                            <span className="text-gray-600">{formData.hairColor}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">אורך:</span>
                            <span className="text-gray-600">{formData.napeLength} ס"מ</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">מבנה:</span>
                            <span className="text-gray-600">
                              {formData.structure === 'top' ? 'טופ' : 
                               formData.structure === 'skin' ? 'סקין' : 'סרט'}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-medium text-gray-700 min-w-[80px]">צפיפות:</span>
                            <span className="text-gray-600">
                              {formData.density === 'classic' ? 'קלאסי' : formData.density === 'sparse' ? 'דליל' : 'מלא'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Price Summary */}
                    <div className="mt-8 w-full">
                      <div className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800 p-8 rounded-xl text-white shadow-lg">
                        <div className="flex flex-wrap justify-between items-center">
                          <div className="mb-2 sm:mb-0">
                            <h3 className="text-2xl font-bold">מחיר סופי</h3>
                            <p className="text-blue-100 mt-1">כולל חומרים ועבודה</p>
                          </div>
                          <div className="text-right">
                            <div className="text-3xl sm:text-5xl font-bold">₪{calculatedData.finalPrice}</div>
                            <p className="text-sm text-blue-100 mt-1">מחיר סופי</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Notes Section - Will appear in PDF if there are customizations */}
                    {formData.customizations && (
                      <div className="mt-8">
                        <div className="bg-gradient-to-br from-yellow-50 to-white p-6 rounded-xl border border-yellow-100">
                          <div className="flex items-center gap-2 mb-4">
                            <div className="w-1 h-6 bg-yellow-500 rounded-full"></div>
                            <h3 className="text-xl font-bold text-gray-800">הערות</h3>
                          </div>
                          <div className="text-gray-600 whitespace-pre-wrap">
                            {formData.customizations}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Footer */}
                    <div className="border-t pt-8 mt-8">
                      <div className="text-center space-y-2">
                        <p className="font-semibold text-gray-800">מיכל דיין - עיצוב והתאמת פאות</p>
                        <div className="flex justify-center items-center gap-4 text-gray-600">
                          <span>054-2890590</span>
                          <span className="text-gray-300">|</span>
                          <span>קרית-ים</span>
                        </div>
                        <div className="text-sm text-gray-500 mt-4">
                          <p className="text-center">* תוקף ההצעה: 14 ימים מתאריך ההצעה</p>
                          <p className="text-center">* ניתן לשלם במזומן או בהעברה בנקאית</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center py-12 text-gray-500">
                  <p>הזן את הפרטים בטופס כדי לראות תצוגה מקדימה של הצעת המחיר</p>
                </div>
              )}
            </CardContent>

            {/* Action Buttons - Moved completely outside CardContent */}
            {calculatedData && (
              <div className="p-6 border-t">
                <div className="flex gap-4">
                  <button
                    onClick={shareViaWhatsApp}
                    className="flex-1 flex items-center justify-center gap-2 bg-green-500 text-white py-4 px-6 rounded-lg hover:bg-green-600 transition-colors text-lg font-medium shadow-lg"
                  >
                    <Share2 className="w-6 h-6" />
                    <span>שתפ בוואצאפ</span>
                  </button>
                  <button
                    onClick={generatePDF}
                    className="flex-1 flex items-center justify-center gap-2 bg-blue-500 text-white py-4 px-6 rounded-lg hover:bg-blue-600 transition-colors text-lg font-medium shadow-lg"
                  >
                    <FileDown className="w-6 h-6" />
                    <span>הורד PDF</span>
                  </button>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default WigCalculator; 