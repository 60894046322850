import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Users,
  Building,
  ClipboardList,
  FileText,
  TrendingUp,
  Calendar,
  AlertCircle,
  Package,
  Truck,
  FileX,
  CheckCircle,
  Clock,
  BarChart2
} from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../config';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { motion } from 'framer-motion';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const DashboardContent = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const token = useSelector(state => state.auth.token);
  
  const [dashboardData, setDashboardData] = useState({
    branches: [],
    users: [],
    adminStats: {},
    inventoryForms: {
      total: 0,
      active: 0,
      completed: 0,
      expired: 0
    },
    rejectionForms: {
      total: 0,
      open: 0,
      inProgress: 0,
      completed: 0
    },
    forms: {
      inventory: [],
      rejections: []
    }
  });
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        
        // Get branches
        const branchesRes = await axios.get(`${API_URL}/api/branches`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Get users from admin endpoint
        const usersRes = await axios.get(`${API_URL}/api/admin/users`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Get dashboard stats from admin endpoint
        const adminStatsRes = await axios.get(`${API_URL}/api/admin/dashboard-stats`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Get rejection forms dashboard
        const rejectionsRes = await axios.get(`${API_URL}/api/rejection-forms/dashboard`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Get inventory dashboard
        const inventoryRes = await axios.get(`${API_URL}/api/inventory/dashboard`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setDashboardData({
          branches: branchesRes.data || [],
          users: usersRes.data || [],
          adminStats: adminStatsRes.data || {},
          inventoryForms: inventoryRes.data?.stats || { 
            total: 0, 
            active: 0, 
            completed: 0,
            expired: 0 
          },
          rejectionForms: rejectionsRes.data?.stats || { 
            total: 0, 
            open: 0, 
            inProgress: 0, 
            completed: 0 
          },
          forms: {
            inventory: inventoryRes.data?.forms || [],
            rejections: rejectionsRes.data?.forms || []
          }
        });

        console.log('Dashboard Data:', {
          branches: branchesRes.data?.length,
          users: usersRes.data?.length,
          adminStats: adminStatsRes.data,
          inventoryStats: inventoryRes.data?.stats,
          rejectionStats: rejectionsRes.data?.stats
        });

        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    if (token) {
      fetchDashboardData();
    }
  }, [token]);

  const stats = {
    overview: {
      totalBranches: dashboardData.branches.length,
      activeManagers: dashboardData.users.filter(user => 
        user.role === 'area_manager' && user.isActive !== false
      ).length,
      totalEmployees: dashboardData.users.length
    },
    inventory: {
      total: dashboardData.inventoryForms.total || 0,
      active: dashboardData.inventoryForms.active || 0,
      completed: dashboardData.inventoryForms.completed || 0,
      expired: dashboardData.inventoryForms.expired || 0
    },
    rejections: {
      total: dashboardData.rejectionForms.total || 0,
      open: dashboardData.rejectionForms.open || 0,
      inProgress: dashboardData.rejectionForms.inProgress || 0,
      completed: dashboardData.rejectionForms.completed || 0
    },
    recentActivities: dashboardData.forms.rejections
      .slice(0, 5)
      .map(form => ({
        type: 'rejection',
        description: `רג׳קט חדש מסניף ${form.branchName || form.branch?.name || 'לא ידוע'}`,
        timestamp: new Date(form.createdAt).toLocaleDateString('he-IL', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      }))
  };

  console.log('Calculated Stats:', stats);

  const getMonthlyStats = () => {
    const last6Months = Array.from({ length: 6 }, (_, i) => {
      const d = new Date();
      d.setMonth(d.getMonth() - i);
      return {
        date: d,
        label: d.toLocaleString('he-IL', { month: 'short' }),
        year: d.getFullYear(),
        month: d.getMonth()
      };
    }).reverse();

    // Get stats for each month
    const monthlyStats = last6Months.map(monthData => {
      const startOfMonth = new Date(monthData.year, monthData.month, 1);
      const endOfMonth = new Date(monthData.year, monthData.month + 1, 0, 23, 59, 59);

      const inventoryCount = dashboardData.forms.inventory.filter(form => {
        const formDate = new Date(form.createdAt);
        return formDate >= startOfMonth && formDate <= endOfMonth;
      }).length;

      const rejectionCount = dashboardData.forms.rejections.filter(form => {
        const formDate = new Date(form.createdAt);
        return formDate >= startOfMonth && formDate <= endOfMonth;
      }).length;

      return {
        label: monthData.label,
        inventory: inventoryCount,
        rejections: rejectionCount
      };
    });

    return monthlyStats;
  };

  const monthlyStats = getMonthlyStats();

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom'
      }
    }
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom'
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full text-red-500">
        <AlertCircle className="mr-2" /> {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Welcome Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl p-6 shadow-lg">
        <h1 className="text-2xl font-bold mb-2">ברוכים הבאים לדשבורד מנהל מערכת</h1>
        <p className="text-blue-100">כאן תוכלו למצוא סיכום של כל הפעילות במערכת</p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Inventory Forms Card */}
        <div 
          className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow cursor-pointer"
          onClick={() => navigate('/dashboard/inventory')}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <ClipboardList className="h-6 w-6 ml-2 text-blue-600" />
                שאלוני מלאי
              </h2>
              <span className="text-sm text-gray-500">לחץ לצפייה</span>
            </div>
            <div className="grid grid-cols-4 gap-4">
              <div className="text-center">
                <div className="text-2xl font-bold text-gray-800">{stats.inventory.total}</div>
                <div className="text-sm text-gray-500">סה"כ</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-red-600">{stats.inventory.active}</div>
                <div className="text-sm text-gray-500">פעילים</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-green-600">{stats.inventory.completed}</div>
                <div className="text-sm text-gray-500">הושלמו</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-orange-500">{stats.inventory.expired}</div>
                <div className="text-sm text-gray-500">פג תוקף</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-3">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">
                {stats.inventory.active > 0 
                  ? `${stats.inventory.active} שאלונים פעילים ממתינים למילוי` 
                  : stats.inventory.expired > 0 
                    ? `${stats.inventory.expired} שאלונים שפג תוקפם` 
                    : 'אין שאלונים פעילים'}
              </span>
            </div>
          </div>
        </div>

        {/* Rejection Forms Card */}
        <div 
          className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow cursor-pointer"
          onClick={() => navigate('/dashboard/rejection-forms')}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <FileX className="h-6 w-6 ml-2 text-blue-600" />
                רג'קטים תפעוליים
              </h2>
              <span className="text-sm text-gray-500">לחץ לצפייה</span>
            </div>
            <div className="grid grid-cols-4 gap-4">
              <div className="text-center">
                <div className="text-2xl font-bold text-gray-800">{stats.rejections.total}</div>
                <div className="text-sm text-gray-500">סה"כ</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-red-600">{stats.rejections.open}</div>
                <div className="text-sm text-gray-500">משימות פתוחות</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-orange-500">{stats.rejections.inProgress}</div>
                <div className="text-sm text-gray-500">בטיפול</div>
              </div>
              <div className="text-center">
                <div className="text-2xl font-bold text-green-600">{stats.rejections.completed}</div>
                <div className="text-sm text-gray-500">הושלמו</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-3">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">
                {stats.rejections.open + stats.rejections.inProgress > 0 
                  ? `${stats.rejections.open + stats.rejections.inProgress} משימות מחכות לטיפול` 
                  : 'כל המשימות הושלמו 🎉'}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-4">סטטיסטיקות חודשיות</h3>
            <div className="h-64">
              <Line
                data={{
                  labels: monthlyStats.map(stat => stat.label),
                  datasets: [
                    {
                      label: 'דוחות מלאי',
                      data: monthlyStats.map(stat => stat.inventory),
                      borderColor: 'rgb(99, 102, 241)',
                      tension: 0.4,
                      fill: true,
                      backgroundColor: 'rgba(99, 102, 241, 0.1)'
                    },
                    {
                      label: 'רג׳קטים',
                      data: monthlyStats.map(stat => stat.rejections),
                      borderColor: 'rgb(239, 68, 68)',
                      tension: 0.4,
                      fill: true,
                      backgroundColor: 'rgba(239, 68, 68, 0.1)'
                    }
                  ]
                }}
                options={{
                  ...chartOptions,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-4">סטטוס רג׳קטים</h3>
            <div className="h-64">
              <Pie
                data={{
                  labels: ['פתוחים', 'בטיפול', 'הושלמו'],
                  datasets: [{
                    data: [
                      stats.rejections.open,
                      stats.rejections.inProgress,
                      stats.rejections.completed
                    ],
                    backgroundColor: [
                      'rgb(239, 68, 68)',
                      'rgb(249, 115, 22)',
                      'rgb(34, 197, 94)'
                    ]
                  }]
                }}
                options={pieOptions}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Recent Activities */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-6">
          <h3 className="text-xl font-bold text-gray-800 mb-4">פעילות אחרונה</h3>
          <div className="space-y-4">
            {stats.recentActivities.map((activity, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
              >
                <span className="text-gray-700">{activity.description}</span>
                <span className="text-sm text-gray-500">{activity.timestamp}</span>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;