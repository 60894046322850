import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Loader, Filter, Search, Eye, Calendar, Building, User, Download, CheckCircle, XCircle, Edit2 } from 'lucide-react';
import api, { getSalaryUpgrades, updateSalaryUpgradeStatus, exportSalaryUpgrades, editSalaryUpgrade } from '../../utils/api';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';

const SalaryUpgradeSurvey = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('all');
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [editingSubmission, setEditingSubmission] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const hebrewMonths = [
    { value: '01', label: 'ינואר' },
    { value: '02', label: 'פברואר' },
    { value: '03', label: 'מרץ' },
    { value: '04', label: 'אפריל' },
    { value: '05', label: 'מאי' },
    { value: '06', label: 'יוני' },
    { value: '07', label: 'יולי' },
    { value: '08', label: 'אוגוסט' },
    { value: '09', label: 'ספטמבר' },
    { value: '10', label: 'אוקטובר' },
    { value: '11', label: 'נובמבר' },
    { value: '12', label: 'דצמבר' }
  ];

  useEffect(() => {
    fetchSubmissions();
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await api.get('/api/admin/branches');
      setBranches(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching branches:', error);
      toast.error('שגיאה בטעינת סניפים');
      setBranches([]);
    }
  };

  const fetchSubmissions = async () => {
    try {
      const data = await getSalaryUpgrades();
      setSubmissions(data);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      toast.error('שגיאה בטעינת הבקשות');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (submissionId, newStatus) => {
    try {
      await updateSalaryUpgradeStatus(submissionId, newStatus);
      toast.success('הסטטוס עודכן בהצלחה');
      fetchSubmissions();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('שגיאה בעדכון הסטטוס');
    }
  };

  const handleExport = async () => {
    try {
      toast.loading('מכין קובץ אקסל...', { id: 'export' });
      const data = await exportSalaryUpgrades();
      
      // Create download link
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary-upgrades-${format(new Date(), 'yyyy-MM-dd')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      
      toast.success('הקובץ הורד בהצלחה', { id: 'export' });
    } catch (error) {
      console.error('Error exporting data:', error);
      toast.error('שגיאה בייצוא הנתונים', { id: 'export' });
    }
  };

  const handleEdit = async (formData) => {
    try {
      await editSalaryUpgrade(editingSubmission._id, formData);
      toast.success('הבקשה עודכנה בהצלחה');
      setIsEditModalOpen(false);
      setEditingSubmission(null);
      fetchSubmissions();
    } catch (error) {
      console.error('Error updating submission:', error);
      toast.error('שגיאה בעדכון הבקשה');
    }
  };

  const filteredSubmissions = submissions
    .filter(sub => {
      if (filter === 'all') return true;
      return sub.status === filter;
    })
    .filter(sub => 
      searchTerm === '' || 
      sub.employeeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sub.employeeNumber.includes(searchTerm) ||
      (sub.branchName && sub.branchName.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .filter(sub => selectedBranch === 'all' || sub.branchName === selectedBranch)
    .filter(sub => {
      if (selectedMonth === 'all') return true;
      const submissionMonth = new Date(sub.createdAt).toISOString().slice(0, 7); // YYYY-MM format
      return submissionMonth === selectedMonth;
    });

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved': return 'text-green-600';
      case 'rejected': return 'text-red-600';
      default: return 'text-yellow-600';
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'approved': return 'bg-green-100 text-green-800';
      case 'rejected': return 'bg-red-100 text-red-800';
      default: return 'bg-yellow-100 text-yellow-800';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'approved': return 'אושר';
      case 'rejected': return 'נדחה';
      default: return 'ממתין לאישור';
    }
  };

  // Simple Modal component without @headlessui
  const EditModal = () => {
    const [formData, setFormData] = useState(editingSubmission || {});

    const handleSubmit = (e) => {
      e.preventDefault();
      handleEdit(formData);
    };

    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 bg-black bg-opacity-30" onClick={() => setIsEditModalOpen(false)} />
        <div className="flex items-center justify-center min-h-screen p-4">
          <div className="relative bg-white rounded-lg p-6 max-w-lg w-full mx-auto shadow-xl">
            <h2 className="text-lg font-medium mb-4">עריכת בקשת העלאת שכר</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">שם העובד</label>
                <input
                  type="text"
                  value={formData.employeeName || ''}
                  onChange={(e) => setFormData({...formData, employeeName: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">מספר עובד</label>
                <input
                  type="text"
                  value={formData.employeeNumber || ''}
                  onChange={(e) => setFormData({...formData, employeeNumber: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">סניף</label>
                <select
                  value={formData.branchId || ''}
                  onChange={(e) => setFormData({...formData, branchId: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                >
                  <option value="">בחר סניף</option>
                  {branches.map(branch => (
                    <option key={branch._id} value={branch._id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">שכר נוכחי</label>
                <input
                  type="number"
                  value={formData.currentSalary || ''}
                  onChange={(e) => setFormData({...formData, currentSalary: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">שכר מבוקש</label>
                <input
                  type="number"
                  value={formData.requestedSalary || ''}
                  onChange={(e) => setFormData({...formData, requestedSalary: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setIsEditModalOpen(false)}
                  className="btn btn-secondary"
                >
                  ביטול
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  שמור שינויים
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="sales-card p-6 rounded-xl shadow-lg animate-fade-in flex justify-center items-center min-h-[200px]">
        <div className="flex items-center gap-3">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
          <span className="text-gray-600 font-medium">טוען...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto"
      >
        <div className="sales-card p-6 rounded-xl shadow-lg animate-fade-in">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-800">ניהול בקשות העלאות שכר</h1>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-600">
                סה"כ בקשות: {submissions.length}
              </div>
              <button
                onClick={handleExport}
                className="btn btn-primary shadow-lg transform hover:scale-105 transition-all duration-300 flex items-center gap-2"
              >
                <Download className="w-5 h-5" />
                ייצוא לאקסל
              </button>
            </div>
          </div>

          {/* Filters Section */}
          <div className="flex flex-wrap gap-4 mb-6 bg-white/50 p-4 rounded-lg shadow-sm">
            {/* Status Filter */}
            <div className="relative">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="block w-40 rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
                style={{ backgroundPosition: "left 0.5rem center" }}
              >
                <option value="all">כל הסטטוסים</option>
                <option value="pending">ממתין</option>
                <option value="approved">אושר</option>
                <option value="rejected">נדחה</option>
              </select>
            </div>

            {/* Branch Filter */}
            <div className="relative">
              <select
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
                className="block w-40 rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
                style={{ backgroundPosition: "left 0.5rem center" }}
              >
                <option value="all">כל הסניפים</option>
                {branches.map(branch => (
                  <option key={branch._id} value={branch.name}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Month Filter */}
            <div className="relative">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                className="block w-40 rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
                style={{ backgroundPosition: "left 0.5rem center" }}
              >
                <option value="all">כל החודשים</option>
                {hebrewMonths.map(month => (
                  <option key={month.value} value={`2024-${month.value}`}>
                    {month.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Search Input */}
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="חיפוש לפי שם או מספר עובד..."
                className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right appearance-none"
                style={{ backgroundPosition: "left 0.5rem center" }}
              />
            </div>
          </div>

          {/* Table Section */}
          <div className="overflow-x-auto rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 bg-white/50">
              <thead>
                <tr className="bg-gray-50/80">
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    תאריך הגשה
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    שם העובד
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    מספר עובד
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    תעודת זהות
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    תפקיד
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    סניף
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    שכר נוכחי
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    שכר מבוקש
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    סטטוס
                  </th>
                  <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                    פעולות
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white/30">
                {filteredSubmissions.map((submission) => (
                  <tr 
                    key={submission._id}
                    className="hover:bg-blue-50/50 transition-colors duration-200"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                      {format(new Date(submission.createdAt), 'dd/MM/yyyy')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800 font-medium">
                      {submission.employeeName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                      {submission.employeeNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                      {submission.idNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                      {submission.role}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                      {submission.branchName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                      ₪{submission.currentSalary}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                      ₪{submission.requestedSalary}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeColor(submission.status)}`}>
                        {getStatusText(submission.status)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                      <div className="flex items-center gap-2 justify-end">
                        <button
                          onClick={() => {
                            setEditingSubmission(submission);
                            setIsEditModalOpen(true);
                          }}
                          className="text-blue-600 hover:text-blue-800 transition-colors"
                          title="ערוך"
                        >
                          <Edit2 className="h-5 w-5" />
                        </button>
                        {submission.status === 'pending' && (
                          <>
                            <button
                              onClick={() => handleStatusUpdate(submission._id, 'approved')}
                              className="text-green-600 hover:text-green-800 transition-colors"
                              title="אשר"
                            >
                              <CheckCircle className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleStatusUpdate(submission._id, 'rejected')}
                              className="text-red-600 hover:text-red-800 transition-colors"
                              title="דחה"
                            >
                              <XCircle className="h-5 w-5" />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </motion.div>
      {isEditModalOpen && <EditModal />}
    </div>
  );
};

export default SalaryUpgradeSurvey;