import React, { useState } from 'react';
import { Plus, Trash2, Building2, Search, Hash, Edit2, X, Check } from 'lucide-react';
import { useBranchManagement } from './hooks';

const BranchManagement = () => {
  const { 
    newBranch,
    newBranchId,
    branches,
    handleNewBranchChange,
    handleNewBranchIdChange,
    addNewBranch, 
    deleteBranch,
    searchTerm,
    setSearchTerm,
    error, 
    success,
    isLoading,
    updateBranch,
  } = useBranchManagement();

  const [editingBranch, setEditingBranch] = useState(null);

  const filteredBranches = branches.filter(branch => 
    branch.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    branch.branch_id.toString().includes(searchTerm)
  );

  const handleEdit = (branch) => {
    setEditingBranch({
      ...branch,
      newName: branch.name,
      newBranchId: branch.branch_id
    });
  };

  const handleCancelEdit = () => {
    setEditingBranch(null);
  };

  const handleSaveEdit = async (branch) => {
    await updateBranch(branch._id, {
      name: branch.newName,
      branch_id: branch.newBranchId
    });
    setEditingBranch(null);
  };

  return (
    <div className="space-y-6" dir="rtl">
      {/* Add Branch Section */}
      <div className="bg-white p-6 rounded-xl shadow-lg">
        <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
          <Building2 className="h-6 w-6 ml-2 text-blue-500" />
          הוסף סניף
        </h3>
        
        {error && (
          <div className="bg-red-50 text-red-700 p-3 rounded-lg mb-4 flex items-center">
            <span className="ml-2">⚠️</span>
            {error}
          </div>
        )}
        
        {success && (
          <div className="bg-green-50 text-green-700 p-3 rounded-lg mb-4 flex items-center">
            <span className="ml-2">✅</span>
            {success}
          </div>
        )}

        <div className="flex flex-col space-y-4">
          {/* Branch ID Input */}
          <div className="flex items-center space-x-2">
            <Hash className="h-5 w-5 text-gray-400" />
            <input
              type="number"
              id="newBranchId"
              value={newBranchId}
              onChange={handleNewBranchIdChange}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              placeholder="מספר סניף"
              min="1"
            />
          </div>

          {/* Branch Name Input */}
          <div className="flex items-center space-x-2">
            <Building2 className="h-5 w-5 text-gray-400" />
            <input
              type="text"
              id="newBranch"
              value={newBranch}
              onChange={handleNewBranchChange}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              placeholder="שם הסניף"
            />
          </div>

          {/* Add Button */}
          <button 
            onClick={addNewBranch}
            disabled={isLoading || !newBranch.trim() || !newBranchId}
            className="w-full sm:w-auto bg-gradient-to-r from-green-500 to-green-600 text-white px-6 py-3 rounded-lg
                     hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 
                     focus:ring-offset-2 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed
                     transform hover:-translate-y-0.5"
          >
            <div className="flex items-center justify-center">
              <Plus className="h-5 w-5 ml-2" />
              הוסף סניף
            </div>
          </button>
        </div>
      </div>

      {/* Branches List Section */}
      <div className="bg-white p-6 rounded-xl shadow-lg">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-gray-800">רשימת סניפים</h3>
          <div className="relative">
            <input
              type="text"
              placeholder="חיפוש סניף..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="h-5 w-5 text-gray-400 absolute right-3 top-2.5" />
          </div>
        </div>

        <div className="divide-y divide-gray-100">
          {filteredBranches.length > 0 ? (
            filteredBranches.map((branch) => (
              <div 
                key={branch._id} 
                className="flex items-center justify-between py-4 group hover:bg-gray-50 rounded-lg px-3 transition-colors duration-200"
              >
                {editingBranch && editingBranch._id === branch._id ? (
                  // Edit mode
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center space-x-4">
                      <input
                        type="number"
                        value={editingBranch.newBranchId}
                        onChange={(e) => setEditingBranch({
                          ...editingBranch,
                          newBranchId: parseInt(e.target.value)
                        })}
                        className="w-20 p-1 border rounded"
                        min="1"
                      />
                      <input
                        type="text"
                        value={editingBranch.newName}
                        onChange={(e) => setEditingBranch({
                          ...editingBranch,
                          newName: e.target.value
                        })}
                        className="p-1 border rounded"
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => handleSaveEdit(editingBranch)}
                        className="text-green-500 hover:text-green-700 p-2 rounded-full hover:bg-green-50"
                        title="שמור"
                      >
                        <Check className="h-5 w-5" />
                      </button>
                      <button
                        onClick={handleCancelEdit}
                        className="text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-50"
                        title="בטל"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ) : (
                  // View mode
                  <>
                    <div className="flex items-center">
                      <Building2 className="h-5 w-5 text-gray-400 ml-3" />
                      <span className="text-gray-500 text-sm ml-2">#{branch.branch_id}</span>
                      <span className="text-gray-400 mx-2">|</span>
                      <span className="text-gray-700 font-medium">{branch.name}</span>
                    </div>
                    <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-all duration-200">
                      <button
                        onClick={() => handleEdit(branch)}
                        className="text-blue-500 hover:text-blue-700 p-2 rounded-full hover:bg-blue-50"
                        title="ערוך סניף"
                      >
                        <Edit2 className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => deleteBranch(branch._id)}
                        className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50"
                        title="מחק סניף"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              {searchTerm ? 'לא נמצאו סניפים התואמים את החיפוש' : 'אין סניפים להצגה'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BranchManagement;