import { useEffect } from 'react';

const CLARITY_PROJECT_ID = 'pjeltvcyv5';

export const useClarity = () => {
  useEffect(() => {
    // Remove production check temporarily for testing
    try {
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", CLARITY_PROJECT_ID);
      
      console.log('✅ Clarity script loaded successfully');
      
      // Check if Clarity is initialized after a short delay
      setTimeout(() => {
        if (window.clarity) {
          console.log('✅ Clarity initialized and ready to track');
        } else {
          console.warn('⚠️ Clarity object not found. Make sure the Project ID is correct');
        }
      }, 2000);

    } catch (error) {
      console.error('❌ Error initializing Clarity:', error);
    }
  }, []);
};

// Custom event tracking function with logging
export const trackClarityEvent = (eventName, eventData) => {
  if (window.clarity && typeof window.clarity === 'function') {
    try {
      window.clarity('event', eventName, eventData);
      console.log('📊 Clarity event tracked:', { eventName, eventData });
    } catch (error) {
      console.error('❌ Error tracking Clarity event:', error);
    }
  } else {
    console.warn('⚠️ Clarity not initialized. Event not tracked:', eventName);
  }
}; 