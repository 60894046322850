import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoChevronBackCircle, IoChevronForwardCircle } from 'react-icons/io5';
import IntroSection from './sections/IntroSection';
import BranchAchievements from './sections/BranchAchievements';
import CelebrationSection from './sections/CelebrationSection';
import { useClarity, trackClarityEvent } from '../../utils/useClarity';
import './sections/sections.css';

const YearSummary = () => {
  useClarity(); // Initialize Clarity
  const [currentSection, setCurrentSection] = useState(0);
  const [direction, setDirection] = useState(0);

  const sections = [
    { id: 'intro', component: IntroSection },
    { id: 'achievements', component: BranchAchievements },
    { id: 'celebration', component: CelebrationSection }
  ];

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    if (
      (currentSection === 0 && newDirection === -1) ||
      (currentSection === sections.length - 1 && newDirection === 1)
    ) {
      return;
    }
    setDirection(newDirection);
    setCurrentSection(prev => {
      const nextSection = prev + newDirection;
      trackClarityEvent('section_change', {
        from: sections[prev].id,
        to: sections[nextSection].id,
        direction: newDirection > 0 ? 'forward' : 'backward'
      });
      return nextSection;
    });
  };

  const handleDotClick = (targetSection) => {
    setDirection(targetSection > currentSection ? 1 : -1);
    trackClarityEvent('dot_navigation', {
      from: sections[currentSection].id,
      to: sections[targetSection].id
    });
    setCurrentSection(targetSection);
  };

  const CurrentComponent = sections[currentSection].component;

  return (
    <div className="year-summary-container">
      <AnimatePresence initial={false} custom={direction} mode="wait">
        <motion.div
          key={sections[currentSection].id}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
          className="section-wrapper"
        >
          <CurrentComponent />
        </motion.div>
      </AnimatePresence>

      {/* Navigation dots */}
      <div className="navigation-dots">
        {sections.map((section, index) => (
          <button
            key={section.id}
            className={`nav-dot ${currentSection === (sections.length - 1 - index) ? 'active' : ''}`}
            onClick={() => handleDotClick(sections.length - 1 - index)}
            aria-label={`Go to ${section.id} section`}
          />
        )).reverse()}
      </div>

      {/* Navigation arrows */}
      <div className="navigation-arrows">
        <button
          className="nav-arrow prev"
          onClick={() => paginate(-1)}
          disabled={currentSection === 0}
          aria-label="Previous section"
        >
          <IoChevronBackCircle size={40} />
        </button>
        <button
          className="nav-arrow next"
          onClick={() => paginate(1)}
          disabled={currentSection === sections.length - 1}
          aria-label="Next section"
        >
          <IoChevronForwardCircle size={40} />
        </button>
      </div>
    </div>
  );
};

export default YearSummary; 