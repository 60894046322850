import { createSlice } from '@reduxjs/toolkit';

// Session duration in milliseconds (7 days)
const SESSION_DURATION = 7 * 24 * 60 * 60 * 1000;

const checkSessionValidity = () => {
  const loginTimestamp = localStorage.getItem('loginTimestamp');
  if (!loginTimestamp) return false;
  
  const now = Date.now();
  const sessionAge = now - parseInt(loginTimestamp);
  return sessionAge < SESSION_DURATION;
};

const loadInitialState = () => {
  if (!checkSessionValidity()) {
    // Clear expired session
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('loginTimestamp');
    return {
      user: null,
      token: null,
      loginTimestamp: null,
    };
  }

  return {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.getItem('token') || null,
    loginTimestamp: localStorage.getItem('loginTimestamp') || null,
  };
};

const initialState = loadInitialState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      state.loginTimestamp = Date.now();
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      localStorage.setItem('loginTimestamp', Date.now().toString());
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.loginTimestamp = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('loginTimestamp');
    },
    checkSession: (state) => {
      if (!checkSessionValidity()) {
        state.user = null;
        state.token = null;
        state.loginTimestamp = null;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('loginTimestamp');
      }
    },
  },
});

export const { setCredentials, logout, checkSession } = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectLoginTimestamp = (state) => state.auth.loginTimestamp;

export default authSlice.reducer;