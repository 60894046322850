import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { createGiftCard, getAreaManagerBranches, getBranches } from '../../utils/api';

const GiftCardForm = ({ onSubmit }) => {
  const auth = useSelector(state => state.auth);
  const user = auth?.user;
  const token = auth?.token;
  const isAreaManager = user?.role === 'area_manager';
  const isAdmin = user?.role === 'admin';

  const [formData, setFormData] = useState({
    workerId: '',
    workerName: '',
    branchId: '',
    notes: ''
  });
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branchesLoading, setBranchesLoading] = useState(true);

  useEffect(() => {
    const fetchBranches = async () => {
      if (!isAreaManager && !isAdmin) {
        console.error('Only area managers and admins can create gift cards');
        toast.error('רק מנהל אזור או מנהל יכול ליצור כרטיסי מתנה');
        return;
      }

      try {
        setBranchesLoading(true);
        const response = await (isAdmin ? getBranches() : getAreaManagerBranches());
        console.log('Branches response:', response);
        
        // For admin, response is direct array, for area manager it's in data property
        const branchesData = isAdmin ? response : response.data;
        
        if (!Array.isArray(branchesData)) {
          console.error('Invalid branches response:', response);
          toast.error('שגיאה בטעינת סניפים');
          setBranches([]);
          return;
        }
        
        if (branchesData.length === 0) {
          console.error('No branches found');
          toast.error('לא נמצאו סניפים');
          setBranches([]);
          return;
        }
        
        setBranches(branchesData);
      } catch (error) {
        console.error('Error fetching branches:', error);
        toast.error('שגיאה בטעינת רשימת הסניפים');
        setBranches([]);
      } finally {
        setBranchesLoading(false);
      }
    };

    fetchBranches();
  }, [isAreaManager, isAdmin]);

  if (!user || !token) {
    return (
      <div className="text-center p-4">
        <p className="text-red-600">יש להתחבר למערכת כדי להוסיף כרטיס מתנה</p>
      </div>
    );
  }

  if (!isAreaManager && !isAdmin) {
    return (
      <div className="text-center p-4">
        <p className="text-red-600">רק מנהל אזור או מנהל יכול ליצור כרטיסי מתנה</p>
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.branchId) {
      toast.error('נא לבחור סניף');
      return;
    }
    setLoading(true);

    try {
      if (!user?.userId) {
        console.error('Auth state:', auth);
        throw new Error('User data not available');
      }

      const giftCardData = {
        ...formData
      };

      console.log('Submitting gift card with data:', giftCardData);
      await createGiftCard(giftCardData);
      toast.success('כרטיס מתנה נשמר בהצלחה');
      setFormData({
        workerId: '',
        workerName: '',
        branchId: '',
        notes: ''
      });
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      console.error('Error submitting gift card:', error);
      const errorMessage = error.response?.data?.message || 'שגיאה בשמירת כרטיס המתנה';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="sales-card p-6 rounded-xl shadow-lg animate-fade-in">
      <h2 className="text-2xl font-bold mb-6 text-right text-gray-800">הוספת כרטיס מתנה חדש</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-semibold text-gray-700 text-right mb-2">תעודת זהות עובד</label>
          <input
            type="text"
            name="workerId"
            value={formData.workerId}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right transition-all duration-300"
          />
        </div>
        
        <div>
          <label className="block text-sm font-semibold text-gray-700 text-right mb-2">שם העובד</label>
          <input
            type="text"
            name="workerName"
            value={formData.workerName}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right transition-all duration-300"
          />
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 text-right mb-2">סניף</label>
          <select
            name="branchId"
            value={formData.branchId}
            onChange={handleChange}
            required
            disabled={branchesLoading}
            className="mt-1 block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right transition-all duration-300"
          >
            <option value="">
              {branchesLoading ? 'טוען סניפים...' : 'בחר סניף'}
            </option>
            {branches.map(branch => (
              <option key={branch._id} value={branch._id}>
                {branch.name}
              </option>
            ))}
          </select>
          {branchesLoading && (
            <div className="mt-2 text-sm text-gray-500 text-right">
              <span className="inline-block animate-spin rounded-full h-4 w-4 border-b-2 border-gray-500 ml-2"></span>
              טוען רשימת סניפים...
            </div>
          )}
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 text-right mb-2">הערות</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right transition-all duration-300"
          />
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary shadow-lg transform hover:scale-105 transition-all duration-300 flex items-center gap-2"
          >
            {loading ? (
              <>
                <span className="inline-block animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
                <span>שומר...</span>
              </>
            ) : (
              'שמור כרטיס מתנה'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default GiftCardForm;
