import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ expiresAt, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = new Date(expiresAt) - new Date();
    if (difference <= 0) {
      if (onTimerEnd) onTimerEnd();
      return null;
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      
      if (!newTimeLeft) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [expiresAt]);

  if (!timeLeft) return (
    <div className="bg-red-500/90 text-white rounded-xl p-3 text-center min-w-[200px] shadow-lg border-2 border-white/20">
      <div className="text-2xl font-bold mb-1">00:00:00</div>
      <div className="text-sm text-white/90">הזמן נגמר! ⚠️</div>
    </div>
  );

  const isNearingEnd = timeLeft.days === 0 && timeLeft.hours < 2;

  const formatNumber = (num) => String(num).padStart(2, '0');

  return (
    <div className={`
      rounded-xl p-3 text-center min-w-[200px]
      ${isNearingEnd
        ? 'bg-orange-500/90 text-white border-2 border-white/20' 
        : 'bg-emerald-100 text-emerald-800 border border-emerald-200'
      } 
      shadow-lg
    `}>
      <div className="text-2xl font-bold mb-1">
        {timeLeft.days > 0 
          ? `${timeLeft.days}:${formatNumber(timeLeft.hours)}:${formatNumber(timeLeft.minutes)}`
          : `${formatNumber(timeLeft.hours)}:${formatNumber(timeLeft.minutes)}:${formatNumber(timeLeft.seconds)}`
        }
      </div>
      <div className={`text-sm ${isNearingEnd ? 'text-white/90' : 'text-emerald-700'}`}>
        {timeLeft.days > 0 
          ? `${timeLeft.days} ימים ${timeLeft.hours} שעות`
          : isNearingEnd ? 'ממהר להסתיים! ⚠️' : 'זמן נותר'
        }
      </div>
    </div>
  );
};

export default CountdownTimer; 