import React, { useState } from 'react';
import WigCalculator from './WigCalculator';
import WigRepairCalculator from './WigRepairCalculator';
import { Card } from '../ui/card';
import Login from './Login';
import { LogOut } from 'lucide-react';

const WigCalculatorSwitcher = () => {
  const [activeCalculator, setActiveCalculator] = useState('new');
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  if (!isLoggedIn) {
    return <Login onLogin={setIsLoggedIn} />;
  }

  return (
    <div className="min-h-screen bg-gray-50 p-2 sm:p-4 md:p-6">
      <div className="max-w-[1600px] mx-auto space-y-4">
        <Card className="bg-white shadow-sm">
          <div className="p-4 border-b flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-900">מערכת לניהול הצעות מחיר לפאות</h2>
            <button
              onClick={handleLogout}
              className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-lg transition-all"
            >
              <LogOut className="w-5 h-5" />
              <span>התנתק/י</span>
            </button>
          </div>

          <div className="p-4">
            <div className="flex justify-center gap-3">
              <button
                onClick={() => setActiveCalculator('new')}
                className={`
                  flex-1 px-6 py-3 rounded-lg text-lg font-semibold transition-all
                  ${activeCalculator === 'new'
                    ? 'bg-blue-600 text-white shadow-md hover:bg-blue-700'
                    : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                  }
                `}
              >
                <div className="flex flex-col items-center gap-1">
                  <span>מחשבון פאות</span>
                  <span className="text-xs font-normal">
                    {activeCalculator === 'new' ? 'פעיל כעת' : 'הכנה של פאה מהתחלה עד הסוף'}
                  </span>
                </div>
              </button>

              <button
                onClick={() => setActiveCalculator('repair')}
                className={`
                  flex-1 px-6 py-3 rounded-lg text-lg font-semibold transition-all
                  ${activeCalculator === 'repair'
                    ? 'bg-blue-600 text-white shadow-md hover:bg-blue-700'
                    : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                  }
                `}
              >
                <div className="flex flex-col items-center gap-1">
                  <span>מחשבון תיקוני פאות</span>
                  <span className="text-xs font-normal">
                    {activeCalculator === 'repair' ? 'פעיל כעת' : 'חישוב עלויות תיקון והחלפת שיער'}
                  </span>
                </div>
              </button>
            </div>

            <div className="mt-3 text-center">
              <p className="text-sm text-gray-500">
                {activeCalculator === 'new' 
                  ? 'מחשבון להכנת הצעת מחיר לפאה חדשה כולל חישוב כמויות וחומרים'
                  : 'מחשבון לחישוב עלויות תיקון פאה קיימת והחלפת שיער'
                }
              </p>
            </div>
          </div>
        </Card>

        {activeCalculator === 'new' ? <WigCalculator /> : <WigRepairCalculator />}
      </div>
    </div>
  );
};

export default WigCalculatorSwitcher; 