import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

// Register Hebrew font
Font.register({
  family: 'Rubik',
  src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf'
});

// Create styles with RTL support
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Rubik',
    padding: 30,
    direction: 'rtl',
  },
  header: {
    backgroundColor: '#1e40af',
    padding: 20,
    marginBottom: 20,
    borderRadius: 8,
  },
  title: {
    fontSize: 24,
    textAlign: 'right',
    color: '#ffffff',
    marginBottom: 4,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'right',
    color: '#e5e7eb',
    direction: 'rtl',
    writingMode: 'horizontal-tb',
    alignSelf: 'flex-end',
  },
  infoGrid: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f8fafc',
    borderRadius: 8,
    border: '1px solid #e2e8f0',
  },
  infoColumn: {
    flex: 1,
  },
  infoItem: {
    marginBottom: 8,
    flexDirection: 'row-reverse',
  },
  label: {
    color: '#6b7280',
    marginLeft: 8,
    fontSize: 12,
  },
  value: {
    color: '#111827',
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e5e7eb',
    marginTop: 20,
  },
  tableRow: {
    flexDirection: 'row-reverse',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e7eb',
    minHeight: 35,
    alignItems: 'center',
  },
  tableHeader: {
    backgroundColor: '#f9fafb',
    fontWeight: 'bold',
  },
  tableCell: {
    padding: 8,
    fontSize: 10,
    textAlign: 'right',
  },
  descriptionCell: {
    width: '30%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  statusCell: {
    width: '15%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  dateCell: {
    width: '15%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  imageCell: {
    width: '25%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  commentsCell: {
    width: '25%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  image: {
    width: 80,
    height: 80,
    objectFit: 'contain',
  },
  statusBadge: {
    padding: 4,
    borderRadius: 4,
    fontSize: 10,
    textAlign: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: 'center',
    color: '#9ca3af',
    fontSize: 10,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    fontSize: 10,
    color: '#9ca3af',
  },
  commentText: {
    fontSize: 8,
    color: '#4b5563',
    marginBottom: 4,
  },
  commentDate: {
    fontSize: 6,
    color: '#9ca3af',
  },
  rtlLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
  },
  labelContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  labelText: {
    color: '#6b7280',
    fontSize: 12,
  },
  labelColon: {
    color: '#6b7280',
    fontSize: 12,
    marginRight: 2,
    marginLeft: 8,
  },
  value: {
    color: '#111827',
    fontSize: 12,
    marginRight: 4,
  },
});

const getStatusStyle = (status) => {
  switch (status) {
    case 'completed':
      return { color: '#065f46', backgroundColor: '#d1fae5' };
    case 'in_progress':
      return { color: '#1e40af', backgroundColor: '#dbeafe' };
    default:
      return { color: '#92400e', backgroundColor: '#fef3c7' };
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 'completed':
      return 'הושלם';
    case 'in_progress':
      return 'בטיפול';
    default:
      return 'ממתין';
  }
};

const formatDate = (dateString) => {
  if (!dateString) return '-';
  try {
    const date = new Date(dateString);
    // Check if date is valid
    if (isNaN(date.getTime())) {
      return '-';
    }
    return date.toLocaleDateString('he-IL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return '-';
  }
};

const optimizeCloudinaryUrl = (url) => {
  if (!url) return '';
  
  try {
    // Handle array of URLs
    if (Array.isArray(url)) {
      return url[0]; // Take first image if it's an array
    }

    // Check if it's a Cloudinary URL
    if (!url.includes('cloudinary.com')) return url;

    const urlObj = new URL(url);
    
    // Define PDF-optimized transformations
    const transformations = [
      'fl_attachment', // Force download
      'f_png', // Force PNG format
      'q_auto:best', // Best quality
      'w_800', // Width constraint
      'h_800', // Height constraint
      'c_limit', // Limit mode
      'fl_preserve_transparency', // Keep transparency
      'dpr_2.0' // High DPR
    ];
    
    // Parse existing path
    const pathParts = urlObj.pathname.split('/');
    const uploadIndex = pathParts.indexOf('upload');
    
    if (uploadIndex !== -1) {
      // Insert transformations after 'upload'
      pathParts.splice(uploadIndex + 1, 0, transformations.join(','));
      urlObj.pathname = pathParts.join('/');
      
      // Add a timestamp to prevent caching
      urlObj.searchParams.append('t', Date.now());
      
      return urlObj.toString();
    }
    
    return url;
  } catch (error) {
    console.error('Error optimizing Cloudinary URL:', error, 'URL:', url);
    return url;
  }
};

const PDFImage = ({ src, style }) => {
  if (!src) return null;

  const optimizedUrl = optimizeCloudinaryUrl(src);
  
  return (
    <View style={{ width: style.width, height: style.height }}>
      <Image
        src={optimizedUrl}
        style={{
          ...style,
          objectFit: 'contain',
        }}
        cache={false} // Disable caching to ensure fresh image
      />
    </View>
  );
};

const InfoLabel = ({ label, value }) => (
  <View style={styles.labelContainer}>
    <View style={styles.rtlLabel}>
      <Text style={styles.labelText}>{label}</Text>
    </View>
    <Text style={styles.value}>{value}</Text>
  </View>
);

// Add this helper function to get the most recent update date
const getLatestUpdateDate = (item) => {
  if (!item.comments || item.comments.length === 0) {
    return item.updatedAt;
  }
  
  // Get the latest comment date
  const latestCommentDate = new Date(Math.max(
    ...item.comments.map(comment => new Date(comment.createdAt))
  ));
  
  return latestCommentDate;
};

const getBranchName = (form) => {
  // First try to get branchName directly
  if (form.branchName) {
    return form.branchName;
  }
  
  // Then try to get it from the branch object
  if (form.branch) {
    if (typeof form.branch === 'object') {
      return form.branch.name || form.branch.branchName || 'לא צוין';
    }
    // If branch is a string (either name or id)
    return form.branch;
  }
  
  return 'לא צוין';
};

const RejectionFormPDF = ({ form }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Enhanced Header */}
      <View style={styles.header}>
        <View style={{ flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center' }}>
          <View>
            <Text style={styles.title}>דוח רג'קטים תפעוליים</Text>
            <Text style={styles.subtitle}>
              {`סניף ${getBranchName(form)}`}
            </Text>
          </View>
          <View style={{ backgroundColor: 'rgba(255,255,255,0.1)', padding: 8, borderRadius: 4 }}>
            <Text style={{ color: '#ffffff', fontSize: 12 }}>
              {formatDate(new Date())}
            </Text>
          </View>
        </View>
      </View>

      {/* Form Info Grid */}
      <View style={styles.infoGrid}>
        <View style={styles.infoColumn}>
          <InfoLabel label="מפקח" value={form.inspector} />
          <InfoLabel label="תאריך דוח" value={formatDate(form.reportDate)} />
        </View>
        <View style={styles.infoColumn}>
          <InfoLabel label="תאריך יעד" value={formatDate(form.dueDate)} />
          <InfoLabel label="מספר משימות" value={form.items.length.toString()} />
        </View>
      </View>

      {/* Tasks Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <View style={[styles.tableCell, styles.descriptionCell]}>
            <Text>תיאור המשימה</Text>
          </View>
          <View style={[styles.tableCell, styles.statusCell]}>
            <Text>סטטוס</Text>
          </View>
          <View style={[styles.tableCell, styles.dateCell]}>
            <Text>תאריך עדכון</Text>
          </View>
          <View style={[styles.tableCell, styles.imageCell]}>
            <Text>תמונה</Text>
          </View>
          <View style={[styles.tableCell, styles.commentsCell]}>
            <Text>הערות</Text>
          </View>
        </View>

        {/* Table Body */}
        {form.items.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={[styles.tableCell, styles.descriptionCell]}>
              <Text>{item.description}</Text>
            </View>
            <View style={[styles.tableCell, styles.statusCell]}>
              <View style={[styles.statusBadge, getStatusStyle(item.status)]}>
                <Text>{getStatusText(item.status)}</Text>
              </View>
            </View>
            <View style={[styles.tableCell, styles.dateCell]}>
              <Text>
                {formatDate(getLatestUpdateDate(item))}
              </Text>
            </View>
            <View style={[styles.tableCell, styles.imageCell]}>
              {item.imageUrl && (
                <PDFImage 
                  src={item.imageUrl}
                  style={styles.image}
                />
              )}
              {item.comments?.slice(-1).map((comment, index) => (
                comment.imageUrls?.length > 0 && (
                  <View key={index} style={{ marginTop: 4 }}>
                    <PDFImage 
                      src={comment.imageUrls[0]}
                      style={{ ...styles.image, width: 60, height: 60 }}
                    />
                  </View>
                )
              ))}
            </View>
            <View style={[styles.tableCell, styles.commentsCell]}>
              {item.comments && item.comments.length > 0 ? (
                <View>
                  {item.comments.slice(-2).map((comment, cIndex) => (
                    <View key={cIndex}>
                      <Text style={styles.commentText}>
                        {comment.text}
                      </Text>
                      <Text style={styles.commentDate}>
                        {formatDate(comment.createdAt)}
                      </Text>
                    </View>
                  ))}
                  {item.comments.length > 2 && (
                    <Text style={styles.commentText}>
                      + {item.comments.length - 2} תגובות נוספות
                    </Text>
                  )}
                </View>
              ) : (
                <Text>אין תגובות</Text>
              )}
            </View>
          </View>
        ))}
      </View>

      {/* Footer */}
      <Text style={styles.footer}>
        הופק בתאריך: {formatDate(new Date())}
      </Text>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
);

export default RejectionFormPDF; 