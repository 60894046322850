import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTrophy, FaChartLine, FaRocket, FaMedal, FaAward, FaStore, FaArrowUp } from 'react-icons/fa';
import confetti from 'canvas-confetti';

const BranchAchievements = () => {
  const achievements = [
    // Large Branches (>2000 גיוסים)
    {
      branch: 'סופר דוש קניון',
      recruitments: 5264,
      size: 'large',
      description: 'מוביל ארצי',
      icon: FaTrophy,
      highlight: 'ביצועים יוצאי דופן'
    },
    {
      branch: 'מחסני מזון סיטי אשדוד',
      recruitments: 3645,
      size: 'large',
      description: 'הישג מרשים',
      icon: FaMedal,
      highlight: 'עבודה מצוינת'
    },
    {
      branch: 'היפר דודו פתח תקווה (סלור)',
      recruitments: 2761,
      size: 'large',
      description: 'ביצועים חזקים',
      icon: FaRocket,
      highlight: 'צמיחה מתמדת'
    },
    {
      branch: 'ביג קרית אתא',
      recruitments: 2702,
      size: 'large',
      description: 'הישגים גבוהים',
      icon: FaRocket,
      highlight: 'עקביות מרשימה'
    },

    // Medium Branches (1000-2000 גיוסים)
    {
      branch: 'מחסני להב נתניה קניון',
      recruitments: 1739,
      size: 'medium',
      description: 'ביצועים יציבים',
      icon: FaAward,
      highlight: 'עקביות לאורך השנה'
    },
    {
      branch: 'מחסני מזון נצרת עלית',
      recruitments: 1875,
      size: 'medium',
      description: 'גיוסים מרשימים ביחס לגודל',
      icon: FaChartLine,
      highlight: 'עקביות מצוינת לאורך השנה'
    },
    {
      branch: 'סופר דוש חולון',
      recruitments: 1393,
      size: 'medium',
      description: 'צמיחה יציבה',
      icon: FaArrowUp,
      highlight: 'מגמת שיפור חיובית'
    },
    {
      branch: 'סביניה',
      recruitments: 1271,
      size: 'medium',
      description: 'ביצועים טובים',
      icon: FaStore,
      highlight: 'יציבות ועקביות'
    },
    {
      branch: 'סופר דוש בתימון',
      recruitments: 1279,
      size: 'medium',
      description: 'התקדמות יפה',
      icon: FaStore,
      highlight: 'שיפור מתמשך'
    },
    {
      branch: 'כרמיאל',
      recruitments: 1224,
      size: 'medium',
      description: 'עבודה מצוינת',
      icon: FaStore,
      highlight: 'ביצועים יציבים'
    },
    {
      branch: 'להב קרית ים',
      recruitments: 1069,
      size: 'medium',
      description: 'התקדמות טובה',
      icon: FaStore,
      highlight: 'מאמץ מרשים'
    },

    // Small-Medium Branches (500-1000 גיוסים)
    {
      branch: 'מרום נווה',
      recruitments: 974,
      size: 'small-medium',
      description: 'צמיחה יפה',
      icon: FaStore,
      highlight: 'פוטנציאל גבוה'
    },
    {
      branch: 'סופר דוש עצמאות',
      recruitments: 981,
      size: 'small-medium',
      description: 'התקדמות טובה',
      icon: FaStore,
      highlight: 'מגמה חיובית'
    },
    {
      branch: 'היפר דודו קרית ים',
      recruitments: 959,
      size: 'small-medium',
      description: 'שיפור מתמיד',
      icon: FaStore,
      highlight: 'עבודה טובה'
    },
    {
      branch: 'מחסני מזון ק.שמואל',
      recruitments: 923,
      size: 'small-medium',
      description: 'התקדמות יציבה',
      icon: FaStore,
      highlight: 'פוטנציאל רב'
    },
    {
      branch: 'מחסני להב וולפסון',
      recruitments: 863,
      size: 'small-medium',
      description: 'שיפור ניכר',
      icon: FaStore,
      highlight: 'מאמץ מרשים'
    },
    {
      branch: 'באר שבע',
      recruitments: 863,
      size: 'small-medium',
      description: 'פוטנציאל אדיר לצמיחה',
      icon: FaStore,
      highlight: 'מקום משמעותי לשיפור'
    },
    {
      branch: 'מחסני מזון טימול אשדוד',
      recruitments: 776,
      size: 'small-medium',
      description: 'שיפור מתמיד',
      icon: FaStore,
      highlight: 'פוטנציאל גבוה'
    },
    {
      branch: 'להב אשדוד',
      recruitments: 682,
      size: 'small-medium',
      description: 'התקדמות יציבה',
      icon: FaStore,
      highlight: 'עבודה טובה'
    },
    {
      branch: 'יבנה',
      recruitments: 614,
      size: 'small-medium',
      description: 'צמיחה נאה',
      icon: FaStore,
      highlight: 'מגמה חיובית'
    },
    {
      branch: 'גליל ים',
      recruitments: 611,
      size: 'small-medium',
      description: 'שיפור מתמשך',
      icon: FaStore,
      highlight: 'התקדמות טובה'
    },
    {
      branch: 'קרית אליעזר',
      recruitments: 604,
      size: 'small-medium',
      description: 'התפתחות יפה',
      icon: FaStore,
      highlight: 'פוטנציאל רב'
    },
    {
      branch: 'באר יעקב',
      recruitments: 563,
      size: 'small-medium',
      description: 'צוטנציאל גבוה מאוד',
      icon: FaStore,
      highlight: 'הזדמנות לצמיחה משמעותית'
    },

    // Small Branches (<500 גיוסים)
    {
      branch: 'מחסני מזון נווה גנים',
      recruitments: 511,
      size: 'small',
      description: 'התקדמות טובה',
      icon: FaStore,
      highlight: 'פוטנציאל צמיחה'
    },
    {
      branch: 'רמז',
      recruitments: 473,
      size: 'small',
      description: 'שיפור מתמיד',
      icon: FaStore,
      highlight: 'כיוון חיובי'
    },
    {
      branch: 'רמת אביב',
      recruitments: 477,
      size: 'small',
      description: 'צמיחה יציבה',
      icon: FaStore,
      highlight: 'התקדמות טובה'
    },
    {
      branch: 'טירת הכרמל',
      recruitments: 450,
      size: 'small',
      description: 'התפתחות נאה',
      icon: FaStore,
      highlight: 'מגמה חיובית'
    },
    {
      branch: 'כרמים',
      recruitments: 453,
      size: 'small',
      description: 'שיפור הדרגתי',
      icon: FaStore,
      highlight: 'פוטנציאל טוב'
    },
    {
      branch: 'רעננה הנשיאים',
      recruitments: 417,
      size: 'small',
      description: 'התקדמות יציבה',
      icon: FaStore,
      highlight: 'צמיחה מתמדת'
    },
    {
      branch: 'הנדיב',
      recruitments: 392,
      size: 'small',
      description: 'צמיחה עקבית',
      icon: FaStore,
      highlight: 'מאמץ ניכר'
    },
    {
      branch: 'כצנלסון',
      recruitments: 384,
      size: 'small',
      description: 'שיפור מתמיד',
      icon: FaStore,
      highlight: 'התקדמות טובה'
    },
    {
      branch: 'היפר דודו המושבה',
      recruitments: 370,
      size: 'small',
      description: 'התפתחות חיובית',
      icon: FaStore,
      highlight: 'פוטנציאל צמיחה'
    },
    {
      branch: 'טיפטוב בבלי',
      recruitments: 386,
      size: 'small',
      description: 'צמיחה הדרגתית',
      icon: FaStore,
      highlight: 'מגמה חיובית'
    },
    {
      branch: 'יהוד',
      recruitments: 299,
      size: 'small',
      description: 'התקדמות יציבה',
      icon: FaStore,
      highlight: 'פוטנציאל רב'
    },
    {
      branch: 'טיפטוב ברש',
      recruitments: 266,
      size: 'small',
      description: 'שיפור מתמשך',
      icon: FaStore,
      highlight: 'כיוון חיובי'
    },
    {
      branch: 'פרש קרית ביאליק',
      recruitments: 249,
      size: 'small',
      description: 'צמיחה עקבית',
      icon: FaStore,
      highlight: 'התקדמות טובה'
    },
    {
      branch: 'היפר דודו מינץ',
      recruitments: 233,
      size: 'small',
      description: 'התפתחות נאה',
      icon: FaStore,
      highlight: 'פוטנציאל צמיחה'
    },
    {
      branch: 'מחסני מזון פתח תקווה',
      recruitments: 174,
      size: 'small',
      description: 'שיפור הדרגתי',
      icon: FaStore,
      highlight: 'מאמץ ניכר'
    },
    {
      branch: 'טיפ טוב גולומב',
      recruitments: 157,
      size: 'small',
      description: 'התקדמות יציבה',
      icon: FaStore,
      highlight: 'צמיחה מתמדת'
    },
    {
      branch: 'טיפ טוב ברוריה',
      recruitments: 111,
      size: 'small',
      description: 'צמיחה עקבית',
      icon: FaStore,
      highlight: 'פוטנציאל רב'
    },
    {
      branch: 'אזורי חן',
      recruitments: 77,
      size: 'small',
      description: 'התפתחות חיובית',
      icon: FaStore,
      highlight: 'מגמה חיובית'
    },
    {
      branch: 'שברץ רעננה',
      recruitments: 23,
      size: 'small',
      description: 'בתחילת הדרך',
      icon: FaStore,
      highlight: 'פוטנציאל צמיחה'
    }
  ];

  const filteredAchievements = achievements.sort((a, b) => b.recruitments - a.recruitments);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  const item = {
    hidden: { y: 20, opacity: 0, scale: 0.8 },
    show: { 
      y: 0, 
      opacity: 1, 
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  const shootConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <motion.div
      className="branch-achievements"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <motion.h2
        className="section-title"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        סיכום ביצועי הסניפים 🏆
      </motion.h2>

      <AnimatePresence>
        <motion.div className="achievements-grid">
          {filteredAchievements.map((achievement, index) => {
            const Icon = achievement.icon;
            return (
              <motion.div
                key={achievement.branch}
                className={`achievement-card ${achievement.size}`}
                variants={item}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
                whileTap={{ scale: 0.98 }}
                onClick={shootConfetti}
                layout
              >
                <motion.div className="achievement-icon">
                  <Icon />
                </motion.div>
                <h3 className="branch-name">{achievement.branch}</h3>
                <div className="achievement-details">
                  <p className="recruitments">{achievement.recruitments.toLocaleString()} גיוסי אפליקציה</p>
                  <p className="description">{achievement.description}</p>
                  <p className="highlight">{achievement.highlight}</p>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </AnimatePresence>

      <motion.p
        className="all-branches-note"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1.2 }}
      >
        כל סניף תורם להצלחה הכוללת שלנו! יחד אנחנו חזקים יותר 🌟
      </motion.p>
    </motion.div>
  );
};

export default BranchAchievements; 