import React, { useState, useEffect } from 'react';
import api, { createRejectionForm } from '../../utils/api';
import { Plus, Link2, ChevronDown, ChevronUp, Filter, Download, Edit } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RejectionFormPDF from './RejectionFormPDF';
import { pdf } from '@react-pdf/renderer';

const ImagePreview = ({ imageUrls, alt, size = "medium" }) => {
  const sizeClasses = {
    small: "w-24 h-24",
    medium: "w-32 h-32",
    large: "w-40 h-40",
    full: "w-full h-48"
  };

  // If single image URL is passed as string, convert to array
  const images = typeof imageUrls === 'string' ? [imageUrls] : imageUrls;

  if (!images?.length) return null;

  const handleImageClick = (e, url) => {
    e.stopPropagation(); // Prevent event bubbling
    window.open(url, '_blank');
  };

  const handleImageError = (e, url) => {
    console.error('Failed to load image:', url);
    e.target.style.display = 'none';
  };

  return (
    <div className="flex gap-2 flex-wrap" onClick={e => e.stopPropagation()}>
      {images.map((url, index) => {
        // Skip if URL is null, undefined, or empty
        if (!url) return null;

        // Clean up the URL if needed (remove any double slashes except after protocol)
        const cleanUrl = url.replace(/([^:]\/)\/+/g, "$1");

        return (
          <div 
            key={index} 
            className="relative group cursor-pointer"
            onClick={(e) => handleImageClick(e, cleanUrl)}
          >
            <img 
              src={cleanUrl} 
              alt={`${alt} ${index + 1}`}
              className={`rounded-lg object-cover ${sizeClasses[size]}`}
              onError={(e) => handleImageError(e, cleanUrl)}
              loading="lazy"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-200 rounded-lg">
              <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                לחץ להגדלה
              </span>
            </div>
          </div>
        );
      }).filter(Boolean)}
    </div>
  );
};

const getFormattedDate = (date) => {
  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
      return new Date().toISOString().split('T')[0]; // fallback to current date
    }
    return d.toISOString().split('T')[0];
  } catch (error) {
    console.error('Error formatting date for filename:', error);
    return new Date().toISOString().split('T')[0]; // fallback to current date
  }
};

const RejectionFormsDashboard = () => {
  const [forms, setForms] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [expandedForm, setExpandedForm] = useState(null);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('all');
  const [preparedPDFs, setPreparedPDFs] = useState({});
  const [newForm, setNewForm] = useState({
    branch: '',
    branchName: '',
    inspector: '',
    reportDate: new Date().toISOString().split('T')[0],
    dueDate: '',
    items: [{ description: '', imageUrl: '', requiresPhoto: false }]
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingForm, setEditingForm] = useState(null);

  useEffect(() => {
    fetchBranches();
    fetchForms();
  }, []);

  useEffect(() => {
    if (selectedBranch === 'all') {
      setFilteredForms(forms);
    } else {
      const filtered = forms.filter(form => {
        // Match by either branch ID or branch name
        return form.branch === selectedBranch || form.branchName === selectedBranch;
      });
      console.log('Filtered forms for branch:', selectedBranch, filtered);
      setFilteredForms(filtered);
    }
    
    updateStats(selectedBranch === 'all' ? forms : forms.filter(form => 
      form.branch === selectedBranch || form.branchName === selectedBranch
    ));
  }, [selectedBranch, forms]);

  const fetchBranches = async () => {
    try {
      const response = await api.get('/api/branches');
      setBranches(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
      toast.error('שגיאה בטעינת רשימת הסניפים');
    }
  };

  const updateStats = (formsToCount) => {
    const allTasks = formsToCount.flatMap(form => form.items);
    
    const newStats = {
      total: allTasks.length,
      open: allTasks.filter(item => item.status === 'pending').length,
      inProgress: allTasks.filter(item => item.status === 'in_progress').length,
      completed: allTasks.filter(item => item.status === 'completed').length,
      completionRate: allTasks.length ? 
        Math.round((allTasks.filter(item => item.status === 'completed').length / allTasks.length) * 100) : 0,
      averageTasksPerForm: formsToCount.length ? 
        Math.round(allTasks.length / formsToCount.length) : 0
    };
    
    setStats(newStats);
  };

  const fetchForms = async () => {
    try {
      const response = await api.get('/api/rejection-forms/dashboard');
      console.log('Fetched dashboard forms:', response.data);
      
      // Verify image URLs in the response
      response.data.forms.forEach(form => {
        form.items.forEach(item => {
          if (item.imageUrl) {
            console.log('Item image URL:', item.imageUrl);
          }
          item.comments?.forEach(comment => {
            if (comment.imageUrl) {
              console.log('Comment image URL:', comment.imageUrl);
            }
          });
        });
      });
      
      setForms(response.data.forms);
      setStats(response.data.stats);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching forms:', error);
      toast.error('שגיאה בטעינת הטפסים');
      setLoading(false);
    }
  };

  const handleCreateForm = async () => {
    try {
      // Find the selected branch object
      const selectedBranchObj = branches.find(b => b._id === newForm.branch);
      if (!selectedBranchObj) {
        toast.error('נא לבחור סניף');
        return;
      }

      // Create form with branch ID and name
      const formData = {
        ...newForm,
        branch: selectedBranchObj._id,  // Send branch ID
        branchName: selectedBranchObj.name  // Send branch name
      };

      const response = await createRejectionForm(formData);
      setForms([response.form, ...forms]);
      setShowCreateModal(false);
      setNewForm({
        branch: '',
        branchName: '',
        inspector: '',
        reportDate: new Date().toISOString().split('T')[0],
        dueDate: '',
        items: [{ description: '', imageUrl: '', requiresPhoto: false }]
      });
      fetchForms();
      toast.success('הטופס נוצר בהצלחה');
    } catch (error) {
      console.error('Error creating form:', error);
      toast.error(error.response?.data?.message || 'שגיאה ביצירת הטופס');
    }
  };

  // Update branch selection handler
  const handleBranchChange = (e) => {
    const branchId = e.target.value;
    const selectedBranchObj = branches.find(b => b._id === branchId);
    setNewForm(prev => ({
      ...prev,
      branch: branchId,
      branchName: selectedBranchObj ? selectedBranchObj.name : ''
    }));
  };

  const addItem = () => {
    setNewForm({
      ...newForm,
      items: [...newForm.items, { description: '', imageUrl: '', requiresPhoto: false }]
    });
  };

  const removeItem = (index) => {
    setNewForm({
      ...newForm,
      items: newForm.items.filter((_, i) => i !== index)
    });
  };

  const updateItem = (index, field, value) => {
    const updatedItems = [...newForm.items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setNewForm({ ...newForm, items: updatedItems });
  };

  const toggleFormExpansion = (formId) => {
    setExpandedForm(expandedForm === formId ? null : formId);
  };

  const calculateProgress = (items) => {
    if (!items?.length) return 0;
    const completed = items.filter(item => item.status === 'completed').length;
    return Math.round((completed / items.length) * 100);
  };

  const preparePDFForForm = async (form) => {
    try {
      toast.loading('מכין PDF...');
      const blob = await pdf(<RejectionFormPDF form={form} />).toBlob();
      setPreparedPDFs(prev => ({
        ...prev,
        [form._id]: {
          blob,
          timestamp: Date.now()
        }
      }));
      toast.dismiss();
      toast.success('PDF מוכן להורדה');
    } catch (error) {
      console.error('Error preparing PDF:', error);
      toast.dismiss();
      toast.error('שגיאה בהכנת ה-PDF');
    }
  };

  const clearPreparedPDF = (formId) => {
    setPreparedPDFs(prev => {
      const newState = { ...prev };
      delete newState[formId];
      return newState;
    });
  };

  const handleEditForm = async () => {
    try {
      const response = await api.patch(`/api/rejection-forms/${editingForm._id}`, {
        branch: editingForm.branch,
        inspector: editingForm.inspector,
        reportDate: editingForm.reportDate,
        dueDate: editingForm.dueDate,
        items: editingForm.items
      });

      // Update the forms list with the edited form
      setForms(forms.map(form => 
        form._id === response.data._id ? response.data : form
      ));

      setShowEditModal(false);
      setEditingForm(null);
      toast.success('הטופס עודכן בהצלחה');
      fetchForms(); // Refresh the list
    } catch (error) {
      console.error('Error editing form:', error);
      toast.error(error.response?.data?.message || 'שגיאה בעדכון הטופס');
    }
  };

  if (loading) return <div className="text-center p-8">טוען...</div>;

  return (
    <div className="min-h-screen bg-gray-50" dir="rtl">
      <div className="max-w-7xl mx-auto p-2 sm:p-6">
        {/* Enhanced Header Section */}
        <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6 mb-4 sm:mb-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 sm:space-x-reverse w-full sm:w-auto">
              <h1 className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
                ניהול רג׳קטים תפעוליים
              </h1>
              <div className="relative w-full sm:w-auto">
                <select
                  value={selectedBranch}
                  onChange={(e) => setSelectedBranch(e.target.value)}
                  className="w-full sm:w-auto appearance-none bg-white border border-gray-300 rounded-lg py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-blue-500"
                >
                  <option value="all">כל הסניפים</option>
                  {branches.map(branch => (
                    <option key={branch._id} value={branch.name}>
                      {branch.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                  <Filter className="h-4 w-4" />
                </div>
              </div>
            </div>
            <button
              onClick={() => setShowCreateModal(true)}
              className="w-full sm:w-auto bg-blue-600 text-white px-4 sm:px-6 py-2 sm:py-2.5 rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center shadow-md hover:shadow-lg"
            >
              <Plus className="h-5 w-5 ml-2" />
              צור טופס חדש
            </button>
          </div>
        </div>

        {/* Updated Stats Overview */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-gray-500 text-sm">סה"כ משימות</h3>
            <div className="flex items-center justify-between mt-2">
              <p className="text-2xl font-bold">{stats.total}</p>
              <p className="text-sm text-gray-500">
                ממוצע {stats.averageTasksPerForm} לטופס
              </p>
            </div>
            {selectedBranch !== 'all' && (
              <p className="text-sm text-gray-500 mt-1">בסניף {selectedBranch}</p>
            )}
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-gray-500 text-sm">ממתינות לטיפול</h3>
            <div className="flex items-center justify-between mt-2">
              <p className="text-2xl font-bold text-yellow-500">{stats.open}</p>
              <p className="text-sm text-gray-500">
                {stats.total ? Math.round((stats.open / stats.total) * 100) : 0}% מהמשימות
              </p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-gray-500 text-sm">בטיפול</h3>
            <div className="flex items-center justify-between mt-2">
              <p className="text-2xl font-bold text-blue-500">{stats.inProgress}</p>
              <p className="text-sm text-gray-500">
                {stats.total ? Math.round((stats.inProgress / stats.total) * 100) : 0}% מהמשימות
              </p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-gray-500 text-sm">הושלמו</h3>
            <div className="flex items-center justify-between mt-2">
              <p className="text-2xl font-bold text-green-500">{stats.completed}</p>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-green-600 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${stats.completionRate}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-500">
                {stats.completionRate}%
              </p>
            </div>
          </div>
        </div>

        {/* Mobile-Friendly Table/List View */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {/* Desktop Table Header - Hidden on Mobile */}
          <table className="min-w-full hidden sm:table">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-right">סניף</th>
                <th className="px-6 py-3 text-right">מפקח</th>
                <th className="px-6 py-3 text-right">תאריך דוח</th>
                <th className="px-6 py-3 text-right">תאריך יעד</th>
                <th className="px-6 py-3 text-right">התקדמות</th>
                <th className="px-6 py-3 text-right">פריטים</th>
                <th className="px-6 py-3 text-right">פעולות</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredForms.map(form => (
                <React.Fragment key={form._id}>
                  <tr 
                    className="hover:bg-gray-50 cursor-pointer" 
                    onClick={() => toggleFormExpansion(form._id)}
                  >
                    <td className="px-6 py-4">{form.branchName}</td>
                    <td className="px-6 py-4">{form.inspector}</td>
                    <td className="px-6 py-4">
                      {new Date(form.reportDate).toLocaleDateString('he-IL')}
                    </td>
                    <td className="px-6 py-4">
                      {new Date(form.dueDate).toLocaleDateString('he-IL')}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2">
                          <div 
                            className="bg-green-600 h-2.5 rounded-full transition-all duration-300"
                            style={{ width: `${calculateProgress(form.items)}%` }}
                          ></div>
                        </div>
                        <span className="text-sm text-gray-600">
                          {calculateProgress(form.items)}%
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 flex items-center">
                      <span>{form.items.length} פריטים</span>
                      {expandedForm === form._id ? 
                        <ChevronUp className="h-4 w-4 mr-2" /> : 
                        <ChevronDown className="h-4 w-4 mr-2" />
                      }
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-4 space-x-reverse">
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingForm({...form});
                            setShowEditModal(true);
                          }}
                          className="text-blue-600 hover:text-blue-800 flex items-center"
                        >
                          <Edit className="h-4 w-4 ml-1" />
                          ערוך
                        </button>
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            const link = `${window.location.origin}/rejection-form/${form.accessToken}`;
                            navigator.clipboard.writeText(link);
                            toast.success('הקישור הועתק ללוח');
                          }}
                          className="text-blue-600 hover:text-blue-800 flex items-center"
                        >
                          <Link2 className="h-4 w-4 ml-1" />
                          העתק קישור
                        </button>
                        {preparedPDFs[form._id] ? (
                          <a
                            href={URL.createObjectURL(preparedPDFs[form._id].blob)}
                            download={`rejection-report-${form.branch}-${getFormattedDate(form.reportDate)}.pdf`}
                            onClick={(e) => {
                              e.stopPropagation();
                              // Cleanup the URL after download starts
                              setTimeout(() => {
                                URL.revokeObjectURL(e.target.href);
                                clearPreparedPDF(form._id);
                              }, 100);
                            }}
                            className="text-green-600 hover:text-green-800 flex items-center"
                          >
                            <Download className="h-4 w-4 ml-1" />
                            הורד PDF
                          </a>
                        ) : (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              preparePDFForForm(form);
                            }}
                            className="text-blue-600 hover:text-blue-800 flex items-center"
                          >
                            <Download className="h-4 w-4 ml-1" />
                            הכן PDF
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedForm === form._id && (
                    <tr>
                      <td colSpan="7" className="px-6 py-4 bg-gray-50">
                        <div className="space-y-6">
                          {form.items.map((item, index) => (
                            <div key={index} className="bg-white rounded-lg shadow-sm border border-gray-200">
                              <div className="p-4">
                                {/* Header Section */}
                                <div className="flex flex-row-reverse justify-between items-start mb-4">
                                  {/* Original Image Section */}
                                  {item.imageUrl && (
                                    <div className="ml-6">
                                      <ImagePreview 
                                        imageUrls={item.imageUrl} 
                                        alt="תמונת פריט"
                                        size="large"
                                      />
                                    </div>
                                  )}
                                  {/* Description and status */}
                                  <div className="flex-1">
                                    <h4 className="text-lg font-medium mb-2">{item.description}</h4>
                                    <div className="flex items-center gap-4">
                                      <span className={`
                                        inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                                        ${item.status === 'completed' ? 'bg-green-100 text-green-800' : 
                                          item.status === 'in_progress' ? 'bg-blue-100 text-blue-800' : 
                                          'bg-yellow-100 text-yellow-800'}
                                      `}>
                                        {item.status === 'completed' ? 'הושלם' : 
                                         item.status === 'in_progress' ? 'בטיפול' : 
                                         'ממתין לטיפול'}
                                      </span>
                                      {item.completedAt && (
                                        <span className="text-sm text-gray-500">
                                          הושלם ב: {new Date(item.completedAt).toLocaleDateString('he-IL')}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* Comments Section */}
                                {item.comments && item.comments.length > 0 && (
                                  <div className="mt-4 border-t pt-4">
                                    <h5 className="font-medium text-gray-700 mb-3">היסטוריית עדכונים:</h5>
                                    <div className="space-y-4">
                                      {item.comments.map((comment, cIndex) => (
                                        <div 
                                          key={cIndex} 
                                          className="bg-gray-50 rounded-lg p-4 relative"
                                        >
                                          <div className="flex flex-row-reverse items-start gap-4">
                                            {/* Comment Images */}
                                            {comment.imageUrls?.length > 0 && (
                                              <ImagePreview 
                                                imageUrls={comment.imageUrls} 
                                                alt="תמונת עדכון"
                                                size="medium"
                                              />
                                            )}
                                            <div className="flex-1">
                                              {comment.text && (
                                                <p className="text-gray-700 mb-2">{comment.text}</p>
                                              )}
                                              <span className="text-xs text-gray-500">
                                                {new Date(comment.createdAt).toLocaleString('he-IL')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {/* Mobile List View */}
          <div className="sm:hidden">
            {filteredForms.map(form => (
              <div key={form._id} className="border-b border-gray-200">
                {/* Header Section */}
                <div className="p-4" onClick={() => toggleFormExpansion(form._id)}>
                  <div className="flex justify-between items-start mb-3">
                    <div>
                      <h3 className="font-medium">{form.branchName}</h3>
                      <p className="text-sm text-gray-500">{form.inspector}</p>
                    </div>
                    <div className="text-sm text-gray-500 text-left">
                      <div>דוח: {new Date(form.reportDate).toLocaleDateString('he-IL')}</div>
                      <div>יעד: {new Date(form.dueDate).toLocaleDateString('he-IL')}</div>
                    </div>
                  </div>
                  
                  {/* Progress Bar */}
                  <div className="mb-3">
                    <div className="flex items-center justify-between mb-1">
                      <span className="text-sm text-gray-600">התקדמות</span>
                      <span className="text-sm text-gray-600">
                        {calculateProgress(form.items)}%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2">
                      <div 
                        className="bg-green-600 h-2 rounded-full transition-all duration-300"
                        style={{ width: `${calculateProgress(form.items)}%` }}
                      ></div>
                    </div>
                  </div>

                  {/* Actions */}
                  <div className="flex justify-between items-center">
                    <div className="flex space-x-4 space-x-reverse">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingForm({...form});
                          setShowEditModal(true);
                        }}
                        className="text-blue-600 text-sm flex items-center"
                      >
                        <Edit className="h-4 w-4 ml-1" />
                        ערוך
                      </button>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          const link = `${window.location.origin}/rejection-form/${form.accessToken}`;
                          navigator.clipboard.writeText(link);
                          toast.success('הקישור הועתק ללוח');
                        }}
                        className="text-blue-600 text-sm flex items-center"
                      >
                        <Link2 className="h-4 w-4 ml-1" />
                        העתק קישור
                      </button>
                      {preparedPDFs[form._id] ? (
                        <a
                          href={URL.createObjectURL(preparedPDFs[form._id].blob)}
                          download={`rejection-report-${form.branch}-${getFormattedDate(form.reportDate)}.pdf`}
                          onClick={(e) => {
                            e.stopPropagation();
                            // Cleanup the URL after download starts
                            setTimeout(() => {
                              URL.revokeObjectURL(e.target.href);
                              clearPreparedPDF(form._id);
                            }, 100);
                          }}
                          className="text-green-600 hover:text-green-800 flex items-center"
                        >
                          <Download className="h-4 w-4 ml-1" />
                          הורד PDF
                        </a>
                      ) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            preparePDFForForm(form);
                          }}
                          className="text-blue-600 hover:text-blue-800 flex items-center"
                        >
                          <Download className="h-4 w-4 ml-1" />
                          הכן PDF
                        </button>
                      )}
                    </div>
                    <div className="flex items-center">
                      <span className="text-sm text-gray-600 ml-2">{form.items.length} פריטים</span>
                      {expandedForm === form._id ? 
                        <ChevronUp className="h-4 w-4" /> : 
                        <ChevronDown className="h-4 w-4" />
                      }
                    </div>
                  </div>
                </div>

                {/* Expanded Content */}
                {expandedForm === form._id && (
                  <div className="px-4 pb-4 space-y-4">
                    {form.items.map((item, index) => (
                      <div key={index} className="bg-gray-50 rounded-lg p-4">
                        <div className="flex flex-col space-y-3">
                          <div className="flex flex-row-reverse justify-between items-start">
                            {item.imageUrl && (
                              <div className="ml-4">
                                <ImagePreview 
                                  imageUrls={item.imageUrl} 
                                  alt="תמונת פריט"
                                  size="medium"
                                />
                              </div>
                            )}
                            <div className="flex-1">
                              <h4 className="font-medium mb-2">{item.description}</h4>
                              <span className={`
                                inline-block px-2 py-1 rounded-full text-sm
                                ${item.status === 'completed' ? 'bg-green-100 text-green-800' : 
                                  item.status === 'in_progress' ? 'bg-blue-100 text-blue-800' : 
                                  'bg-yellow-100 text-yellow-800'}
                              `}>
                                {item.status === 'completed' ? 'הושלם' : 
                                 item.status === 'in_progress' ? 'בטיפול' : 
                                 'ממתין לטיפול'}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* Comments section in mobile view */}
                        {item.comments && item.comments.length > 0 && (
                          <div className="mt-4 pt-4 border-t">
                            <h5 className="font-medium text-sm text-gray-700 mb-3">היסטוריית עדכונים:</h5>
                            {item.comments.map((comment, cIndex) => (
                              <div key={cIndex} className="bg-white rounded-lg p-3 mb-3">
                                <div className="flex flex-row-reverse items-start gap-3">
                                  {comment.imageUrls?.length > 0 && (
                                    <ImagePreview 
                                      imageUrls={comment.imageUrls} 
                                      alt="תמונת עדכון"
                                      size="small"
                                    />
                                  )}
                                  <div className="flex-1">
                                    {comment.text && <p className="text-sm mb-2">{comment.text}</p>}
                                    <span className="text-xs text-gray-500">
                                      {new Date(comment.createdAt).toLocaleString('he-IL')}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal remains the same but add mobile-friendly classes */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4">יצירת טופס רג'קט חדש</h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
                <select
                  value={newForm.branch}
                  onChange={handleBranchChange}
                  className="w-full p-2 border rounded"
                  required
                >
                  <option value="">בחר סניף</option>
                  {branches.map(branch => (
                    <option key={branch._id} value={branch._id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">מפקח</label>
                <input
                  type="text"
                  value={newForm.inspector}
                  onChange={(e) => setNewForm({ ...newForm, inspector: e.target.value })}
                  className="w-full p-2 border rounded"
                  placeholder="שם המפקח"
                />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium mb-1">תאריך הדוח</label>
                  <input
                    type="date"
                    value={newForm.reportDate}
                    onChange={(e) => setNewForm({ ...newForm, reportDate: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">תאריך יעד להגשה</label>
                  <input
                    type="date"
                    value={newForm.dueDate}
                    onChange={(e) => setNewForm({ ...newForm, dueDate: e.target.value })}
                    className="w-full p-2 border rounded"
                    min={newForm.reportDate}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">פריטים לטיפול</label>
                {newForm.items.map((item, index) => (
                  <div key={index} className="flex flex-col gap-2 mb-4 p-3 bg-gray-50 rounded-lg">
                    <div className="flex gap-2">
                      <input
                        type="text"
                        value={item.description}
                        onChange={(e) => updateItem(index, 'description', e.target.value)}
                        className="flex-1 p-2 border rounded"
                        placeholder="תיאור הבעיה"
                      />
                      <button
                        onClick={() => removeItem(index)}
                        className="bg-red-100 text-red-600 px-3 rounded hover:bg-red-200"
                      >
                        הסר
                      </button>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        id={`requiresPhoto-${index}`}
                        checked={item.requiresPhoto}
                        onChange={(e) => updateItem(index, 'requiresPhoto', e.target.checked)}
                        className="rounded text-blue-600"
                      />
                      <label htmlFor={`requiresPhoto-${index}`} className="text-sm text-gray-600">
                        נדרש תיעוד בתמונה
                      </label>
                    </div>
                  </div>
                ))}
                <button
                  onClick={addItem}
                  className="text-blue-600 hover:text-blue-800 text-sm"
                >
                  + הוסף פריט
                </button>
              </div>

              <div className="flex justify-end gap-2 mt-6">
                <button
                  onClick={() => setShowCreateModal(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  ביטול
                </button>
                <button
                  onClick={handleCreateForm}
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  צור טופס
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <h2 className="text-xl font-bold mb-4">עריכת טופס רג'קט</h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">סניף</label>
                <select
                  value={editingForm.branch}
                  onChange={(e) => setEditingForm({ ...editingForm, branch: e.target.value })}
                  className="w-full p-2 border rounded"
                >
                  {branches.map(branch => (
                    <option key={branch._id} value={branch.name}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">מפקח</label>
                <input
                  type="text"
                  value={editingForm.inspector}
                  onChange={(e) => setEditingForm({ ...editingForm, inspector: e.target.value })}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium mb-1">תאריך דוח</label>
                  <input
                    type="date"
                    value={editingForm.reportDate.split('T')[0]}
                    onChange={(e) => setEditingForm({ ...editingForm, reportDate: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">תאריך יעד</label>
                  <input
                    type="date"
                    value={editingForm.dueDate.split('T')[0]}
                    onChange={(e) => setEditingForm({ ...editingForm, dueDate: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">פריטים</label>
                {editingForm.items.map((item, index) => (
                  <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
                    <div className="flex gap-2">
                      <input
                        type="text"
                        value={item.description}
                        onChange={(e) => {
                          const newItems = [...editingForm.items];
                          newItems[index] = { ...item, description: e.target.value };
                          setEditingForm({ ...editingForm, items: newItems });
                        }}
                        className="flex-1 p-2 border rounded"
                        disabled={item.status === 'completed'}
                      />
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={item.requiresPhoto}
                          onChange={(e) => {
                            const newItems = [...editingForm.items];
                            newItems[index] = { ...item, requiresPhoto: e.target.checked };
                            setEditingForm({ ...editingForm, items: newItems });
                          }}
                          className="mr-2"
                          disabled={item.status === 'completed'}
                        />
                        <label className="text-sm">נדרש תיעוד בתמונה</label>
                      </div>
                      {item.status !== 'completed' && (
                        <button
                          onClick={() => {
                            const newItems = editingForm.items.filter((_, i) => i !== index);
                            setEditingForm({ ...editingForm, items: newItems });
                          }}
                          className="bg-red-100 text-red-600 px-3 rounded hover:bg-red-200"
                        >
                          הסר
                        </button>
                      )}
                    </div>
                    {item.status === 'completed' && (
                      <p className="text-sm text-gray-500 mt-2">לא ניתן לערוך פריט שהושלם</p>
                    )}
                  </div>
                ))}
                <button
                  onClick={() => {
                    setEditingForm({
                      ...editingForm,
                      items: [
                        ...editingForm.items,
                        { description: '', requiresPhoto: false, status: 'pending', comments: [] }
                      ]
                    });
                  }}
                  className="text-blue-600 hover:text-blue-800 text-sm"
                >
                  + הוסף פריט חדש
                </button>
              </div>

              <div className="flex justify-end gap-2 mt-6">
                <button
                  onClick={() => {
                    setShowEditModal(false);
                    setEditingForm(null);
                  }}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  ביטול
                </button>
                <button
                  onClick={handleEditForm}
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  שמור שינויים
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RejectionFormsDashboard; 